import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledIconsSlider = styled.div`
  padding-top: ${Rem(32)};
`

export const StyledIconsSliderItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  @media (${BP.ipad}) {
    justify-content: space-between;
  }
`

export const StyledIconsSliderItem = styled.div`
  text-align: center;
  min-width: 100%;
  max-width: 100%;

  @media (${BP.ipad}) {
    min-width: 0;
    max-width: none;
  }
`

export const StyledIconsSliderItemIcon = styled.div`
  width: 100%;
  height: ${Rem(54)};
  background-image: url('${(props) => props.bg}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: ${Rem(20)};
`

export const StyledIconsSliderItemText = styled.div`
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  text-transform: uppercase;
`
