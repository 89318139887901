import React, { useContext, useEffect, useState } from 'react'
import { LayoutContext } from '../contexts/LayoutContext'

const useFixedNav = () => {
  const { scrollDirection } = useContext(LayoutContext)
  const [isVisible, setIsVisible] = useState(true)
  useEffect(() => {
    document.body.classList.add('has-fixed-nav')
  })

  useEffect(() => {
    setIsVisible(scrollDirection === 'up')
  }, [scrollDirection])

  useEffect(() => {
    return document.body.classList.remove('has-fixed-nav')
  }, [])

  return {
    isVisible,
  }
}

export default useFixedNav
