import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledClientCardsScroller = styled.div``

export const StyledClientCardsScrollerItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding: ${Rem(36)} 0 ${Rem(24)};

  @media (${BP.ipad}) {
    padding: ${Rem(80)} 0 ${Rem(24)};
  }
`

export const StyledClientCardsScrollerItem = styled.div`
  overflow: hidden;
  position: relative;
  min-width: 80vw;
  margin-left: ${Rem(20)};
  padding: ${Rem(30)} ${Rem(30)} ${Rem(120)};
  ${Shadow};

  @media (${BP.ipad}) {
    min-width: 40%;
  }

  &:first-child {
    @media (${BP.ipad}) {
      margin-left: 0;
    }
  }

  &:last-child {
    &:after {
      display: block;
      content: '';
      position: relative;
      width: ${Rem(20)};
      height: 100%;
      left: calc(100% + ${Rem(32)});

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }
`

export const StyledClientCardsScrollerItemLogo = styled.img`
  margin-bottom: ${Rem(24)};
  max-height: ${Rem(50)};
`

export const StyledClientCardsScrollerItemText = styled.p`
  font-size: ${Rem(14)};
  line-height: ${Rem(19)};
  font-weight: normal;
`

export const StyledClientCardsScrollerItemCTA = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  @media (${BP.ipad}) {
    left: auto;
    right: 0;
  }
`
