import React, { useRef, useState } from 'react'
import {
  StyledHero,
  StyledHeroMedia,
  StyledHeroImage,
  StyledHeroLogo,
  StyledHeroMediaExtra,
  StyledHeroMediaExtraCta,
  StyledHeroMediaExtraReadMore,
  StyledHeroSubtitle,
  StyledHeroText,
  StyledHeroTitle,
  StyledHeroScrollDown,
  StyledHeroCtaText,
} from './style.jsx'
import { Link } from 'gatsby'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { StyledGridRow } from '../../commons/Grid'

import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default ({
  title,
  subtitle,
  ctaText,
  image,
  video,
  readMore,
  themeColor,
  background,
  hasScroller = true,
  logoTitle,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const iconColor = themeColor.yellow ? Colors.yellow : Colors.white
  const heroEl = useRef(null)
  return (
    <StyledHero {...themeColor} ref={heroEl}>
      <StyledGridRow noMargin={!!image}>
        <StyledHeroText>
          {logoTitle && (<StyledHeroLogo src={logoTitle}/>)}<StyledHeroTitle dangerouslySetInnerHTML={{ __html: title }} />
          <StyledHeroSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
        </StyledHeroText>
      </StyledGridRow>
      {image && (
        <StyledGridRow isFull>
          {typeof image === 'string' ? (
            <StyledHeroImage bg={image} />
          ) : (
            <StyledHeroImage bg={image.mobile} bgDesktop={image.desktop} />
          )}
          <StyledHeroCtaText>
          <span>{ctaText}</span>  
          </StyledHeroCtaText>
        </StyledGridRow>
      )}
      {video && (
        <StyledGridRow isFull>
          <StyledHeroMedia>
            <StyledHeroMediaExtra isVisible={!showVideo}>
              <StyledHeroMediaExtraCta>
                <Button
                  handleOnClick={() => setShowVideo(!showVideo)}
                  noAnimation
                >
                    <span>{video.ctaLabel}</span>
                  <Icon icon={icons.play} color={iconColor} />
                </Button>
              </StyledHeroMediaExtraCta>
              <StyledHeroMediaExtraReadMore
                onClick={() => {
                  gsap.to(window, {
                    scrollTo: heroEl.current.clientHeight + 40,
                    duration: 0.4,
                  })
                }}
              >
                <span>{readMore.text}</span>
              </StyledHeroMediaExtraReadMore>
            </StyledHeroMediaExtra>
            <VimeoPlayer {...video} autoplay={showVideo} showIcon={false} />
          </StyledHeroMedia>
        </StyledGridRow>
      )}
      {hasScroller && (
        <StyledHeroScrollDown
          onClick={() => {
            gsap.to(window, {
              scrollTo: heroEl.current.clientHeight + 40,
              duration: 0.4,
            })
          }}
        >
          <Icon icon={icons.chevronDown} color={Colors.yellow} />
        </StyledHeroScrollDown>
      )}
    </StyledHero>
  )
}
