import React, { useState, useRef } from 'react'

import {
  StyledVideoAndText,
  StyledVideoAndTextContent,
  StyledVideoAndTextCTA,
  StyledVideoAndTextIcon,
  StyledVideoAndTextItem,
  StyledVideoAndTextItems,
  StyledVideoAndTextItemText,
  StyledVideoAndTextMedia,
  StyledVideoAndTextParagraph,
  StyledVideoAndTextSubtitle,
  StyledVideoAndTextTitle,
} from './style'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { StyledGridRow } from '../../commons/Grid'
import MobileScroller from '../MobileScroller/MobileScroller'
import Scroller from '../Scroller/Scroller'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default ({
  id,
  theme,
  inverted,
  upside,
  title,
  subtitle,
  paragraph,
  items,
  videoID,
  thumb,
  labels,
  sectionId,
  jumpId,
  videoIconColor,
}) => {
  const isWhite = theme === 'white'
  const [isPlaying, setIsPlaying] = useState(false)
  return (
    <StyledVideoAndText id={id} inverted={inverted} upside={upside} isWhite={isWhite}>
      <StyledVideoAndTextMedia onClick={() => setIsPlaying(true)}>
        <VimeoPlayer
          thumb={thumb}
          videoID={videoID}
          autoplay={isPlaying}
          showIcon={true}
          videoIconColor={videoIconColor}
        />
      </StyledVideoAndTextMedia>
      <StyledGridRow noMargin isFullMobile>
        <StyledVideoAndTextContent>
          <StyledGridRow noMargin>
            {title && (
              <StyledVideoAndTextTitle
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {subtitle && (
              <StyledVideoAndTextSubtitle
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
            {paragraph && (
              <StyledVideoAndTextParagraph
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            )}
          </StyledGridRow>
          {items && (
            <StyledGridRow noMargin isFullMobile>
              <Scroller>
                <StyledVideoAndTextItems>
                  {items.map((item, index) => (
                    <StyledVideoAndTextItem key={index}>
                      <StyledVideoAndTextIcon bg={item.icon} />
                      <StyledVideoAndTextItemText
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </StyledVideoAndTextItem>
                  ))}
                </StyledVideoAndTextItems>
              </Scroller>
            </StyledGridRow>
          )}
          <StyledGridRow noMargin>
            {jumpId && (
              <StyledVideoAndTextCTA>
                <Button
                  as="div"
                  theme={isWhite ? 'white' : 'transparent'}
                  noPadding
                  handleOnClick={() => {
                    gsap.to(window, {
                      scrollTo: {
                        y: '',
                      },
                      duration: 0.4,
                    })
                  }}
                >
                  {labels.WATCH_VIDEO}
                  <Icon
                    icon={icons.arrowRight}
                    color={isWhite ? Colors.black : Colors.white}
                  />
                </Button>
              </StyledVideoAndTextCTA>
            )}
          </StyledGridRow>
        </StyledVideoAndTextContent>
      </StyledGridRow>
    </StyledVideoAndText>
  )
}
