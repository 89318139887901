import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'
import { StyledFormSelect } from '../../commons/Form'
import { StyledButton } from '../Button/style'
import { StyledLoader } from '../Loader/style'

export const StyledBeekeeperShop = styled.div`
  * {
    user-select: none;
  }
  @media (${BP.ipad}) {
    padding-top: ${Rem(36)};
  }
`

export const StyledBeekeeperShopContent = styled.div`
  padding-top: ${Rem(36)};
  padding-bottom: ${Rem(0)};
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    display: block;
    overflow: hidden;
    position: relative;
    margin-bottom: ${Rem(40)};
  }
`

export const StyledBeekeeperShopBigTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${Rem(26)};
  line-height: ${Rem(36)};
  letter-spacing: ${Rem(5)};
  margin-bottom: ${Rem(24)};
  text-align: center;
  text-transform: uppercase;

  @media (${BP.ipad}) {
    font-size: ${Rem(36)};
    line-height: ${Rem(36)};
    margin-bottom: ${Rem(42)};
  }
`

export const StyledBeekeeperShopTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${Rem(20)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(30)};
    line-height: ${Rem(30)};
    margin-bottom: ${Rem(42)};
  }
`

export const StyledBeekeeperShopInfo = styled.div`
  position: relative;
  margin-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    position: initial;
    padding-left: ${Rem(24)};
    display: inline;
    margin-bottom: ${Rem(48)};
  }
`

export const StyledBeekeeperShopInfoName = styled.div`
  font-size: ${Rem(18)};
  line-height: ${Rem(19)};
  letter-spacing: ${Rem(5)};
  text-transform: uppercase;
  padding-bottom: ${Rem(12)};

  @media (${BP.ipad}) {
    font-size: ${Rem(25)};
    line-height: ${Rem(28)};
  }
`

export const StyledBeekeeperShopInfoPersonal = styled.div`
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
  }
`

export const StyledBeekeeperShopInfoPersonalCTA = styled.div`
  position: absolute;
  display: inline-block;
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding: ${Rem(10)} ${Rem(24)};
  white-space: nowrap;
  cursor: pointer;
  bottom: ${Rem(20)};
  left: 50%;
  transform: translateX(-50%);

  @media (${BP.ipad}) {
    position: relative;
    transform: none;
    bottom: auto;
    left: auto;
  }
`

export const StyledBeekeeperShopServices = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: ${Rem(20)};
`

export const StyledBeekeeperShopVideoGallery = styled.div`
  padding-bottom: ${Rem(80)};
`

export const StyledBeekeeperShopChoosers = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (${BP.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }
`

export const StyledBeekeeperShopChoosersHives = styled.div`
  width: 100%;
  margin-bottom: ${Rem(48)};

  @media (${BP.tablet}) {
    margin-bottom: ${Rem(70)};
  }
`

export const StyledBeekeeperShopChoosersHiveName = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  font-size: ${Rem(20)};
  line-height: ${Rem(22)};
  margin-bottom: ${Rem(10)};

  span {
    margin-right: ${Rem(8)};
    margin-bottom: ${Rem(10)};
  }

  @media (${BP.tablet}) {
    font-size: ${Rem(28)};
    line-height: ${Rem(33)};
    flex-wrap: nowrap;
  }

  ${StyledButton} {
    height: auto;
    margin: 0;
    padding: ${Rem(4)} ${Rem(8)};
    min-width: auto;

    :before {
      display: none;
    }
  }
`

export const StyledBeekeeperShopChoosersHiveFilters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: ${Rem(16)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
  margin-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`

export const StyledBeekeeperShopChoosersHiveFilterCheckbox = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: ${Rem(32)};
  margin-bottom: ${Rem(16)};
  cursor: pointer;
  width: 100%;

  @media (${BP.ipad}) {
    width: auto;
    margin-bottom: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: ${Rem(18)};
    height: ${Rem(18)};
    border-radius: ${Rem(3)};
    margin-right: ${Rem(8)};
    border: 1px solid ${Colors.yellow};

    @media (${BP.ipad}) {
      width: ${Rem(28)};
      height: ${Rem(28)};
    }
  }

  ${StyledIcon} {
    position: absolute;
    width: ${Rem(10)};
    top: 50%;
    left: ${Rem(3)};
    transform: translateY(-50%);

    @media (${BP.ipad}) {
      width: ${Rem(20)};
      left: ${Rem(6)};
    }
  }
`

export const StyledBeekeeperShopChoosersHiveFilterYears = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${Rem(8)};
  padding-top: ${Rem(16)};

  @media (${BP.ipad}) {
    padding-top: 0;
  }
`

export const StyledBeekeeperShopChoosersHiveFilterYear = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${Rem(30)};
  height: ${Rem(30)};
  border-top: 1px solid ${Colors.yellow};
  border-bottom: 1px solid ${Colors.yellow};
  cursor: pointer;

  @media (${BP.ipad}) {
    width: ${Rem(40)};
    height: ${Rem(40)};
  }

  &:first-child {
    border: 1px solid ${Colors.yellow};
    border-radius: ${Rem(3)} 0 0 ${Rem(3)};
  }

  &:last-child {
    border: 1px solid ${Colors.yellow};
    border-radius: 0 ${Rem(3)} ${Rem(3)} 0;

    ${() => css`
      ${StyledBeekeeperShopChoosersHiveFilterYearDiscount} {
        right: ${Rem(-16)};
      }
    `}
  }

  ${(props) =>
    props.isActive &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.yellow};
    `}
`

export const StyledBeekeeperShopChoosersHiveFilterYearDiscount = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${Rem(30)};
  height: ${Rem(30)};
  border-radius: 50%;
  border: 1px solid ${Colors.red};
  font-size: ${Rem(10)};
  color: ${Colors.red};
  background-color: ${Colors.white};
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  top: ${Rem(-23)};
  right: ${Rem(-10)};
  z-index: 2;
`

export const StyledBeekeeperShopChoosersHivePresentForm = styled.div`
  width: 100%;
  border: 2px solid #d8d8d8;
  border-radius: ${Rem(10)};
  margin-bottom: ${Rem(24)};
  overflow: hidden;
`

export const StyledBeekeeperShopChoosersHivePresentFormTabs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledBeekeeperShopChoosersHivePresentFormTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: ${Rem(64)};
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
  font-weight: bold;
  color: #979797;
  background-color: #f9f9f9;
  cursor: pointer;

  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
    line-height: ${Rem(30)};
  }

  ${(props) =>
    props.isActive &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.yellow};

      ${StyledBeekeeperShopTooltipTrigger} {
        color: ${Colors.white};
        border-color: ${Colors.white};
      }
    `}

  ${() => css`
    ${StyledBeekeeperShopTooltipTrigger} {
      margin-left: ${Rem(4)};
    }
  `}
`

export const StyledBeekeeperShopChoosersHivePresentFormTabModal = styled.div`
  padding: ${Rem(40)} ${Rem(16)};

  @media (${BP.ipad}) {
    padding: ${Rem(60)} 0;
  }
`

export const StyledBeekeeperShopChoosersHivePresentFormTabModalTitle = styled.div`
  font-size: ${Rem(25)};
  line-height: ${Rem(40)};
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
  }
`

export const StyledBeekeeperShopChoosersHivePresentFormTabModalSubitle = styled.div`
  font-size: ${Rem(18)};
  margin-bottom: ${Rem(50)};

  @media (${BP.ipad}) {
    font-size: ${Rem(24)};
  }
`

export const StyledBeekeeperShopChoosersHivePresentFormTabModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media (${BP.ipad}) {
    flex-direction: row-reverse;
  }

  > img {
    max-width: 80%;
    margin: 0 auto ${Rem(16)};

    @media (${BP.ipad}) {
      max-width: none;
      margin: 0 auto;
    }
  }

  > p {
    font-size: ${Rem(14)};
    line-height: ${Rem(25)};
    font-weight: 300;

    @media (${BP.ipad}) {
      font-size: ${Rem(16)};
      line-height: ${Rem(24)};
      padding-right: ${Rem(24)};
    }
  }
`

export const StyledBeekeeperShopChoosersHivePresentFormPanels = styled.div`
  padding: ${Rem(24)} ${Rem(16)};

  @media (${BP.ipad}) {
    padding: ${Rem(24)};
  }
`

export const StyledBeekeeperShopChoosersHivePresentFormPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (${BP.ipad}) {
    flex-wrap: nowrap;
  }
`

export const StyledBeekeeperShopChoosersHivePresentFormInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${Rem(24)};

  &:last-child {
    margin-bottom: 0;
  }

  @media (${BP.ipad}) {
    width: calc((100% / ${({numbers}) => numbers ?? 3}) - ${Rem(8)});
    margin-bottom: 0;
  }

  > label {
    font-size: ${Rem(16)};
    margin-bottom: ${Rem(4)};

    @media (${BP.ipad}) {
      font-size: ${Rem(18)};
    }
  }

  > input {
    border-radius: ${Rem(10)};
    font-size: ${Rem(16)};
    padding: ${Rem(12)};
    border: 1px solid #d8d8d8;
    font-family: inherit;
    width: 100%;
    background-color: ${Colors.white};

    @media (${BP.ipad}) {
      font-size: ${Rem(18)};
      padding: ${Rem(14)};
    }
  }
`

export const StyledBeekeeperShopChoosersHiveItemsIntro = styled.div`
  font-size: ${Rem(25)};
  line-height: ${Rem(29)};
  margin-bottom: ${Rem(24)};
`

export const StyledBeekeeperShopChoosersHiveItems = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (${BP.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }
`

export const StyledBeekeeperShopChooserItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${Rem(24)};
  padding: ${Rem(24)} ${Rem(16)} ${Rem(88)};
  border-radius: ${Rem(10)};
  overflow: hidden;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1px solid ${Colors.yellow};
    border-radius: ${Rem(10)};
    pointer-events: none;
  }

  @media (${BP.tablet}) {
    width: calc(50% - ${Rem(12)});
    min-height: ${Rem(299)};
    padding: ${Rem(24)} ${Rem(24)} ${Rem(88)};
  }

  ${(props) =>
    props.isActive &&
    css`
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

      &:after {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border: 3px solid ${Colors.yellow};
      }

      ${StyledBeekeeperShopChooserItemCTA} {
        color: ${Colors.white};
        background-color: ${Colors.yellow};
      }
    `}

  ${(props) =>
    props.adoption &&
    css`
      border-radius: 0;
      padding-bottom: ${Rem(25)};
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

      @media (${BP.tablet}) {
        padding-bottom: ${Rem(25)};
        height: auto;
      }

      &:after {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border: 3px solid ${Colors.yellow};
      }

      ${StyledBeekeeperShopChooserItemDetails} {
        margin-bottom: ${Rem(20)};
      }
    `}
`

export const StyledBeekeeperShopChooserItemExtra = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  color: ${Colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.yellow80};
  font-size: ${Rem(28)};
  line-height: ${Rem(32)};
  padding: 0 ${Rem(78)};
  text-align: center;

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`

export const StyledBeekeeperShopChooserItemExtraTrigger = styled.div`
  position: absolute;
  width: ${Rem(18)};
  height: ${Rem(18)};
  top: ${Rem(16)};
  right: ${Rem(16)};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #bdc3c7;
  color: #bdc3c7;
  border-radius: 50%;
  font-size: ${Rem(14)};
  z-index: 4;
  cursor: pointer;

  ${(props) =>
    props.isClose &&
    css`
      color: ${Colors.white};
      border-color: ${Colors.white};
    `}
`

export const StyledBeekeeperShopChooserItemImage = styled.div`
  width: 100%;
  max-width: ${Rem(90)};
  height: ${Rem(90)};
  background-image: url('${(props) => props.bg}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${Rem(8)};
  overflow: hidden;
  
  @media(${BP.ipad}) {
    max-width: ${Rem(180)};
    height: ${Rem(180)};
  }
`

export const StyledBeekeeperShopChooserItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: calc(100% - ${Rem(90)});
  padding-left: ${Rem(8)};

  @media (${BP.ipad}) {
    max-width: calc(100% - ${Rem(180)});
    padding-left: ${Rem(24)};
  }
`

export const StyledBeekeeperShopChooserItemName = styled.div`
  font-size: ${Rem(25)};
  line-height: ${Rem(34)};
  margin-bottom: ${Rem(16)};

  @media (${BP.ipad}) {
    font-size: ${Rem(28)};
  }
`

export const StyledBeekeeperShopChooserItemDetails = styled.div``

export const StyledBeekeeperShopChooserItemDetail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: ${Rem(16)};
  }
`

export const StyledBeekeeperShopChooserItemDetailIcon = styled.div`
  > img {
    width: ${Rem(20)};
  }
`

export const StyledBeekeeperShopChooserItemDetailText = styled.div`
  font-size: ${Rem(16)};
  line-height: ${Rem(20)};
  font-weight: 300;
  padding-left: ${Rem(8)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(22)};
  }
`

export const StyledBeekeeperShopChooserItemFilters = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    flex-wrap: nowrap;
  }
`

export const StyledBeekeeperShopChooserItemFilter = styled.div`
  &:first-child {
    margin-right: ${Rem(16)};
    margin-bottom: ${Rem(12)};

    @media (${BP.ipad}) {
      margin-bottom: 0;
    }
  }
`

export const StyledBeekeeperShopChooserItemFilterLabel = styled.div`
  font-size: ${Rem(16)};
  margin-bottom: ${Rem(8)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
  }
`

export const StyledBeekeeperShopChooserItemFlowers = styled.div`
  display: flex;
  font-size: ${Rem(16)};
  margin-top: ${Rem(16)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
  }

  & > span {
    margin: auto ${Rem(8)};
  }
`

export const StyledBeekeeperShopChooserItemFilterInput = styled.div`
  border: 1px solid #d8d8d8;
  border-radius: ${Rem(10)};
  padding: ${Rem(10)};
  font-size: ${Rem(16)};

  @media (${BP.ipad}) {
    padding: ${Rem(16)};
    font-size: ${Rem(18)};
  }

  ${StyledFormSelect} {
    > select {
      border: none;
      padding: 0 ${Rem(30)} 0 0;
      height: auto;
      letter-spacing: normal;
      font-size: ${Rem(16)};
      font-weight: bold;
      text-transform: none;
      text-overflow: ellipsis;
      background-color: ${Colors.white};

      @media (${BP.ipad}) {
        font-size: ${Rem(18)};
      }
    }

    &:after {
      right: 0;
      background-image: url('/2020/svg/icons/chevron-down-yellow.svg');
    }
  }

  ${StyledBeekeeperShopChooserItemFilter}:last-child & {
    padding: ${Rem(10)} ${Rem(4)};
    @media (${BP.ipad}) {
      padding: ${Rem(13)} ${Rem(6)};
    }
  }
`

export const StyledBeekeeperShopChooserItemFilterCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;

      ${StyledBeekeeperShopChooserItemFilterCounterValue} {
        color: rgb(170, 170, 170);
      }
      ${StyledBeekeeperShopChooserItemFilterCounterButton} {
        background-color: rgb(170, 170, 170);
        pointer-events: none;
      }
    `}
`

export const StyledBeekeeperShopChooserItemFilterCounterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${Rem(24)};
  height: ${Rem(24)};
  font-size: ${Rem(22)};
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  border-radius: 50%;
  cursor: pointer;

  @media (${BP.ipad}) {
    width: ${Rem(28)};
    height: ${Rem(28)};
    font-size: ${Rem(25)};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      background-color: rgb(170, 170, 170);
      pointer-events: none;
    `}
`

export const StyledBeekeeperShopChooserItemFilterCounterValue = styled.div`
  min-width: ${Rem(60)};
  padding: 0 ${Rem(10)};
  text-align: center;
  font-size: ${Rem(18)};
`

export const StyledBeekeeperShopChooserItemFilterCounterMax = styled.div`
  width: 100%;
  margin-bottom: ${Rem(8)};
  font-weight: 300;
  position: relative;
  transform: translateY(${Rem(-6)});
`

export const StyledBeekeeperShopChooserItemPrice = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: ${Rem(24)};
  min-height: ${Rem(34)};

  @media (${BP.ipad}) {
    font-size: ${Rem(30)};
  }
`

export const StyledBeekeeperShopChooserItemPriceOriginal = styled.div`
  font-size: ${Rem(16)};
  color: #979797;
  text-decoration: line-through;
  padding-right: ${Rem(12)};

  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
  }
`

export const StyledBeekeeperShopChooserItemCTA = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${Rem(64)};
  left: 0;
  bottom: 0;
  border-top: 1px solid ${Colors.yellow60};
  color: ${Colors.yellow};
  font-size: ${Rem(20)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
  cursor: pointer;
`
export const StyledBeekeeperShopChooserBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;
  margin-left: auto;

  & > div {
    justify-content: center;
  }
`

export const StyledBeekeeperShopChooserCTA = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: ${Rem(30)} 0;
  height: ${Rem(66)};
`

export const StyledBeekeeperShopChooserCTAButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${Rem(66)};
  color: ${Colors.black};
  background-color: ${Colors.yellow};
  cursor: pointer;
  font-size: ${Rem(20)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
  padding: 0 ${Rem(40)};

  @media (${BP.ipad}) {
    width: auto;
  }

  ${StyledIcon} {
    margin-left: ${Rem(16)};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      filter: grayscale(1);
      pointer-events: none;
      cursor: default;
    `}

  ${(props) =>
    props.isFloat &&
    css`
      z-index: 9;
      position: fixed;
      bottom: 0px;

      @media (${BP.ipad}) {
        right: 10px;
      }
    `}
`

export const StyledBeekeeperShopImpact = styled.div``

export const StyledBeekeeperShopImpactTitle = styled.div`
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  text-transform: uppercase;
  padding-bottom: ${Rem(36)};
`

export const StyledBeekeeperShopImpactItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  @media (${BP.ipad}) {
    justify-content: center;
  }
`

export const StyledBeekeeperShopImpactItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid ${Colors.gray};
  padding: 0 ${Rem(36)} 0 ${Rem(12)};

  &:last-child {
    border-right: none;
  }
`

export const StyledBeekeeperShopImpactItemImage = styled.img``

export const StyledBeekeeperShopImpactItemContent = styled.div`
  padding-left: ${Rem(20)};
`

export const StyledBeekeeperShopImpactItemName = styled.div`
  font-size: ${Rem(30)};
`

export const StyledBeekeeperShopImpactItemValue = styled.div`
  font-size: ${Rem(60)};
  color: ${Colors.yellow};
  padding-bottom: ${Rem(12)};
`

export const StyledBeekeeperShopImpactItemExtra = styled.div`
  font-size: ${Rem(18)};
`

export const StyledBeekeeperShopSummary = styled.div`
  padding: ${Rem(46)} ${Rem(16)};

  @media (${BP.ipad}) {
    padding: ${Rem(46)} ${Rem(32)};
  }
`

export const StyledBeekeeperShopSummaryForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${Rem(30)};

  > input {
    appearance: none;
    display: block;
    font-family: inherit;
    font-size: ${Rem(16)};
    border: 2px solid ${Colors.white};
    border-radius: ${Rem(30)};
    padding: ${Rem(8)} ${Rem(12)};
    width: 100%;
    margin-right: ${Rem(8)};
    text-overflow: ellipsis;

    @media (${BP.ipad}) {
      font-size: ${Rem(20)};
      padding: ${Rem(12)} ${Rem(16)};
      margin-right: ${Rem(24)};
    }
  }

  > button {
    appearance: none;
    display: block;
    font-family: inherit;
    font-size: ${Rem(18)};
    border: 2px solid ${Colors.white};
    border-radius: ${Rem(30)};
    padding: ${Rem(8)} ${Rem(16)};
    color: ${Colors.yellow};
    background-color: ${Colors.white};
    cursor: pointer;

    @media (${BP.ipad}) {
      font-size: ${Rem(24)};
      padding: ${Rem(12)} ${Rem(24)};
      min-width: ${Rem(200)};
      min-width: ${Rem(200)};
    }
  }
`

export const StyledBeekeeperShopSummaryBeekeeper = styled.div``

export const StyledBeekeeperShopSummaryDetails = styled.div``

export const StyledBeekeeperShopSummaryDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${Rem(14)};
  line-height: ${Rem(12)};
  margin-bottom: ${Rem(8)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(21)};
  }

  ${(props) =>
    props.big &&
    css`
      font-size: ${Rem(18)};
      margin-bottom: ${Rem(16)};
      padding-top: ${Rem(16)};

      @media (${BP.ipad}) {
        font-size: ${Rem(25)};
      }
    `}
`

export const StyledBeekeeperShopSummaryDetailLabel = styled.div`
  font-size: inherit;

  > span {
    font-weight: 300;
  }
`

export const StyledBeekeeperShopSummaryDetailValue = styled.div``

export const StyledBeekeeperShopSummaryDeliveryDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: ${Rem(14)};
  padding: ${Rem(24)} ${Rem(24)};
  border-top: 2px solid ${Colors.white};
  border-bottom: 2px solid ${Colors.white};
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  font-weight: 300;
  text-align: center;

  @media (${BP.ipad}) {
    font-size: ${Rem(30)};
    padding: ${Rem(48)} ${Rem(24)};
  }

  > span {
    font-weight: bold;
  }
`
export const StyledBeekeeperShopSummaryScroller = styled.div`
  position: relative;
  background-color: ${Colors.yellow};
  height: ${Rem(60)};

  @media (${BP.ipad}) {
    height: ${Rem(100)};
  }

  > span {
    position: absolute;
    text-align: center;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    display: block;
    font-family: inherit;
    font-size: ${Rem(18)};
    border: 2px solid ${Colors.yellow};
    border-radius: ${Rem(30)};
    padding: ${Rem(8)} ${Rem(16)};
    color: ${Colors.yellow};
    background-color: ${Colors.white};
    cursor: pointer;
    min-width: ${Rem(200)};

    @media (${BP.ipad}) {
      font-size: ${Rem(24)};
      padding: ${Rem(12)} ${Rem(24)};
    }
  }
`

export const StyledBeekeeperShopSummaryEditorial = styled.div`
  width: 100%;
`

export const StyledBeekeeperShopSummaryEditorialTitle = styled.div`
  color: ${Colors.yellow};
  font-size: ${Rem(20)};
  margin-bottom: ${Rem(24)};
  text-align: center;

  @media (${BP.ipad}) {
    font-size: ${Rem(44)};
    margin-bottom: ${Rem(44)};
  }
`

export const StyledBeekeeperShopSummaryEditorialImage = styled.img`
  width: 100%;
  height: auto;
`

export const StyledBeekeeperShopSummaryEditorialContent = styled.div`
  padding: ${Rem(20)} ${Rem(16)};

  @media (${BP.ipad}) {
    padding: ${Rem(40)} ${Rem(36)};
  }
`

export const StyledBeekeeperShopSummaryEditorialContentTitle = styled.div`
  color: ${Colors.yellow};
  font-size: ${Rem(20)};
  margin-bottom: ${Rem(24)};
  text-align: center;

  @media (${BP.ipad}) {
    font-size: ${Rem(44)};
    margin-bottom: ${Rem(44)};
  }
`

export const StyledBeekeeperShopSummaryEditorialContentSubtitle = styled.div`
  color: ${Colors.yellow};
  font-size: ${Rem(18)};
  margin-bottom: ${Rem(24)};
  text-align: center;

  @media (${BP.ipad}) {
    font-size: ${Rem(36)};
    margin-bottom: ${Rem(44)};
  }
`

export const StyledBeekeeperShopSummaryEditorialContentList = styled.div`
  font-size: ${Rem(14)};
  text-align: left;
  font-weight: 300;

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
  }

  ul {
    list-style-type: none;
    padding: 0 0 0 ${Rem(16)};
    margin: 0;

    li {
      position: relative;
      margin-bottom: ${Rem(8)};

      > span {
        display: inline-block;
      }

      &:before {
        position: absolute;
        display: inline-block;
        content: '•';
        top: 0;
        left: ${Rem(-14)};
      }
    }
  }
`

export const StyledBeekeeperShopSummaryEditorialPayments = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${Rem(40)};

  @media (${BP.ipad}) {
    flex-direction: row;
  }

  > img {
    max-height: ${Rem(90)};
    margin: 0 ${Rem(16)} ${Rem(16)};

    @media (${BP.ipad}) {
      margin: 0 ${Rem(16)};
    }

    &:last-child {
      max-height: ${Rem(60)};
      margin-bottom: 0;
    }
  }
`

export const StyledBeekeeperShopSummaryEditorialForm = styled.div`
  position: relative;
  padding: ${Rem(32)} ${Rem(16)};
  min-height: ${Rem(260)};

  @media (${BP.ipad}) {
    padding: ${Rem(48)} ${Rem(32)};
  }

  & > ${StyledLoader} {
    position: absolute;
    z-index: 9;
  }
`

export const StyledBeekeeperShopSummaryEditorialFormTitle = styled.div`
  font-size: ${Rem(28)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
  font-weight: 700;
  margin-bottom: ${Rem(48)};
`

export const StyledBeekeeperShopSummaryEditorialFormInputWithError = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${Rem(8)};

  @media (${BP.ipad}) {
    margin-right: ${Rem(24)};
  }

  width: 100%;
  max-width: ${Rem(688)};

  ${(props) =>
    props.isSmall &&
    css`
      max-width: ${Rem(134)};
    `}

  ${(props) =>
    props.isMid &&
    css`
      max-width: ${Rem(261)};
    `}

  > span {
    margin-bottom: ${Rem(8)};
  }
`

export const StyledBeekeeperShopSummaryEditorialFormBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${Rem(24)};

  > span {
    display: block;
    width: 100%;
    padding-bottom: ${Rem(8)};
    font-size: ${Rem(11)};

    @media (${BP.ipad}) {
      font-size: ${Rem(14)};
    }
  }

  > input, > ${StyledBeekeeperShopSummaryEditorialFormInputWithError} input {
    appearance: none;
    display: block;
    font-family: inherit;
    font-size: ${Rem(16)};
    padding: ${Rem(8)} ${Rem(12)};
    width: 100%;
    margin-right: ${Rem(8)};
    text-overflow: ellipsis;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

    @media (${BP.ipad}) {
      font-size: ${Rem(20)};
      padding: ${Rem(12)} ${Rem(16)};
      margin-right: ${Rem(24)};
    }
  }

  > ${StyledBeekeeperShopSummaryEditorialFormInputWithError} ${StyledBeekeeperShopChooserItemFilterInput} {
    max-width: fit-content;
    border: 3px solid ${Colors.yellow};
    border-radius: 0;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  }
`

export const StyledBeekeeperShopSummaryEditorialFormInput = styled.input`
  appearance: none;
  display: block;
  font-family: inherit;
  font-size: ${Rem(14)};
  letter-spacing: ${Rem(0.5)};
  border: 3px solid ${Colors.yellow};
  padding: ${Rem(5)} ${Rem(16)};
  width: 100%;
  max-width: ${Rem(688)};
  margin-right: ${Rem(8)};
  margin-bottom: ${Rem(8)};

  @media (${BP.ipad}) {
    margin-right: ${Rem(24)};
  }

  ${(props) =>
    props.isSmall &&
    css`
      max-width: ${Rem(134)};
    `}

  ${(props) =>
    props.isMid &&
    css`
      max-width: ${Rem(261)};
    `}
  
  ${(props) =>
    props.as === 'select' &&
    css`
      max-width: ${Rem(177)};
    `}
`

export const StyledBeekeeperShopTooltip = styled.div`
  display: inline-block;
  position: relative;
  margin-left: ${Rem(8)};
`

export const StyledBeekeeperShopTooltipTrigger = styled.div`
  width: ${Rem(18)};
  height: ${Rem(18)};
  top: ${Rem(16)};
  right: ${Rem(16)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #bdc3c7;
  color: #bdc3c7;
  border-radius: 50%;
  font-size: ${Rem(14)};
  z-index: 4;
  cursor: pointer;
`

export const StyledBeekeeperShopTooltipContent = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  background-color: ${Colors.white};
  width: auto;
  min-width: ${Rem(300)};
  max-width: ${Rem(400)};
  height: auto;
  padding: ${Rem(16)};
  font-size: ${Rem(14)};
  font-weight: 300;
  right: 50%;
  transform: translateX(50%);
  bottom: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  border-radius: ${Rem(5)};
  z-index: 99;
  text-align: center;

  &:after {
    content: '';
    display: block;
    width: ${Rem(16)};
    height: ${Rem(16)};
    background-color: ${Colors.white};
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
  }

  ${StyledBeekeeperShopTooltipTrigger}:hover + & {
    opacity: 1;
    pointer-events: all;
  }

  ${(props) =>
    props.isRight &&
    css`
      right: 0;
      transform: translateX(${Rem(60)});

      &:after {
        left: 100%;
        transform: translate(${Rem(-78)}, 50%) rotate(45deg);
      }
    `}
`


export const StyledBeekeeperShopAddressTitle = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${Rem(688)};
  align-items: center;
  margin-bottom: ${Rem(8)};
  flex-wrap: wrap;

  & > div:first-child {
    margin-bottom: ${Rem(8)};
  }

  & > ${StyledBeekeeperShopChoosersHiveFilterCheckbox} {
    margin-right: 0;
  }

  @media (${BP.ipad}) {
    flex-wrap: nowrap;

    & > div:first-child {
      margin-bottom: 0;
    }
  }
`

export const StyledDivider = styled.div`
  margin-left: ${Rem(15)};
  margin-right: ${Rem(15)};
  margin-bottom: ${Rem(40)};
  width: calc(100% - ${Rem(15)} - ${Rem(15)}); 
  height: ${Rem(2)};
  border: 0;
  background: ${Colors.yellow};
  position: relative;
`