import { Map, View, Feature, Overlay } from 'ol'
import * as Proj from 'ol/proj'
import * as Interaction from 'ol/interaction'
import * as Control from 'ol/control'
import { boundingExtent } from 'ol/extent'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { XYZ, Vector } from 'ol/source'
import { Style, Icon } from 'ol/style'
import { Point } from 'ol/geom'
import { noModifierKeys } from 'ol/events/condition'

export const addMarker = (map, position, icon, customData = {}) => {
  map.markers = map.markers || []
  map.popups = map.popups || []
  const element = document.createElement('div')
  element.classList.add('map__popup')
  const logo = document.createElement('img')
  logo.src = customData.logo
  if (customData.infowindow) {
    element.innerHTML = customData.infowindow
  } else {
    element.appendChild(logo)
  }
  const popup = new Overlay({
    element,
    positioning: 'bottom-center',
    stopEvent: false,
    offset: [0, -50],
    autoPan: true,
    id: customData.popupID,
  })

  const iconFeature = new Feature({
    geometry: new Point(Proj.transform(position, 'EPSG:4326', 'EPSG:3857')),
    customData: {
      ...customData,
      popup,
    },
  })

  iconFeature.setStyle(
    new Style({
      image: new Icon({
        anchor: [0.5, 48],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: icon,
        scale: customData.scale ?? 1,
      }),
    })
  )

  const marker = new VectorLayer({
    source: new Vector({
      features: [iconFeature],
    }),
  })

  map.addLayer(marker)
  map.addOverlay(popup)
  map.markers.push(marker)
  map.popups.push(popup)

  return marker
}

export default ({
  target,
  center,
  mapID,
  markers = [],
  handleMouseOver,
  handleMouseOut,
  handlePointerDown,
  onMapReady,
}) => {
  const initialCenter = center ? [center.lng, center.lat] : [12.34625, 41.99607]

  const baseMap = new TileLayer({
    visible: true,
    source: new XYZ({
      url:
        //'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/Tile/{z}/{y}/{x}',
      //'https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
      //'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    }),
  })

  const map = new Map({
    target,
    layers: [baseMap],
    controls: Control.defaults({
      zoom: false,
      rotate: false,
    }),
    interactions: Interaction.defaults({
      dragPan: true,
      mouseWheelZoom: false,
      doubleClickZoom: true,
    }),
    view: new View({
      center: Proj.transform(initialCenter, 'EPSG:4326', 'EPSG:3857'),
      zoom: 5.5,
    }),
  })

  map.addInteraction(
    new Interaction.MouseWheelZoom({
      condition: (e) => {
        return !noModifierKeys(e)
      },
    })
  )

  rebuildMarkers(map, markers)

  let visible = false
  map.on('click', function (evt) {
    if (evt.originalEvent.target.tagName === 'CANVAS') {
      const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature
      })
      if (visible) {
        visible.setPosition(undefined)
        visible = false
      }
      if (feature) {
        const coordinates = feature.getGeometry().getCoordinates()
        visible = feature.get('customData').popup
        visible.setPosition(coordinates)

        if (typeof feature.get('customData').onClick === 'function') {
          feature.get('customData').onClick()
        }
      }
    }
  })

  if (typeof onMapReady === 'function') {
    onMapReady({
      panTo: function (popupID, center) {
        const coords = Proj.transform(center, 'EPSG:4326', 'EPSG:3857')
        if (visible) {
          visible.setPosition(undefined)
          visible = false
        }
        map.getView().animate(
          {
            center: coords,
            zoom: 12,
          },
          function () {
            visible = map.popups.find((x) => x.id === popupID)
            visible.setPosition(coords)
          }
        )
      },
    })
  }

  return map
}

export const rebuildMarkers = (map, markers) => {
  if (map.markers) {
    map.markers.forEach((layer) => {
      map.removeLayer(layer)
    })
    map.markers = []
  }

  if (map.popups) {
    map.popups.forEach((overlay) => {
      map.removeOverlay(overlay)
    })
    map.popups = []
  }

  if (markers.length == 0) {
    return
  }
  let minLng = 180
  let minLat = 180
  let maxLng = -180
  let maxLat = -180
  const offset = 0.65
  markers.map((marker) => {
    const lng = Number(marker.center.lng.toString().replace(',', '.'))
    const lat = Number(marker.center.lat.toString().replace(',', '.'))
    minLng = lng < minLng ? lng : minLng
    minLat = lat < minLat ? lat : minLat
    maxLng = lng > maxLng ? lng : maxLng
    maxLat = lat > maxLat ? lat : maxLat
    addMarker(map, [lng, lat], marker.pin, marker)
  })
  const min = Proj.transform(
    [minLng - offset, minLat - offset],
    'EPSG:4326',
    'EPSG:3857'
  )
  const max = Proj.transform(
    [maxLng + offset, maxLat + offset],
    'EPSG:4326',
    'EPSG:3857'
  )
  const boundingBox = boundingExtent([min, max])
  map.getView().fit(boundingBox, { duration: 1000 })
}