import React from 'react'
import {
  StyledImageBlock,
} from './style'

export default ({
  image,
  theme,
  paddingTop = 0,
  paddingBottom = 0,
}) => {
  return (
    <StyledImageBlock 
      src={image} 
      isWhite={theme === 'white'} 
      paddingTop={paddingTop} 
      paddingBottom={paddingBottom} />
  )
}
