import React, { useEffect, useRef, useState } from 'react'
import {
  StyledVideoReviews,
  StyledVideoReviewsCarousel,
  StyledVideoReviewsControl,
  StyledVideoReviewsItem,
  StyledVideoReviewsItemTitle,
  StyledVideoReviewsItemVideo,
  StyledVideoReviewsSwiper,
} from './style'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import { StyledGridRow } from '../../commons/Grid'
import { Intro } from '../index'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import Swiper, { Navigation } from 'swiper'

Swiper.use([Navigation])

export default ({ intro, videos, themeColor }) => {
  const [currentVideo, setCurrentVideo] = useState(null)
  const sliderEl = useRef()
  const prevArrowEl = useRef()
  const nextArrowEl = useRef()
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    if (sliderEl && window.innerWidth >= 1024) {
      const slider = new Swiper(sliderEl.current, {
        allowTouchMove: 'ontouchstart' in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        navigation: {
          prevEl: prevArrowEl.current,
          nextEl: nextArrowEl.current,
        },
        on: {
          slideChangeTransitionStart: (e) => {
            setCurrentSlide(e.realIndex)
          },
        },
      })
    }
  }, [sliderEl])

  return (
    <StyledVideoReviews>
      <Intro {...intro} themeColor={themeColor} />
      <StyledGridRow noMargin isFullMobile>
        <StyledVideoReviewsSwiper ref={sliderEl} className="swiper-container">
          <StyledVideoReviewsCarousel className="swiper-wrapper" space={20}>
            {videos.map((video, index) => (
              <StyledVideoReviewsItem
                key={index}
                className="swiper-slide"
                isCurrent={currentSlide === index}
              >
                <StyledVideoReviewsItemVideo
                  onClick={() => setCurrentVideo(index)}
                >
                  <VimeoPlayer
                    videoID={video.videoID}
                    thumb={video.thumb}
                    autoplay={currentVideo === index}
                  />
                  <StyledVideoReviewsItemTitle>
                    {video.title}
                  </StyledVideoReviewsItemTitle>
                </StyledVideoReviewsItemVideo>
              </StyledVideoReviewsItem>
            ))}
          </StyledVideoReviewsCarousel>
        </StyledVideoReviewsSwiper>
        <StyledVideoReviewsControl ref={prevArrowEl}>
          <Icon icon={icons.chevronLeft} color={Colors.white} />
        </StyledVideoReviewsControl>
        <StyledVideoReviewsControl ref={nextArrowEl} next>
          <Icon icon={icons.chevronRight} color={Colors.white} />
        </StyledVideoReviewsControl>
      </StyledGridRow>
    </StyledVideoReviews>
  )
}
