import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'

export const StyledAdoptionCarousel = styled.div`
  position: relative;
  text-align: center;
`

export const StyledAdoptionCarouselImages = styled.div`
  position: relative;
  width: 100%;
  max-width: ${Rem(1000)};
`

export const StyledAdoptionCarouselImagesControl = styled.div`
  position: absolute;
  display: none;
  top: ${Rem(150)};
  left: ${Rem(98)};
  z-index: 2;
  cursor: pointer;

  ${StyledIcon} {
    width: ${Rem(78)};
  }

  @media (${BP.ipad}) {
    top: ${Rem(400)};
    display: inline;
  }

  ${(props) =>
    props.next &&
    css`
      left: auto;
      right: ${Rem(50)};
      ${StyledIcon} {
        transform: rotate(180deg);
      }
    `}
`

export const StyledAdoptionCarouselImagesSlide = styled.div`
  min-width: ${Rem(325)};
  max-width: ${Rem(325)};

  @media (${BP.ipad}) {
    min-width: ${Rem(390)};
    max-width: ${Rem(390)};
  }
`

export const StyledAdoptionCarouselImagesSlideImage = styled.div`
  ${Ratio(390, 300)};
  position: relative;
  width: 100%;
  border-radius: ${Rem(50)};
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  transform: scale(0.4);
  transition: transform 0.2s ease-out 0s;
  margin-bottom: ${Rem(60)};
  box-shadow: 0 2px 4px 0 ${Colors.black};
  
  .swiper-slide-visible & {
    z-index: 2;
    transform: scale(1) translate(0, ${Rem(0)});
    transform-origin: center center;
  }

  .swiper-slide-visible.swiper-slide-prev & {
    transform: scale(0.85) translateX(12%);
    transform-origin: right center;
    z-index: 1;
  }

  .swiper-slide-visible.swiper-slide-next & {
    transform: scale(0.85) translateX(-12%);
    transform-origin: left center;
    z-index: 1;
  }
`

export const StyledAdoptionCarouselImagesSlideName = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: ${Rem(24)};
  color: ${Colors.white};
  transition: opacity 0.25s ease-out 0s;
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};

  .swiper-slide-visible & {
    opacity: 1;
    pointer-events: all;
  }

  .swiper-slide-visible.swiper-slide-prev & {
    opacity: 0;
    pointer-events: none;
  }

  .swiper-slide-visible.swiper-slide-next & {
    opacity: 0;
    pointer-events: none;
  }
`

export const StyledAdoptionCarouselContents = styled.div``

export const StyledAdoptionCarouselContentsItem = styled.div`
  text-align: center;
`

export const StyledAdoptionCarouselContentsItemTitle = styled.h2`
  font-size: ${Rem(30)};
  line-height: ${Rem(56)};
  padding-bottom: ${Rem(12)};
`

export const StyledAdoptionCarouselContentsItemDescription = styled.p`
  position: relative;
  font-weight: 300;
  font-size: ${Rem(25)};
  line-height: ${Rem(32)};
  padding-top: ${Rem(12)};

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: ${Rem(3)};
    border-radius: ${Rem(10)};
    top: 0;
    left: 0;
    background-color: ${Colors.gray};
  }
`
