import React, {useContext} from 'react'
import {
  StyledPagesPreview,
  StyledPagesPreviewPage,
  StyledPagesPreviewPageMedia,
  StyledPagesPreviewPageTitle,
  StyledPagesPreviewPageCta,
} from './style.jsx'
import { Link } from 'gatsby'
import { StyledGridRow } from '../../commons/Grid'
import Icon, { icons } from '../Icon/Icon'
import Button from '../Button/Button'
import Scroller from '../Scroller/Scroller'
import { DataContext } from '../../contexts/DataContext'

export default ({ pages }) => {
  const { i18nLink } = useContext(DataContext)
  return (
    <StyledGridRow isFullMobile>
      <Scroller>
        <StyledPagesPreview>
          {pages.map((page, index) => (
            <StyledPagesPreviewPage key={index}>
              <StyledPagesPreviewPageTitle>
                {page.title}
              </StyledPagesPreviewPageTitle>
              <StyledPagesPreviewPageCta>
                <Button as="div" to={page.cta.url ? i18nLink(`${page.cta.url}`) : null}>
                  {page.cta.label}
                  <Icon icon={icons.arrowRight} />
                </Button>
              </StyledPagesPreviewPageCta>
              <StyledPagesPreviewPageMedia bg={page.image} />
            </StyledPagesPreviewPage>
          ))}
        </StyledPagesPreview>
      </Scroller>
    </StyledGridRow>
  )
}
