import styled, { css } from 'styled-components'
import { Colors, Rem } from '../../commons/Theme'


export const StyledTextBlock = styled.p`
  color: ${Colors.white};
  background-color: ${Colors.black};
  padding: ${Rem(30)} ${Rem(20)};
  text-align: center;
  
  margin-top: ${(props) => Rem(props.marginTop)};
  margin-bottom: ${(props) => Rem(props.marginBottom)};

  ${(props) =>
    props.isWhite &&
    css`
      color: ${Colors.black};
      background-color: ${Colors.white};
    `}
`
