import React, { useEffect, useRef } from 'react'
import {
  StyledAdoptionCarousel,
  StyledAdoptionCarouselContents,
  StyledAdoptionCarouselContentsItem,
  StyledAdoptionCarouselContentsItemDescription,
  StyledAdoptionCarouselContentsItemTitle,
  StyledAdoptionCarouselImages,
  StyledAdoptionCarouselImagesControl,
  StyledAdoptionCarouselImagesSlide,
  StyledAdoptionCarouselImagesSlideImage,
  StyledAdoptionCarouselImagesSlideName,
} from './style'
import { StyledGridRow } from '../../commons/Grid'
import Swiper, { Navigation, EffectFade, Controller, Virtual } from 'swiper'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { StyledBigText } from '../../commons/Typography'

Swiper.use([Navigation, EffectFade, Controller, Virtual])

export default ({ title, items }) => {
  const sliderEl = useRef()
  const prevArrowEl = useRef()
  const nextArrowEl = useRef()
  const sliderContentsEl = useRef()

  useEffect(() => {
    if (sliderEl && sliderContentsEl) {
      const contentsSlider = new Swiper(sliderContentsEl.current, {
        allowTouchMove: false,
        observer: true,
        observeParents: true,
        autoHeight: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
      })

      new Swiper(sliderEl.current, {
        allowTouchMove: 'ontouchstart' in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        navigation: {
          prevEl: prevArrowEl.current,
          nextEl: nextArrowEl.current,
        },
        on: {
          slideChange: (slider) => {
            contentsSlider.slideTo(slider.realIndex)
          },
        },
      })
    }
  }, [sliderEl, sliderContentsEl])

  return (
    <StyledAdoptionCarousel id="adoptionDescription">
      <StyledGridRow isFullMobile>
        <StyledBigText dangerouslySetInnerHTML={{ __html: title }} />
        <StyledAdoptionCarouselImages
          ref={sliderEl}
          className="swiper-container"
        >
          <div className="swiper-wrapper">
            {items.map((item, index) => (
              <StyledAdoptionCarouselImagesSlide
                key={index}
                className="swiper-slide"
              >
                <StyledAdoptionCarouselImagesSlideImage bg={item.image}>
                </StyledAdoptionCarouselImagesSlideImage>
              </StyledAdoptionCarouselImagesSlide>
            ))}
          </div>
        </StyledAdoptionCarouselImages>
        <StyledAdoptionCarouselImagesControl ref={prevArrowEl}>
          <Icon icon={icons.arrowLeft} color={Colors.yellow} />
        </StyledAdoptionCarouselImagesControl>
        <StyledAdoptionCarouselImagesControl next ref={nextArrowEl}>
          <Icon icon={icons.arrowLeft} color={Colors.yellow} />
        </StyledAdoptionCarouselImagesControl>
        <StyledAdoptionCarouselContents
          ref={sliderContentsEl}
          className="swiper-container"
        >
          <div className="swiper-wrapper">
            {items.map((item, index) => (
              <StyledAdoptionCarouselContentsItem
                key={index}
                className="swiper-slide"
              >
                <StyledAdoptionCarouselContentsItemTitle
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <StyledAdoptionCarouselContentsItemDescription
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </StyledAdoptionCarouselContentsItem>
            ))}
          </div>
        </StyledAdoptionCarouselContents>
      </StyledGridRow>
    </StyledAdoptionCarousel>
  )
}
