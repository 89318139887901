import { useEffect } from 'react'

const useSnipcartEvents = () => {
  useEffect(() => {
    const { Snipcart } = window
    if (!Snipcart) return

    function getProduct(cartItem) {
      return {
        id: cartItem.id,
        name: cartItem.name,
        price: cartItem.unitPrice,
        quantity: cartItem.quantity,
        variant: cartItem.customFields.length == 0 ? null : cartItem.customFields[0].value,
      }
    }

    Snipcart.events.on('item.adding', cartItem => {
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [{
            item_name: cartItem.name,
            price: cartItem.price,
            item_id: cartItem.id,
            quantity: cartItem.quantity,
          }]
        },
      })
    })

    Snipcart.events.on('theme.routechanged', routesChange => {
      if (routesChange.to === '/checkout') {
        window.dataLayer.push({
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: { step: 1 }
            }
          },
        })
      }
    })

    Snipcart.events.on('shipping.selected', shippingMethod => {
      window.dataLayer.push({
        event: 'checkoutPerk',
        ecommerce: {
          checkout: {
            actionField: { step: 2 }
          }
        },
      })
    })

    Snipcart.events.on('cart.confirmed', cartConfirmResponse => {
      const tax = cartConfirmResponse.taxes.items.length === 0 
        ? undefined 
        : cartConfirmResponse.taxes.items[0].amount;
      window.dataLayer.push({
        event: 'purchasePerk',
        ecommerce: {
          purchase: {
            actionField: {
              id: cartConfirmResponse.invoiceNumber,                  // Transaction ID. Required for purchases and refunds.
              revenue: cartConfirmResponse.total,                     // Total transaction value (incl. tax and shipping)
              tax: tax,
              shipping: cartConfirmResponse.shippingDetails.cost,
            },
            products: cartConfirmResponse.items.items.map(getProduct),
          }
        }
      })
    })
  }, [])
}

export default useSnipcartEvents
