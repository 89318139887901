import axios from "axios";
import { useContext } from "react";
import { DataContext } from "../contexts/DataContext";

const getPipeformHowKnowUsOptions = (labels) => [{
  id: 62,
  value: labels.pipeformHowKnowUsOptions.searchEngine,
},{
  id: 63,
  value: labels.pipeformHowKnowUsOptions.linkedin,
},{
  id: 64,
  value: labels.pipeformHowKnowUsOptions.facebookInstagraa,
},{
  id: 65,
  value: labels.pipeformHowKnowUsOptions.csrEvent,
},{
  id: 66,
  value: labels.pipeformHowKnowUsOptions.words,
},{
  id: 67,
  value: labels.pipeformHowKnowUsOptions.tvRadio,
},{
  id: 68,
  value: labels.pipeformHowKnowUsOptions.newspaperBlog,
},{
  id: 69,
  value: labels.pipeformHowKnowUsOptions.alreadyPrivateAdopter,
},{
  id: 85,
  value: labels.pipeformHowKnowUsOptions.other,
}];

const getLanguage = () => {
  if (window.location.pathname.startsWith("/en")) {
    return 193;
  }
  if (window.location.pathname.startsWith("/es")) {
    return 194;
  }
  if (window.location.pathname.startsWith("/de")) {
    return 195;
  }
  if (window.location.pathname.startsWith("/fr")) {
    return 196;
  }
  return 192;
};

const getAds = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("fbclid")) {
    // Facebook
    return 226;
  }
  if (urlParams.has("gclid")) {
    // Google
    return 224;
  }
  if (urlParams.has("li_fat_id")) {
    // Linkedin
    return 223;
  }
  if (urlParams.has("msclkid")) {
    // Microsoft
    return 225;
  }
  
  // No
  return 222;
};

const getAdsCampaign = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("fbclid")) {
    // Facebook
    return urlParams.get("fbclid");
  }
  if (urlParams.has("gclid")) {
    // Google
    return urlParams.get("gclid");
  }
  if (urlParams.has("li_fat_id")) {
    // Linkedin
    return urlParams.get("li_fat_id");
  }
  if (urlParams.has("msclkid")) {
    // Microsoft
    return urlParams.get("msclkid");
  }
};

const sendPipeform = ({
  name, email, companyName, phone, acceptMarketing, numEmployees, message, howKnowUsId,
}) => {
  const params =  {
    V2ViRm9ybUNhcHR1cmVCbG9jazo5NDMzNWFkMC1mN2E5LTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: name ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo5NDMzNWFkMS1mN2E5LTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: email ?? "NA@3bee.com", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazpmOTI2YjU0MS1mN2E5LTExZWMtOGU4Ni1kMzg4MDI2NzRkOGM: companyName ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo5NDMzNWFkMi1mN2E5LTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: phone  ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazpmOTI2YjU0MC1mN2E5LTExZWMtOGU4Ni1kMzg4MDI2NzRkOGM: acceptMarketing ? [50] : [],
    V2ViRm9ybUNhcHR1cmVCbG9jazpmOTI2ZGM1MC1mN2E5LTExZWMtOGU4Ni1kMzg4MDI2NzRkOGM: numEmployees ?? "", 
    V2ViRm9ybUNhcHR1cmVCbG9jazo5NDMzNWFkMy1mN2E5LTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: message ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazpjMGE0ZDNkMC0yN2E4LTExZWQtOTY4Yi1mNWZiZWVmYWE1N2Y: message ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazpmOTI2ZGM1MS1mN2E5LTExZWMtOGU4Ni1kMzg4MDI2NzRkOGM: howKnowUsId ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazpiYTRjYTFmMC1mN2FkLTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: getLanguage(), // required
    V2ViRm9ybUNhcHR1cmVCbG9jazpiYTRjYzkwMC1mN2FkLTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: getAds(), // required
    V2ViRm9ybUNhcHR1cmVCbG9jazpiYTRjYzkwMS1mN2FkLTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: getAdsCampaign() ?? "NA",
    V2ViRm9ybUNhcHR1cmVCbG9jazpiYTRjYzkwMi1mN2FkLTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: window.location.href,
  };

  /// Definitions: https://webforms.pipedrive.com/f/1HGGAPEq6LcyOvetUpjbT1d9joSmPWObg80T7rVG1PNOeL6oVxVieUXErxkfsATKP/definition
  return axios
    .post(
      "https://webforms.pipedrive.com/f/1HGGAPEq6LcyOvetUpjbT1d9joSmPWObg80T7rVG1PNOeL6oVxVieUXErxkfsATKP",
      {
        values: params,
      },
    );
};

const usePipeform = () => {
  const { labels } = useContext(DataContext);

  return {
    pipeformHowKnowUsOptions: getPipeformHowKnowUsOptions(labels),
    sendPipeform,
  };
};

export default usePipeform;
