import styled from 'styled-components'
import { Rem, BP } from '../../commons/Theme'
import { StyledButton } from '../Button/style'

export const StyledHiveTechCrowdHeader = styled.div`
  padding: ${Rem(0)} ${Rem(20)};
  padding-bottom: ${Rem(50)};
  text-align: justify;
  @media (${BP.desktop}) {
    padding-left: ${Rem(70)};
    padding-top: 0px;
    margin-top: 0px;
  }

  ${StyledButton} {
    margin-top: ${Rem(20)};
    margin-right ${Rem(20)};
  }
`

export const StyledHiveTechCrowdHeaderTitle = styled.h1`
  font-size: ${Rem(25)};
  line-height: ${Rem(35)};
  padding-bottom: ${Rem(10)};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    line-height: ${Rem(55)};
    padding-bottom: ${Rem(20)};
    padding-top: 0px;
  }
`
export const StyledHiveTechCrowdHeaderSubtitle = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(10)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(30)};
    padding-bottom: ${Rem(20)};
  }
`

export const StyledHiveTechCrowdHeaderParagraph = styled.p`
  font-size: ${Rem(16)};
  padding: ${Rem(10)} 0;

  span {
    font-size: ${Rem(24)};
  }

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    padding: ${Rem(10)} 0;

    span {
      font-size: ${Rem(30)};
    }
  }
`