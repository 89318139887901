import React, {useContext} from 'react'
import {
  StyledTextAndImage,
  StyledTextAndImageContent,
  StyledTextAndImageImage,
  StyledTextAndImageContentList,
  StyledTextAndImageContentListItem,
} from './style'
import { StyledGridRow } from '../../commons/Grid'
import {
  StyledTitle,
  StyledBigText,
  StyledParagraph,
} from '../../commons/Typography'
import Button from '../Button/Button'
import { Link } from 'gatsby'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { DataContext } from '../../contexts/DataContext'

export default ({
  title,
  bigText,
  subtitle,
  list,
  cta,
  image,
  theme,
  upside,
  imageProps = {},
  children,
}) => {
  const { i18nLink  } = useContext(DataContext)
  return (
    <StyledTextAndImage theme={theme} upside={upside}>
      <StyledGridRow noMargin>
        <StyledTextAndImageContent minHeight={imageProps.height}>
          {title && <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />}
          <StyledBigText dangerouslySetInnerHTML={{ __html: bigText }} />
          {subtitle && (
            <StyledParagraph dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}
          {list && (
            <StyledTextAndImageContentList>
              {list.map((item, index) => (
                <StyledTextAndImageContentListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </StyledTextAndImageContentList>
          )}
          {cta && (
            <Button as="div" theme="white" noPadding to={cta.url ? i18nLink(`${cta.url}`) : null}>
              {cta.label}
              <Icon icon={icons.arrowRight} color={Colors.black} />
            </Button>
          )}
          {children}
        </StyledTextAndImageContent>
      </StyledGridRow>
      <StyledTextAndImageImage
        bg={image}
        bgSize={imageProps.size}
        bgPosition={imageProps.position}
      />
    </StyledTextAndImage>
  )
}
