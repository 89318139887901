import React, { useContext } from 'react'
import { DataContext } from '../../contexts/DataContext';
import {
  StyledDiscoverHiveTechButton,
  StyledDiscoverHiveTechButtonContainer,
} from './style'

export default ({
  label,
}) => {

  const { setSelectedSectionId } = useContext(DataContext)

  return (
    <StyledDiscoverHiveTechButtonContainer>
      <StyledDiscoverHiveTechButton onClick={() => {
          setSelectedSectionId('polly')
        }}>
        {label}
      </StyledDiscoverHiveTechButton>
    </StyledDiscoverHiveTechButtonContainer>
  )
}
