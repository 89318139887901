import React, {useContext, useState, useEffect} from 'react'
import {
  StyledProductCard, 
  StyledShippingLabel, 
  StyledDivider,
  StyledPriceValues,
  StyledProductType, StyledName, StyledInitialPrice, StyledFinalPrice, StyledPriceParagraph,
  StyledDiscountPercentage,
  StyledCardBody,
  StyledEstimatedShippingContainer, StyledEstimatedShippingLabel, StyledShippingMonth,
  StyledQuantityLeftContainer, StyledQuantityLeftText, StyledQuantityLeft,
  StyledShippingTo, StyledImage, StyledShippingToContainer, StyledSaveCircle, StyledSaveCircleText,
  StyledFlag,
  StyledShippingLabels, StyledShippingLabelSmall,
  StyledTopLeftMessages,
  StyledPriceSubtitle,
  StyledProductQuantity,
  StyledImageLabels,
  StyledRedRows, StyledRedRow,
  StyledImageNote,
  SytledTexOverlay,
  StyledQuestionMark,
  SytledTexOverlayText,
  StyledFeatures, StyledFeaturesIcon, StyledFeatureList, StyledFeature
} from './style'
import { DataContext } from '../../contexts/DataContext'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { LayoutContext } from '../../contexts/LayoutContext'

// eslint-disable-next-line no-unused-vars
import Modal from '../Modal/Modal'

export default ({
  productId,
  name,
  url,
  image,
  sections,
  shippingLabel,
  shippingLabels,
  productType,
  finalPrice,
  initialPrice,
  discountPercentage,
  shippingMonth,
  quantityLeft,
  shippingTo,
  icon,
  saveCircleText, saveCircleTop, saveCircleRight,
  priceDetails,
  priceSubtitle,
  productQuantity,
  topLeftMessages,
  imageLabels,
  long_description,
  features,
  imageNote,
  weight,
  cartDescription,
  cartCustom1Name,
  cartCustom1Options,
  
}) => {

    const getCartItemName = (name, productQuantity) => {
      const suffix = productQuantity && ["x 1", "x1"].indexOf(productQuantity.toLowerCase()) ? ` ${productQuantity}` : ""
      return `${name}${suffix}`
    }

    const { labels, selectedSectionId } = useContext(DataContext)
    const { setModalContent } = useContext(LayoutContext)
    const showCircle = saveCircleText && saveCircleTop && saveCircleRight;
    const hideShippingDiv = (!shippingLabel || shippingLabel === ' ')
    const showProductCard = sections && sections.indexOf(selectedSectionId) > -1;
    const cartItemName = getCartItemName(name, productQuantity)
    const outOfStock = quantityLeft[0] == '0'
    const [showDescription, setShowDescription] = useState(false)

    useEffect(()=>{
      if(showDescription){
        if (typeof window !== `undefined`) {
          window.dataLayer.push({
            event: 'perkContentView',
            ecommerce: {
              idPerk: productId,
              currencyCode: 'EUR',
              click: {
                products: [
                  {
                    name: name,
                    id: productId,
                    price: finalPrice,
                    brand: 'mypolly',
                  },
                ],
              },
            },
          })
        }
    }},
    [showDescription])

    return showProductCard ? (
      
      <StyledProductCard id={`productCard-${productId}`}>
          <SytledTexOverlay showDescription={showDescription} onClick={() => {setShowDescription(!showDescription)}}>
            <SytledTexOverlayText dangerouslySetInnerHTML={{ __html: long_description }}>
            </SytledTexOverlayText>
          </SytledTexOverlay>
          {
            shippingLabels && shippingLabels.length > 0 
          ?
            <StyledShippingLabels>
              {shippingLabels.map((label, index) => (
                <StyledShippingLabelSmall key={index} >{label.text}</StyledShippingLabelSmall>
              ))}
            </StyledShippingLabels>
          : 
            <StyledShippingLabel empty={hideShippingDiv}>{shippingLabel}</StyledShippingLabel>
          }
          
          <StyledQuestionMark onClick={() => {setShowDescription(!showDescription)}} showDescription={showDescription} src={showDescription ? "/2020/svg/icons/cross.svg":"/2020/svg/icons/questionmark.svg"}  />
          { topLeftMessages && topLeftMessages.items &&
            <StyledTopLeftMessages bold={topLeftMessages.bold}>
              {topLeftMessages.items.map((item, index) => (
                  <p key={index} >{item.text}</p>
              ))}
            </StyledTopLeftMessages>
          }

          { productQuantity && <StyledProductQuantity>{productQuantity}</StyledProductQuantity> }

          
          
          <StyledImage src={image} alt={name} />
          
          {showCircle && (
            <StyledSaveCircle circleTop={saveCircleTop} circleRight={saveCircleRight}>
              <StyledSaveCircleText
                dangerouslySetInnerHTML={{ __html: saveCircleText }}
              />
            </StyledSaveCircle>
            )}
          { imageLabels && imageLabels.items &&
            <StyledImageLabels bold={imageLabels.bold}>
              {imageLabels.items.map((item, index) => (
                  <p key={index} >{item.text}</p>
              ))}
            </StyledImageLabels>
          }
         
          <StyledDivider imageNote={imageNote}/>

          <StyledCardBody>
            <StyledName>
              { name }
              {features && 
                <StyledFeatures>
                  <StyledFeaturesIcon
                    onClick={() => {setModalContent(
                      <StyledFeatureList>
                        <h5 class="featureListTitle">{labels.FEATURE_LIST}</h5>
                        {features.weight && <StyledFeature><span class="label">{labels.WEIGHT}:</span> {features.weight}</StyledFeature>}
                        {features.features && <StyledFeature><span class="label">{labels.FEATURES}:</span> {features.features}</StyledFeature>}
                        {features.includedPlan && <StyledFeature><span class="label">{labels.INCLUDED_PLAN}:</span> {features.includedPlan}</StyledFeature>}
                        {features.shippingCosts && <StyledFeature><span class="label">{labels.SHIPPING_COSTS}:</span> {features.shippingCosts}</StyledFeature>}
                        {features.dimensions && <StyledFeature><span class="label">{labels.DIMENSIONS}:</span> {features.dimensions}</StyledFeature>}
                        {features.paymentMethods && <StyledFeature><span class="label">{labels.PAYMENT_METHODS}:</span> {features.paymentMethods}</StyledFeature>}
                      </StyledFeatureList>
                    )}} 
                  >?</StyledFeaturesIcon>
                </StyledFeatures>
              }
            </StyledName>

            <StyledPriceParagraph>
              <StyledPriceValues>
                <StyledFinalPrice>{ finalPrice } &euro;</StyledFinalPrice>
                <StyledRedRows>
                  <StyledRedRow>
                    <StyledInitialPrice>{ initialPrice }</StyledInitialPrice>
                    <StyledDiscountPercentage>{ discountPercentage }</StyledDiscountPercentage>
                  </StyledRedRow>
                  { priceDetails && 
                  <StyledRedRow dangerouslySetInnerHTML={{ __html: priceDetails }}>
                  </StyledRedRow>
                  }
                </StyledRedRows>
              </StyledPriceValues>
              {priceSubtitle && <StyledPriceSubtitle dangerouslySetInnerHTML={{ __html: priceSubtitle }}/>}
            </StyledPriceParagraph>
            
            <StyledEstimatedShippingContainer>
              <StyledEstimatedShippingLabel>{ labels.ESTIMATED_SHIPPING }</StyledEstimatedShippingLabel>
              <StyledShippingMonth>{ shippingMonth }</StyledShippingMonth>
            </StyledEstimatedShippingContainer>

            <StyledQuantityLeftContainer>
              <StyledQuantityLeftText>{ labels.QUANTITY_LEFT_ONLY }</StyledQuantityLeftText>
              <StyledQuantityLeft> { quantityLeft } </StyledQuantityLeft>
              <StyledQuantityLeftText>{ labels.QUANTITY_LEFT }</StyledQuantityLeftText>
            </StyledQuantityLeftContainer>

            {outOfStock && (
              <Button as="div"
                theme="yellow"
                disabled={outOfStock}
              >
                {outOfStock ? labels.BUY : labels.SOLDOUT}{' '}
                <Icon icon={icons.arrowRight} color={Colors.white} />
              </Button>
            )}

            {!outOfStock && (
              <Button as="div"
                theme="yellow"
                className="snipcart-add-item"
                dataitemid={productId}
                dataitemurl={url}
                dataitemimage={image}
                dataitemprice={finalPrice}
                dataitemname={cartItemName}
                dataitemweight={weight}
                dataitemdescription={cartDescription}
                dataitemcustom1name={cartCustom1Name}
                dataitemcustom1options={cartCustom1Options}
              >
                {labels.BUY}{' '}
                <Icon icon={icons.arrowRight} color={Colors.white} />
              </Button>
            )}
            
          </StyledCardBody> 
      </StyledProductCard>
      ) : null
}
