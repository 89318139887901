import { useEffect, useState } from 'react'

const useCounter = (finalValue, milliseconds) => {
  const [count, setCount] = useState(0)

  const msInc = 10
  const steps = milliseconds / msInc
  const inc = finalValue / steps

  useEffect(() => {
    if (count >= finalValue) {
      setCount(finalValue)
      return
    }
    setTimeout(() => {
      const newValue = Math.ceil(count+inc)
      if (newValue >= finalValue) {
        setCount(finalValue)
      } else {
        setCount(newValue)
      }
    }, msInc)
  }, [count])

  return count
}

export default useCounter
