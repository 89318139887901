import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledPressScroller = styled.div`
  padding-top: ${Rem(40)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(94)};
    padding-bottom: ${Rem(40)};
  }
`

export const StyledPressScrollerItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledPressScrollerItem = styled.div`
  position: relative;
  min-width: 80vw;
  margin-left: ${Rem(20)};

  @media (${BP.ipad}) {
    min-width: ${Rem(396)};
  }

  &:first-child {
    @media (${BP.ipad}) {
      margin-left: 0;
    }
  }

  &:last-child {
    &:after {
      position: relative;
      content: '';
      display: block;
      width: ${Rem(20)};
      height: ${Rem(50)};
      left: 100%;
      background-color: ${Colors.transparent};

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }
`

export const StyledPressScrollerItemContent = styled.div`
  ${Ratio(387, 425)};
  background-image: url('${(props) => props.bg}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
`
