import React from 'react'
import {
  StyledCommunity,
  StyledCommunityTitle,
  StyledCommunitySubTitle,
  StyledCommunityParagraph,
  StyledCommunityItems,
  StyledCommunityItem,
  StyledCommunityItemImage,
  StyledCommunityItemCount,
  StyledCommunityItemLabel,
} from './style.jsx'
import { StyledGridRow } from '../../commons/Grid'
export default ({ title, subtitle, paragraph, items }) => {
  return (
    <StyledCommunity>
      <StyledGridRow noMargin>
        <StyledCommunityTitle>{title}</StyledCommunityTitle>
        <StyledCommunitySubTitle
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
        <StyledCommunityParagraph>{paragraph}</StyledCommunityParagraph>
        <StyledCommunityItems>
          {items.map((item, index) => (
            <StyledCommunityItem key={index}>
              <StyledCommunityItemImage src={item.image} />
              <StyledCommunityItemCount>{item.count}</StyledCommunityItemCount>
              <StyledCommunityItemLabel>{item.label}</StyledCommunityItemLabel>
            </StyledCommunityItem>
          ))}
        </StyledCommunityItems>
      </StyledGridRow>
    </StyledCommunity>
  )
}
