import React, { useContext, useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import {
  StyledBeekeeper,
  StyledBeekeeperActions,
  StyledBeekeeperContent,
  StyledBeekeeperDescription,
  StyledBeekeeperGallery,
  StyledBeekeeperInformation,
  StyledBeekeeperMobileImage,
  StyledBeekeeperName,
  StyledBeekeeperService,
  StyledBeekeeperServices,
  StyledBeekeepersList,
  StyledBeekeepersListScroller,
  StyledBeekeepersMap,
  StyledBeekeepersWrapper,
  StyledBeekeepersBloomingChange,
} from './style'
import Map from '../Map/Map'
import SmallGallery from '../SmallGallery/SmallGallery'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { DataContext } from '../../contexts/DataContext'
import { StyledGridRow } from '../../commons/Grid'
import OpenLayers from '../OpenLayers/OpenLayers'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

const cropText = (text) => {
  const limit = 146
  if (text.length < limit) {
    return text
  }

  let counter = 0
  return `${text
    .split(' ')
    .reduce((res, word) => {
      counter += word.length
      if (counter < limit) {
        res.push(word)
      }
      return res
    }, [])
    .join(' ')}${counter <= limit ? '' : '...'}`
}

export default ({ isLoading, items, urlParams }) => {
  const { labels, i18nLink } = useContext(DataContext)
  const [activeMapItem, setActiveMapItem] = useState(null)
  const [map, setMap] = useState(null)
  const scroller = useRef(null)
  return (
    <StyledBeekeepersWrapper>
      <StyledGridRow>
        <StyledBeekeepersBloomingChange>
          <Button
            theme="white"
            handleOnClick={() => {
              gsap.to(window, {
                scrollTo: {
                  y: 0,
                },
                duration: 1.0,
              })
            }}
          >
            Cambia Fioritura
          </Button>
        </StyledBeekeepersBloomingChange>
        <StyledBeekeepersList>
          <StyledBeekeepersListScroller ref={scroller}>
            {items.map((item, index) => (
              <StyledBeekeeper
                key={index}
                data-id={item.id}
                onClick={() => {
                  if (activeMapItem === item.id) {
                    navigate(i18nLink(`/beekeeper/${item.slug}?${urlParams}`))
                  } else {
                    setActiveMapItem(item.id)
                    map.panTo(item.id, [item.longitude, item.latitude])
                  }
                }}
              >
                {item.slider.length > 0 && (
                  <>
                    <StyledBeekeeperMobileImage bg={item.slider[0]} />
                    <StyledBeekeeperGallery>
                      <SmallGallery items={item.slider} />
                      <StyledBeekeeperServices>
                        {item.services.map((service, index) => (
                          <StyledBeekeeperService key={index}>
                            {service}
                          </StyledBeekeeperService>
                        ))}
                      </StyledBeekeeperServices>
                    </StyledBeekeeperGallery>
                  </>
                )}
                <StyledBeekeeperContent
                  onClick={() => {
                    //navigate(`/beekeeper/${item.slug}?${urlParams}`)
                  }}
                >
                  <StyledBeekeeperName
                    dangerouslySetInnerHTML={{ __html: item.fullname }}
                  />
                  <StyledBeekeeperDescription
                    dangerouslySetInnerHTML={{
                      __html: cropText(item.company_description),
                    }}
                  />
                  <StyledBeekeeperInformation
                    dangerouslySetInnerHTML={{ __html: item.informations }}
                  />
                </StyledBeekeeperContent>
                <StyledBeekeeperActions>
                  <Button
                    as="div"
                    to={i18nLink(`/beekeeper/${item.slug}?${urlParams}`)}
                  >
                    {labels.GO_TO_BEEKEEPER}
                    <Icon icon={icons.arrowRight} color={Colors.black} />
                  </Button>
                  <Button
                    as="div"
                    handleOnClick={() => {
                      map.panTo(item.id, [item.longitude, item.latitude])
                    }}
                  >
                    {labels.VIEW_ON_MAP}
                    <Icon icon={icons.arrowRight} color={Colors.black} />
                  </Button>
                </StyledBeekeeperActions>
              </StyledBeekeeper>
            ))}
          </StyledBeekeepersListScroller>
        </StyledBeekeepersList>
      </StyledGridRow>
      <StyledBeekeepersMap>
        {!isLoading && (
          <OpenLayers
            markers={items.map((x) => {
              let urlPin = '/2020/images/biodiversity/pin.png'
              if (!!x.honeys.find((x) => x.special)) {
                urlPin = '/2020/images/biodiversity/beechristmas.png'
              }
              const link = i18nLink(`/beekeeper/${x.slug}?${urlParams}`)
              return {
                pin: urlPin,
                center: {
                  lat: x.latitude,
                  lng: x.longitude,
                },
                popupID: x.id,
                infowindow: `<div style="display: table; align-items: center; justify-content: center;"><strong style="display: table-row; vertical-align: middle">${x.fullname}</strong><button onclick="window.location.href = '${link}'" style="text-align: center; margin: 0 auto; display: block; vertical-align: middle; cursor: pointer; font-size: 14px; padding: 5px; margin-top: 5px; color: rgba(255,255,255,1); background-color: rgba(255,176,0,1); border: none">Scopri di più</button></div>`,
                onClick: () => {
                  if (activeMapItem === x.id) {
                    navigate(i18nLink(`/beekeeper/${x.slug}?${urlParams}`))
                  }
                  setActiveMapItem(x.id)
                  const target = scroller.current.querySelector(
                    `[data-id="${x.id.toString()}"]`
                  )
                  scroller.current.scrollTo({
                    top: target.offsetTop,
                    left: target.offsetLeft,
                    behavior: 'smooth',
                  })
                },
              }
            })}
            onMapReady={setMap}
          />
        )}
      </StyledBeekeepersMap>
    </StyledBeekeepersWrapper>
  )
}
