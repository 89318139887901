import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem
} from '../../commons/Theme'


export const StyledAdoptStatistics = styled.div`
`

export const StyledItemsScroller = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

//   @media ($ {breakpoint}) {
//     overflow: hidden;
//     padding: ${Rem(16)} 0;
//     justify-content: space-between;
//   }
  
`

export const StyledItem = styled.div`
  margin-left: ${Rem(22)};
  min-width: ${Rem(210)};
  max-width: ${Rem(210)};
  text-align: center;

  &:last-child {
    margin-top: -1px;

    &:after {
      position: relative;
      content: '';
      display: block;
      width: ${Rem(20)};
      height: 1px;
      left: 100%;

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }
`

export const StyledItemImage = styled.div`
  width: ${Rem(124)};
  height: ${Rem(82)};
  margin: 0 auto ${Rem(12)};
  background-image: url('${(props) => props.bg}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export const StyledItemTitle = styled.h2`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
  padding-bottom: ${Rem(12)};
  color: ${Colors.yellow};
`

export const StyledItemDescription = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
`
