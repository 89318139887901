import React, { useContext, useEffect } from 'react'
import usePageBuilder from '../hooks/usePageBuilder'
import { LayoutContext } from '../contexts/LayoutContext'
import { BookModal } from '../components'
import { DataContext } from '../contexts/DataContext'
import useSnipcartEvents from '../hooks/useSnipcartEvents'

export default () => {
  const { props } = usePageBuilder()
  const { setModalContent } = useContext(LayoutContext)
  const { labels } = useContext(DataContext)
  useSnipcartEvents()


  return props.children
}