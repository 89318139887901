import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

const useScrollController = () => {
  gsap.registerPlugin(ScrollToPlugin)

  const scrollToCssSelector = (cssSelector, topMargin = 0) => {
    if (cssSelector && document.querySelector(cssSelector)) {
      var rect = document.querySelector(cssSelector).getBoundingClientRect();
      var offsetTop = window.pageYOffset + rect.top - topMargin;

      gsap.to(window, {
        scrollTo: offsetTop,
        duration: 1,
      })
    } 
  }

  const scrollToTop = (topMargin = 0) => {
    gsap.to(window, {
      scrollTo: topMargin,
      duration: 1,
    })
  }

  return {
    scrollToCssSelector,
    scrollToTop,
  }
}

export default useScrollController
