import React from 'react'
import {
  StyledReviews,
  StyledReview,
  StyledReviewContent,
  StyledReviewStars,
  StyledReviewStar,
  StyledReviewAuthor,
  StyledReviewText,
} from './style.jsx'
import { StyledGridRow } from '../../commons/Grid'
import Scroller from '../Scroller/Scroller'

const getStars = (stars) => {
  const array = []
  for (let i = 0; i < 5; i++) {
    array.push(i)
  }
  return array.map((x, index) => {
    return (
      <StyledReviewStar key={index} isActive={index <= Number(stars) - 1}>
        ★
      </StyledReviewStar>
    )
  })
}

export default ({ highlighted, items }) => {
  return (
    <StyledGridRow isFullMobile>
      <Scroller>
        <StyledReviews>
          {items.map((item, index) => (
            <StyledReview key={index} highlighted={highlighted}>
              <StyledReviewContent>
                {item.stars && (
                  <StyledReviewStars>{getStars(item.stars)}</StyledReviewStars>
                )}
                <StyledReviewAuthor>{item.author}</StyledReviewAuthor>
                <StyledReviewText>{item.review}</StyledReviewText>
              </StyledReviewContent>
            </StyledReview>
          ))}
        </StyledReviews>
      </Scroller>
    </StyledGridRow>
  )
}
