import React, {} from 'react'
import {
  StyledAdoptPayment,
  StyledPaymentParagraph,
  StyledArrowParagraph
} from './style'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'

export default ({
  items
}) => {

    return (
      <StyledAdoptPayment>
        { items.map((item, index) => (
          <div key={`payment-paragraph-${index}`}>
            <StyledPaymentParagraph
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
            <StyledArrowParagraph>
              { item.showArrow && (
                <Icon icon={icons.arrowLeft} color={Colors.yellow} />
              )}
            </StyledArrowParagraph>
          </div>
          ))
        }
      </StyledAdoptPayment>
    )
}
