import React from 'react'
import {
  StyledCountdownWrapper,
  StyledCountDigits,
  StyledCountDigitsWrapper,
  StyledCountText,
} from './style'
import Countdown from 'react-countdown';

const renderer = ({ total, days, hours, minutes, seconds }) => {
  if (total) {
    //console.log(Date.now())
    // Render a countdown
    return (
      <>
        <StyledCountDigitsWrapper>
          <StyledCountDigits>
            {days} 
          </StyledCountDigits>
          <StyledCountText>
            giorni
          </StyledCountText>
        </StyledCountDigitsWrapper>
        <StyledCountDigitsWrapper>
          <StyledCountDigits>
            {hours}
          </StyledCountDigits>
          <StyledCountText>
            ore
          </StyledCountText>
        </StyledCountDigitsWrapper>
        <StyledCountDigitsWrapper>
          <StyledCountDigits>
            {minutes}
          </StyledCountDigits>
          <StyledCountText>
            minuti
          </StyledCountText>
        </StyledCountDigitsWrapper>
        <StyledCountDigitsWrapper>
          <StyledCountDigits>
            {seconds}
          </StyledCountDigits>
          <StyledCountText>
            secondi
          </StyledCountText>
        </StyledCountDigitsWrapper>
    </>
    );
  } else {
    // Render a finished state
    return <></>
  }
};

export default ({date}) => {
  return (
    <StyledCountdownWrapper>
      <Countdown 
      date={1612018800444}
      renderer={renderer}
      >
      </Countdown>
    </StyledCountdownWrapper>
  )
}
