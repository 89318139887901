import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledScroller } from '../Scroller/style'

export const StyledTechNav = styled.div`
  position: fixed;
  width: 100%;
  height: auto;
  top: ${Sizes.headerMobile};
  padding-top: ${Rem(16)};
  background-color: ${Colors.dark};
  z-index: 9;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s ease-in-out 0s;

  @media (${BP.ipad}) {
    padding-top: ${Rem(22)};
    top: ${Sizes.headerDesktop};
  }

  ${(props) =>
    props.isVisible &&
    css`
      transform: translate3d(0, 0, 0);
    `}
`

export const StyledTechNavItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  @media (${BP.ipad}) {
    justify-content: space-between;
    max-width: ${Rem(860)};
    margin: 0 auto;
  }
`

export const StyledTechNavItem = styled.div`
  text-align: center;
  margin-left: ${Rem(40)};

  &:last-child {
    &:after {
      position: relative;
      display: block;
      content: '';
      width: ${Rem(40)};
      height: 1px;
      left: 100%;
      margin-bottom: -1px;

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }

  @media (${BP.ipad}) {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const StyledTechNavItemIcon = styled.img`
  margin-bottom: ${Rem(14)};
`

export const StyledTechNavItemLabel = styled.div`
  position: relative;
  font-size: ${Rem(13)};
  line-height: ${Rem(16)};

  ${(props) =>
    props.highlight &&
    css`
      min-width: ${Rem(68)};

      &:after {
        position: absolute;
        display: inline;
        content: '★';
        top: ${Rem(-4)};
        font-size: ${Rem(8)};
        color: ${Colors.yellow};
      }
    `}
`

export const StyledTechNavItemExtra = styled.div`
  font-size: ${Rem(8)};
  letter-spacing: ${Rem(2)};
  color: ${Colors.yellow};
  padding-top: ${Rem(6)};
  text-transform: uppercase;
`
