import React, { useEffect, useRef } from 'react'
import { StyledScroller } from './style'
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'
import ResizeObserver from 'resize-observer-polyfill'

export default ({ mobile, children }) => {
  const scrollerEl = useRef(null)
  useEffect(() => {
    window.ResizeObserver = ResizeObserver
  }, [])

  useEffect(() => {
      new SimpleBar(scrollerEl.current)
  }, [scrollerEl])
  return <StyledScroller ref={scrollerEl}>{children}</StyledScroller>
}
