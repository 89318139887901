import React from 'react'
import {
  StyledPressScroller,
  StyledPressScrollerItems,
  StyledPressScrollerItem,
  StyledPressScrollerItemContent,
} from './style.jsx'
import { Link } from 'gatsby'
import { StyledGridRow } from '../../commons/Grid'
import Scroller from '../Scroller/Scroller'

export default ({ items }) => {
  return (
    <StyledGridRow isFullMobile>
      <Scroller>
        <StyledPressScroller>
          <StyledPressScrollerItems>
            {items.map((item, index) => (
              <StyledPressScrollerItem key={index}>
                <Link to={item.url}>
                  <StyledPressScrollerItemContent bg={item.image} />
                </Link>
              </StyledPressScrollerItem>
            ))}
          </StyledPressScrollerItems>
        </StyledPressScroller>
      </Scroller>
    </StyledGridRow>
  )
}
