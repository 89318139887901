import React, { useContext, useEffect, useState } from 'react'
import {
  StyledChooseUserModal,
  StyledChooseUserModalRow,
  StyledChooseUserModalIcon,
} from './style'

import { DataContext } from '../../contexts/DataContext'

import Icon, { icons } from '../Icon/Icon'

import { LayoutContext } from '../../contexts/LayoutContext'
import { Colors } from '../../commons/Theme'
import Button from '../Button/Button'

export default () => {
  const { labels } = useContext(DataContext)
  const [formData, setFormData] = useState({})
  const [errorMsg, setErrorMsg] = useState(null)
  const { setModalContent } = useContext(LayoutContext)

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  useEffect(() => {
    setCookie('user', 'unknown', 3)
  }, [])


  return (
    <StyledChooseUserModal>
      <StyledChooseUserModalRow>
        <StyledChooseUserModalIcon src='/2020/svg/icons/bee.svg'/>
          <Button noPadding theme="transparent">
                NO

          </Button>
          <Button  noPadding theme="transparent">
                SI
          </Button>
      </StyledChooseUserModalRow>
    </StyledChooseUserModal>
  )
}
