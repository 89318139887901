import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'

export const StyledBusinessSlider = styled.div``

export const StyledBusinessSwiper = styled.div``

export const StyledBusinessSwiperSlider = styled.div``

export const StyledBusinessSwiperControls = styled.div`
  ${Ratio(375, 250)};
  position: absolute;
  width: 100%;
  display: flex;
  align-items: flex-end;
  top: 0;
  left: 0;
  background-color: ${Colors.transparent};
  z-index: 3;
  pointer-events: none;
  cursor: pointer;

  @media (${BP.ipad}) {
    width: auto;
    height: auto;
    top: 0;
    left: 50%;
    align-items: flex-start;
  }
`

export const StyledBusinessSwiperControlPrev = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${Rem(58)};
  min-height: ${Rem(58)};
  background-color: ${Colors.yellow};
  pointer-events: all;

  @media (${BP.ipad}) {
    min-width: ${Rem(83)};
    min-height: ${Rem(83)};
  }

  ${StyledIcon} {
    width: ${Rem(10)};

    @media (${BP.ipad}) {
      width: ${Rem(14)};
    }
  }

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`

export const StyledBusinessSwiperControlNext = styled(
  StyledBusinessSwiperControlPrev
)`
  margin-left: ${Rem(2)};
`

export const StyledBusinessSlide = styled.div`
  overflow: hidden;
`

export const StyledBusinessSlideCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${Colors.white};
  background-color: ${Colors.black};
  ${(props) =>
    props.theme === 'white' &&
    css`
      color: ${Colors.black};
      background-color: ${Colors.grayLight};
    `};

  @media (${BP.ipad}) {
    flex-direction: row;
    align-items: stretch;
  }
`

export const StyledBusinessSlideCardBlock = styled.div`
  width: 100%;

  @media (${BP.ipad}) {
    width: 50%;
  }
`

export const StyledBusinessSlideCardMedia = styled.div`
  ${Ratio(375, 250)};
  width: 100%;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  
  @media (${BP.ipad}) {
    ${Ratio(1, 1)};
    height: 100%;
  }
`

export const StyledBusinessSlideCardContent = styled.div`
  height: 100%;
  padding: ${Rem(30)} ${Rem(20)} ${Rem(110)};

  @media (${BP.ipad}) {
    padding: ${Rem(170)} ${Rem(60)} ${Rem(110)} ${Rem(112)};
    max-width: ${Rem(640)};
  }
`

export const StyledBusinessSlideCardLogo = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: ${Rem(110)};
`

export const StyledBusinessSlideCardSmallText = styled.div`
  font-size: ${Rem(20)};
  margin-bottom: ${Rem(44)};

  @media (${BP.ipad}) {
    font-size: ${Rem(25)};
    margin-bottom: ${Rem(52)};
  }
`

export const StyledBusinessSlideCardCounter = styled.span`
  display: inline-block;
  font-size: ${Rem(20)};

  &:after {
    content: '';
    display: inline-block;
    width: ${Rem(20)};
    height: ${Rem(2)};
    margin: 0 ${Rem(12)};
    background-color: ${Colors.yellow};
    vertical-align: middle;
  }
`

export const StyledBusinessSlideCardText = styled.div``

export const StyledBusinessSlideCardTitle = styled.h2`
  font-size: ${Rem(25)};
  line-height: ${Rem(35)};
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    line-height: ${Rem(55)};
    margin-bottom: ${Rem(28)};
  }
`

export const StyledBusinessSlideCardParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(30)};
  }
`

export const StyledBusinessSlideCardCta = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;

  a {
    text-decoration: none;
  }
`
