import React from 'react'
import {
  StyledGiveAdoption,
  StyledGiveAdoptionContent,
  StyledGiveAdoptionDescription,
  StyledGiveAdoptionImage,
  StyledGiveAdoptionSubtitle,
  StyledGiveAdoptionText,
} from './style'
import { StyledGridRow } from '../../commons/Grid'
import { StyledBigText } from '../../commons/Typography'
export default ({ title, subtitle, description, icon }) => {
  return (
    <StyledGiveAdoption>
      <StyledGridRow>
        <StyledBigText dangerouslySetInnerHTML={{ __html: title }} />
        <StyledGiveAdoptionContent>
          <StyledGiveAdoptionImage src={icon} />
          <StyledGiveAdoptionText>
            <StyledGiveAdoptionSubtitle
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
            <StyledGiveAdoptionDescription
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </StyledGiveAdoptionText>
        </StyledGiveAdoptionContent>
      </StyledGridRow>
    </StyledGiveAdoption>
  )
}
