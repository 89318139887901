import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledFaq = styled.div`
  padding-top: ${Rem(12)};
  padding-bottom: ${Rem(82)};
  padding-right: 0;
  width:100%;
  align-items: center;

  @media (${BP.desktop}) {
    padding-right: ${({paddingRight}) => Rem(paddingRight)};
  }
 
  ${(props) => props.isWhite && css`
    background-color: ${Colors.white};
    color: ${Colors.black};
  `}

  .faq-row-wrapper {
    .faq-title {
      margin-left:${Rem(20)};
      @media (${BP.desktop}) {
        margin-left: ${Rem(32)};
      }
      padding-top: ${Rem(16)}; 
      font-size: ${Rem(24)}; 
    }
 
    .faq-body {
        background-color: ${Colors.yellow};
        padding-left: ${Rem(20)};
        @media (${BP.desktop}) {
          padding-left: ${Rem(32)};
        }
        .faq-row {
          @media (${BP.desktop}) {
          padding-right: ${Rem(32)};
        }
          border-color:  ${Colors.white};
            .row-title {
            }
            .row-content {
                .row-content-text {
                }
            }
        }
    }
}
`
