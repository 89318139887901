import styled, { css } from 'styled-components'
import { Rem, BP, Ratio, Colors } from '../../commons/Theme'
import { StyledButton } from '../Button/style'


export const StyledBannerImageWrapper = styled.div`
  width: 90%;
  margin-left:5%;
  position: relative;
  background-color: #000000;
  z-index:1;
  border-radius: ${Rem(15)};
`

export const StyledBannerImage = styled.div`
  background-image: url('${(props) => props.bg}');
  ${Ratio(375, 300)};
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index:1;
  border-radius: ${Rem(15)};
  max-height: ${Rem(300)};
  @media(${BP.ipad}) {
    ${Ratio(1200, 300)};
    opacity: 1;
  }
`

export const StyledBannerTitle = styled.div`
  position: absolute;
  z-index:2;
  top: ${Rem(25)};
  left: ${Rem(25)};
  color: ${Colors.white};
  font-size: ${Rem(35)};
  @media(${BP.ipad}) {
    font-size: ${Rem(50)};
  }
`

export const StyledBannerSubtitle = styled.div`
  position: absolute;
  z-index:2;
  top: ${Rem(85)};
  left: ${Rem(25)};
  color: ${Colors.grayLight};
  font-size: ${Rem(25)};
  @media(${BP.ipad}) {
    color: ${Colors.grayDark};
    font-size: ${Rem(40)};
  }
`

export const StyledBannerButton = styled.div`
  position: absolute;
  bottom: ${Rem(25)};
  right: ${Rem(25)};
  @media(${BP.ipad}) {
    left: ${Rem(25)};
  }

  ${StyledButton} {
    height: ${Rem(40)};
    border-radius: ${Rem(5)};
    box-shadow: ${Rem(3)} ${Rem(3)} ${Rem(4)} ${Colors.black};
    margin-left: ${Rem(10)};
    margin-bottom: ${Rem(10)};
  }
`