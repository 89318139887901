import React, {} from 'react'
import {
  StyledButtonBlock,
  StyledChildren
} from './style'
import Button from '../Button/Button'

export default ({
  label,
  name,
  productId,
  url,
  image,
  finalPrice,
  weight,
  description,
  custom1Name,
  custom1Options,
}) => {
    return (
      <StyledButtonBlock >

        <Button as="div"
          theme="yellow"
          noLine
          className="snipcart-add-item"
          dataitemid={productId}
          dataitemurl={url}
          dataitemimage={image}
          dataitemprice={finalPrice}
          dataitemname={name}
          dataitemweight={weight}
          dataitemdescription={description}
          dataitemcustom1name={custom1Name}
          dataitemcustom1options={custom1Options}
        >

          <StyledChildren dangerouslySetInnerHTML={{ __html: label }}></StyledChildren>
        </Button>
        
      </StyledButtonBlock>
    )
}
