import React, { useContext } from 'react'
import * as Components from '../components'
import { DataContext } from '../contexts/DataContext'
import useTheme from './useTheme'
import SEO from '../components/SEO/SEO'
import { Helmet } from 'react-helmet'

const usePageBuilder = () => {
  const { content, labels, products, plans, seo, languages } = useContext(DataContext)
  const themeColor = useTheme()

  const currentLanguage = languages.filter( (language) => language.isCurrent)
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : 'en'

  return (
    <>
      <Helmet htmlAttributes={{lang: currentLanguageCode}} />
      <SEO meta={seo.meta} />
      {content.map(({ component, props }, index) => {
        const Component = Components[component]
        return Component ? (
          <Component
            key={index}
            {...props}
            labels={labels}
            products={products}
            plans={plans}
            themeColor={themeColor}
          />
        ) : (
          <div key={index}>
            <hr />
            componente {component} non trovato - controllare l'export nel file
            /src/components/index.js
            <hr />
          </div>
        )
      })}
    </>
  )
}

export default usePageBuilder
