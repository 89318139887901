import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledMap = styled.div``

export const StyledMapMap = styled.div`
  position: relative;
  ${Ratio(375, 230)};
  width: 100%;

  @media (${BP.ipad}) {
    ${Ratio(1200, 408)};
  }
`
