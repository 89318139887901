import React from 'react'
import {
  StyledModalGallery,
  StyledModalGalleryThumbs,
  StyledModalGalleryThumb,
} from './style.jsx'
import { StyledGridRow } from '../../commons/Grid'
import Scroller from '../Scroller/Scroller'
export default ({ items, marginBottom }) => {
  return (
    <StyledModalGallery marginBottom={marginBottom}>
      <StyledGridRow noMargin>
        <Scroller>
          <StyledModalGalleryThumbs>
            {items.map((item, index) => (
              <StyledModalGalleryThumb key={index} bg={item} />
            ))}
          </StyledModalGalleryThumbs>
        </Scroller>
      </StyledGridRow>
    </StyledModalGallery>
  )
}
