import React from 'react'
import {
  StyledFaq,
} from './style'
import Faq from "react-faq-component";

const config = {
   animate: true,
  // arrowIcon: "V",
   tabFocus: true
};

export default ({
  data,
  theme,
  paddingRight = 0,
}) => {
  const styles = {
    bgColor: theme == 'black' ? 'black' : 'white',
    titleTextColor: 'orange',
    rowTitleColor: 'white',
    rowContentColor: 'white',
    arrowColor: 'white',
    rowContentPaddingLeft: '20px',
    rowContentPaddingRight: '20px',
    rowContentPaddingBottom: '10px',
  };

  return (
    <StyledFaq isWhite={theme=='white'} paddingRight={paddingRight}>
       <Faq data={data} styles={styles} config={config} />
    </StyledFaq>
  )
}
