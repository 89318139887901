import styled, { css } from 'styled-components'
import { BP, Colors, Rem } from '../../commons/Theme'

export const StyledImageDescription = styled.div`
  padding-top: ${({paddingTop}) => Rem(paddingTop)};
  padding-bottom: ${({paddingBottom}) =>Rem(paddingBottom)};

  ${(props) =>
    props.isWhite &&
    css`
      background-color: ${Colors.white};
      color: ${Colors.black};
    `}
`
export const StyledImageDescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
export const StyledImageDescriptionImage = styled.img`
  max-width: 90%;
  align-self: center;

  @media (${BP.tablet}) {
    max-width: 30%;
  }
`

export const StyledImageDescriptionLabels = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;

  width: 35%;
  text-align: ${({textAlign}) => textAlign ?? 'left'};
  font-size: ${Rem(20)};

  @media (${BP.tablet}) {
    display: flex;
  }
`

export const StyledImageDescriptionLabel = styled.div`
  padding: 0 ${Rem(26)} ${Rem(4)};
`

export const StyledImageDescriptionLine = styled.img`
  padding-bottom: ${Rem(10)}; 
`

export const StyledImageDescriptionLabelsBottom = styled.div`
  display: flex;
  flex-direction: row;

  font-size: ${Rem(20)};

  & div:first-child {
    margin-left: ${Rem(10)};
  }
  & div:last-child {
    margin-right: ${Rem(10)};
  }

  @media (${BP.tablet}) {
    display: none;
  }
`

export const StyledImageDescriptionLabelBottom = styled.div`
  padding: 0 ${Rem(8)} ${Rem(8)} ${Rem(26)};
  width: ${Rem(250)};

  background-image: url('/2020/images/hive-tech-crowd/line-bottom.png');
  background-position: left bottom;
  background-repeat: no-repeat;
`
