import React, { useContext, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import {
  StyledHive,
  StyledHiveActions,
  StyledHiveContent,
  StyledHiveDescription,
  StyledHiveGallery,
  StyledHiveInformation,
  StyledHiveMobileImage,
  StyledHiveName,
  StyledHiveService,
  StyledHiveServices,
  StyledHivesList,
  StyledHivesListScroller,
  StyledHivesMap,
  StyledHivesWrapper,
  StyledBeekeeper,
  StyledBeekeeperName,
  StyledBeekeeperHives,
  StyledEmptyHives,
} from './style'
import SmallGallery from '../SmallGallery/SmallGallery'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { DataContext } from '../../contexts/DataContext'
import { StyledGridRow } from '../../commons/Grid'
import OpenLayers from '../OpenLayers/OpenLayers'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Loader from '../Loader/Loader'
gsap.registerPlugin(ScrollToPlugin)

const cropText = (text = '') => {
  const limit = 146
  if (text.length < limit) {
    return text
  }

  let counter = 0
  return `${text
    .split(' ')
    .reduce((res, word) => {
      counter += word.length
      if (counter < limit) {
        res.push(word)
      }
      return res
    }, [])
    .join(' ')}${counter <= limit ? '' : '...'}`
}

export default ({ isLoading, items, urlParams }) => {
  const { labels, i18nLink } = useContext(DataContext)
  const [activeMapItem, setActiveMapItem] = useState(null)
  const [map, setMap] = useState(null)
  const scroller = useRef(null)
  return (
    <StyledHivesWrapper>
      <Loader isVisible={isLoading} />
      <StyledEmptyHives isVisible={!isLoading && items.length == 0} />
      <StyledGridRow>
        <StyledHivesList>
          <StyledHivesListScroller ref={scroller}>
            {items.map((apicoltore, index) =>
              apicoltore.items.length > 0 ? (
                <StyledBeekeeper key={index}>
                  <StyledBeekeeperName>
                    {apicoltore.fullname}
                  </StyledBeekeeperName>
                  <StyledBeekeeperHives>
                    {apicoltore.items.map((item, index) => (
                      <StyledHive
                        key={index}
                        data-id={item.id}
                        onClick={() => {
                          if (activeMapItem === item.id) {
                            navigate(
                              i18nLink(
                                `/apicoltori/${apicoltore.slug}?${urlParams}&hive_id=${item.id}`
                              )
                            )
                          } else {
                            setActiveMapItem(item.id)
                            map.panTo(item.id, [item.longitude, item.latitude])
                          }
                        }}
                      >
                        <StyledHiveMobileImage bg={item.gallery.length > 0 ? item.gallery[0] : item.main_image} />
                        <StyledHiveGallery>
                          <SmallGallery items={item.gallery.length > 0 ? item.gallery : [item.main_image]} />
                          <StyledHiveServices>
                            {item.rare && (
                              <StyledHiveService>
                                {labels.RARE_HONEY}
                              </StyledHiveService>
                            )}
                            {item.special && (
                              <StyledHiveService>
                                {labels.SPECIAL_HONEY}
                              </StyledHiveService>
                            )}
                          </StyledHiveServices>
                        </StyledHiveGallery>
                        <StyledHiveContent
                          onClick={() => {
                            //navigate(`/apicoltori/${item.slug}?${urlParams}`)
                          }}
                        >
                          <StyledHiveName
                            dangerouslySetInnerHTML={{ __html: item.name }}
                          />
                          <StyledHiveDescription
                            dangerouslySetInnerHTML={{
                              __html: cropText(item.description || ''),
                            }}
                          />
                          <StyledHiveInformation>
                            {labels.QUEEN_NAME}: {item.queen_name}
                            <br />
                            {labels.RACE}: {item.race}
                            <br />
                            {labels.PRODUCED_HONEYS}: {item.honeys.length}
                          </StyledHiveInformation>
                        </StyledHiveContent>
                        <StyledHiveActions>
                          <Button
                            as="div"
                            to={i18nLink(
                              `/apicoltori/${apicoltore.slug}?${urlParams}&hive_id=${item.id}`
                            )}
                          >
                            {labels.GO_TO_HIVE}
                            <Icon
                              icon={icons.arrowRight}
                              color={Colors.black}
                            />
                          </Button>
                          <Button
                            as="div"
                            handleOnClick={() => {
                              map.panTo(item.id, [
                                item.longitude,
                                item.latitude,
                              ])
                            }}
                          >
                            {labels.VIEW_ON_MAP}
                            <Icon
                              icon={icons.arrowRight}
                              color={Colors.black}
                            />
                          </Button>
                        </StyledHiveActions>
                      </StyledHive>
                    ))}
                  </StyledBeekeeperHives>
                </StyledBeekeeper>
              ) : null
            )}
          </StyledHivesListScroller>
        </StyledHivesList>
      </StyledGridRow>
      <StyledHivesMap>
        <OpenLayers
          markers={Object.keys(items).reduce((res, _apicoltore, index) => {
            const apicoltore = items[_apicoltore]
            let urlPin = '/2020/images/biodiversity/pin.png'
            apicoltore.items.map((hive) => {
              if (hive.special) {
                urlPin = '/2020/images/biodiversity/beechristmas.png'
              }
              const link = i18nLink(
                `/apicoltori/${apicoltore.slug}?${urlParams}&hive_id=${hive.id}`
              )
              res.push({
                pin: urlPin,
                center: {
                  lat: hive.latitude,
                  lng: hive.longitude,
                },
                popupID: hive.id,
                infowindow: `<div style="display: table; text-align: center; align-items: center; justify-content: center;"><strong style="display: table-row; vertical-align: middle">${apicoltore.fullname}</strong><span>${hive.name}</span><button onclick="window.location.href = '${link}'" style="text-align: center; margin: 0 auto; display: block; vertical-align: middle; cursor: pointer; font-size: 14px; padding: 5px; margin-top: 5px; color: rgba(255,255,255,1); background-color: rgba(255,176,0,1); border: none">Scopri di più</button></div>`,
                onClick: () => {
                  if (activeMapItem === hive.id) {
                    navigate(
                      i18nLink(
                        `/apicoltori/${apicoltore.slug}?${urlParams}&hive_id=${hive.id}`
                      )
                    )
                  }
                  setActiveMapItem(apicoltore.id)
                  const target = scroller.current.querySelector(
                    `[data-id="${hive.id.toString()}"]`
                  )
                  scroller.current.scrollTo({
                    top: target.offsetTop,
                    left: target.offsetLeft,
                    behavior: 'smooth',
                  })
                },
              })
            })
            return res
          }, [])}
          onMapReady={setMap}
        />
      </StyledHivesMap>
    </StyledHivesWrapper>
  )
}
