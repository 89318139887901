import React, { useEffect, useRef, useState } from 'react'
import {
  StyledFastChoose,
  StyledFastChooseCTA,
  StyledFastChooseCTAButton,
  StyledFastChooseItem,
  StyledFastChooseItemBlock,
  StyledFastChooseItemCTA,
  StyledFastChooseItemDetail,
  StyledFastChooseItemDetailIcon,
  StyledFastChooseItemDetails,
  StyledFastChooseItemDetailText,
  StyledFastChooseItemExtra,
  StyledFastChooseItemExtraTrigger,
  StyledFastChooseItemImage,
  StyledFastChooseItemInfo,
  StyledFastChooseItemName,
  StyledFastChooseItemPrice,
  StyledFastChooseItems,
  StyledFastChooseSubtitle,
  StyledFastChooseTitle,
} from './style'
import { StyledGridRow } from '../../commons/Grid'
import Icon, { icons } from '../Icon/Icon'
import useApi from '../../hooks/useApi'
import { Colors } from '../../commons/Theme'
import {
  StyledBeekeeperShopChooserCTA,
  StyledBeekeeperShopChooserCTAButton,
  StyledBeekeeperShopSummary,
  StyledBeekeeperShopSummaryDeliveryDate,
  StyledBeekeeperShopSummaryDetail,
  StyledBeekeeperShopSummaryDetailLabel,
  StyledBeekeeperShopSummaryDetails,
  StyledBeekeeperShopSummaryDetailValue,
  StyledBeekeeperShopSummaryEditorial,
  StyledBeekeeperShopSummaryEditorialContent,
  StyledBeekeeperShopSummaryEditorialContentList,
  StyledBeekeeperShopSummaryEditorialContentSubtitle,
  StyledBeekeeperShopSummaryEditorialContentTitle,
  StyledBeekeeperShopSummaryEditorialForm,
  StyledBeekeeperShopSummaryEditorialFormBlock,
  StyledBeekeeperShopSummaryEditorialFormInput,
  StyledBeekeeperShopSummaryEditorialFormTitle,
  StyledBeekeeperShopSummaryEditorialImage,
  StyledBeekeeperShopSummaryEditorialPayments,
  StyledBeekeeperShopSummaryEditorialTitle,
  StyledBeekeeperShopSummaryForm,
  StyledBeekeeperShopSummaryScroller,
} from '../BeekeeperShop/style'
import { countries } from '../../commons/Countries'
import useScrollController from '../../hooks/useScrollController'

export default ({ title, subtitle }) => {
  const [extraVisible, setExtraVisible] = useState(null)
  const { getBeekeepers } = useApi()
  const [beekeepers, setBeekeepers] = useState([])
  const [currentStep, setCurrentStep] = useState(1)
  const [chooses, setChooses] = useState([])
  const summaryForm = useRef(null)
  const { scrollToTop } = useScrollController()
  const sizes = [
    {
      label: '250 g',
      value: 0.25,
    },
    {
      label: '500 g',
      value: 0.5,
    },
    {
      label: '1 Kg',
      value: 1,
    },
    {
      label: '2 Kg',
      value: 2,
    },
    {
      label: '5 Kg',
      value: 5,
    },
  ]
  const selections = [
    {
      beekeeper: 7,
      hive: 520,
      honey: 3,
      size: sizes[1],
      quantity: 4,
    },
    {
      beekeeper: 5397,
      hive: 3044,
      honey: 29,
      size: sizes[1],
      quantity: 4,
    },
    {
      beekeeper: 7,
      hive: 520,
      honey: 3,
      size: sizes[1],
      quantity: 4,
    },
    {
      beekeeper: 5397,
      hive: 3044,
      honey: 29,
      size: sizes[1],
      quantity: 4,
    },
    {
      beekeeper: 7,
      hive: 520,
      honey: 3,
      size: sizes[1],
      quantity: 4,
    },
    {
      beekeeper: 5397,
      hive: 3044,
      honey: 29,
      size: sizes[1],
      quantity: 4,
    },
    {
      beekeeper: 7,
      hive: 520,
      honey: 3,
      size: sizes[1],
      quantity: 4,
    },
    {
      beekeeper: 5397,
      hive: 3044,
      honey: 29,
      size: sizes[1],
      quantity: 4,
    },
  ]

  const priceToString = (price) => {
    return price.toFixed(2).toString().replace('.', ',')
  }

  const getHive = (selection) =>
    beekeepers
      .find((x) => x.id === selection.beekeeper)
      .hives.find((x) => x.id === selection.hive)

  const getHoney = (selection) =>
    beekeepers
      .find((x) => x.id === selection.beekeeper)
      .honeys.find((x) => x.id === selection.honey)

  useEffect(() => {
    getBeekeepers().then((res) => {
      setBeekeepers(res)
    })
  }, [])

  return (
    <StyledFastChoose>
      {currentStep === 1 && (
        <>
          <StyledGridRow noMargin>
            <StyledFastChooseTitle>{title}</StyledFastChooseTitle>
            <StyledFastChooseSubtitle>{subtitle}</StyledFastChooseSubtitle>
            {beekeepers.length > 0 && (
              <StyledFastChooseItems>
                {selections.map((selection, index) => (
                  <StyledFastChooseItem
                    key={index}
                    isActive={chooses.indexOf(index) >= 0}
                  >
                    <StyledFastChooseItemExtraTrigger
                      isClose={extraVisible === index}
                      onClick={() =>
                        setExtraVisible(extraVisible === index ? null : index)
                      }
                      dangerouslySetInnerHTML={{
                        __html: extraVisible === index ? '&times;' : '?',
                      }}
                    />
                    <StyledFastChooseItemExtra
                      isVisible={extraVisible === index}
                    >
                      descrizione
                    </StyledFastChooseItemExtra>
                    <StyledFastChooseItemBlock>
                      <StyledFastChooseItemImage
                        onClick={() =>
                          setExtraVisible(extraVisible === index ? null : index)
                        }
                        bg={getHive(selection).main_image}
                      />
                      <StyledFastChooseItemInfo>
                        <StyledFastChooseItemName
                          dangerouslySetInnerHTML={{
                            __html: getHive(selection).name,
                          }}
                        />
                        <StyledFastChooseItemDetails>
                          <StyledFastChooseItemDetail>
                            <StyledFastChooseItemDetailIcon>
                              <Icon icon={icons.shopBee} />
                            </StyledFastChooseItemDetailIcon>
                            <StyledFastChooseItemDetailText>
                              Razza:
                            </StyledFastChooseItemDetailText>
                          </StyledFastChooseItemDetail>
                          <StyledFastChooseItemDetail>
                            <StyledFastChooseItemDetailIcon>
                              <Icon icon={icons.shopCrown} />
                            </StyledFastChooseItemDetailIcon>
                            <StyledFastChooseItemDetailText>
                              Regina: {getHive(selection).queen_name}
                            </StyledFastChooseItemDetailText>
                          </StyledFastChooseItemDetail>
                        </StyledFastChooseItemDetails>
                      </StyledFastChooseItemInfo>
                    </StyledFastChooseItemBlock>
                    <StyledFastChooseItemBlock>
                      <StyledFastChooseItemImage
                        onClick={() =>
                          setExtraVisible(extraVisible === index ? null : index)
                        }
                        bg={getHoney(selection).main_image}
                      />
                      <StyledFastChooseItemInfo>
                        <StyledFastChooseItemName
                          dangerouslySetInnerHTML={{
                            __html: getHoney(selection).name,
                          }}
                        />
                        <StyledFastChooseItemDetails>
                          <StyledFastChooseItemDetail>
                            <StyledFastChooseItemDetailIcon>
                              <Icon icon={icons.shopHoney} />
                            </StyledFastChooseItemDetailIcon>
                            <StyledFastChooseItemDetailText>
                              Quantità: {selection.size.label}
                            </StyledFastChooseItemDetailText>
                          </StyledFastChooseItemDetail>
                          <StyledFastChooseItemDetail>
                            <StyledFastChooseItemDetailIcon>
                              <Icon icon={icons.shopHoney} />
                            </StyledFastChooseItemDetailIcon>
                            <StyledFastChooseItemDetailText>
                              Barattoli: {selection.quantity}
                            </StyledFastChooseItemDetailText>
                          </StyledFastChooseItemDetail>
                        </StyledFastChooseItemDetails>
                      </StyledFastChooseItemInfo>
                    </StyledFastChooseItemBlock>
                    <StyledFastChooseItemPrice>
                      €{' '}
                      {priceToString(
                        getHoney(selection).price *
                          selection.quantity *
                          selection.size.value
                      )}
                    </StyledFastChooseItemPrice>
                    <StyledFastChooseItemCTA
                      onClick={() => {
                        if (chooses.indexOf(index) >= 0) {
                          setChooses(chooses.filter((x) => x !== index))
                        } else {
                          setChooses([...chooses, index])
                        }
                      }}
                    >
                      {chooses.indexOf(index) >= 0
                        ? 'Selezionato'
                        : 'Seleziona'}
                    </StyledFastChooseItemCTA>
                  </StyledFastChooseItem>
                ))}
              </StyledFastChooseItems>
            )}
          </StyledGridRow>
          <StyledGridRow isFullMobile>
            <StyledFastChooseCTA>
              <StyledFastChooseCTAButton
                onClick={() => {
                  scrollToTop()
                  setCurrentStep(2)
                }}
                isDisabled={chooses.length === 0}
              >
                Prosegui <Icon icon={icons.arrowRight} color={Colors.black} />
              </StyledFastChooseCTAButton>
            </StyledFastChooseCTA>
          </StyledGridRow>
        </>
      )}
      {currentStep === 2 && (
        <StyledGridRow isFullMobile>
          <StyledBeekeeperShopSummary>
            <StyledBeekeeperShopSummaryForm>
              <input type="text" placeholder="Hai un codice promozionale?" />
              <button>Inserisci</button>
            </StyledBeekeeperShopSummaryForm>
            <StyledBeekeeperShopSummaryDetails>
              {chooses.map((choose, index) => (
                <React.Fragment key={index}>
                  <StyledBeekeeperShopSummaryDetail big>
                    <StyledBeekeeperShopSummaryDetailLabel>
                      {getHive(selections[choose]).name}
                    </StyledBeekeeperShopSummaryDetailLabel>
                  </StyledBeekeeperShopSummaryDetail>
                  <StyledBeekeeperShopSummaryDetail>
                    <StyledBeekeeperShopSummaryDetailLabel>
                      {getHoney(selections[choose]).name}
                    </StyledBeekeeperShopSummaryDetailLabel>
                    <StyledBeekeeperShopSummaryDetailValue>
                      €{' '}
                      {priceToString(
                        getHoney(selections[choose]).price *
                          selections[choose].quantity *
                          selections[choose].size.value
                      )}
                    </StyledBeekeeperShopSummaryDetailValue>
                  </StyledBeekeeperShopSummaryDetail>
                </React.Fragment>
              ))}
              <StyledBeekeeperShopSummaryDetail big>
                <StyledBeekeeperShopSummaryDetailLabel>
                  Spedizione
                </StyledBeekeeperShopSummaryDetailLabel>
                <StyledBeekeeperShopSummaryDetailValue>
                  € {priceToString(2.99)}
                </StyledBeekeeperShopSummaryDetailValue>
              </StyledBeekeeperShopSummaryDetail>
              <StyledBeekeeperShopSummaryDetail big>
                <StyledBeekeeperShopSummaryDetailLabel>
                  Totale <span>(iva inclusa)</span>
                </StyledBeekeeperShopSummaryDetailLabel>
                <StyledBeekeeperShopSummaryDetailValue>
                  €{' '}
                  {priceToString(
                    chooses.reduce((res, choose) => {
                      res +=
                        getHoney(selections[choose]).price *
                        selections[choose].quantity *
                        selections[choose].size.value
                      return res
                    }, 2.99)
                  )}
                </StyledBeekeeperShopSummaryDetailValue>
              </StyledBeekeeperShopSummaryDetail>
            </StyledBeekeeperShopSummaryDetails>
          </StyledBeekeeperShopSummary>
          <StyledBeekeeperShopSummaryDeliveryDate>
            Consegna prevista del miele per il 14 Marzo 2021
          </StyledBeekeeperShopSummaryDeliveryDate>
          <StyledBeekeeperShopSummaryScroller>
            <span
              onClick={() => {
                scrollToTop(summaryForm.current.offsetTop + 150)
              }}
            >
              Continua
            </span>
          </StyledBeekeeperShopSummaryScroller>
          <StyledBeekeeperShopSummaryEditorial>
            <StyledBeekeeperShopSummaryEditorialTitle>
              Cos'è incluso nel prezzo
            </StyledBeekeeperShopSummaryEditorialTitle>
            <StyledBeekeeperShopSummaryEditorialImage src="/2020/images/shop/summary.png" />
            <StyledBeekeeperShopSummaryEditorialContent>
              <StyledBeekeeperShopSummaryEditorialContentTitle>
                Un alveare per 1 anno, tutto incluso, nessuna spesa nascosta
              </StyledBeekeeperShopSummaryEditorialContentTitle>
              <StyledBeekeeperShopSummaryEditorialContentSubtitle>
                Biodiversità, miele, libri e sostegno concreto alle api
              </StyledBeekeeperShopSummaryEditorialContentSubtitle>
              <StyledBeekeeperShopSummaryEditorialContentList>
                <ul>
                  <li>
                    <span>
                      Il quantitativo di miele scelto nel piano, consegnato
                      direttamente a casa tua
                    </span>
                  </li>
                  <li>
                    <span>
                      Accesso alla piattaforma di monitoraggio dove potrai
                      vedere i dati in tempo reale del tuo alveare
                    </span>
                  </li>
                  <li>
                    <span>Il miele è garantito nella sua provenienza.</span>
                  </li>
                  <li>
                    <span>
                      I nostri apicoltori seguono un protocollo rigido imposto
                      da 3Bee
                    </span>
                  </li>
                  <li>
                    <span>
                      Nessun uso di antibiotici. Vietato l'uso di sostanza
                      chimiche illegali.
                    </span>
                  </li>
                  <li>
                    <span>Vietato l'uso di adultranti del miele.</span>
                  </li>
                  <li>
                    <span>
                      Vietato l'importazione o il blending con altri mieli.
                    </span>
                  </li>
                  <li>
                    <span>
                      Installazione del sistema di monitoraggio per migliorare
                      la qualità di vita delle api
                    </span>
                  </li>
                  <li>
                    <span>
                      Cura quotidiana dell'alveare da parte dell'apicoltore
                    </span>
                  </li>
                  <li>
                    <span>
                      Cura di evntuali danni all'alveare dovuti a parassiti
                    </span>
                  </li>
                  <li>
                    <span>
                      Aggiornamenti perdiodici dello stato di salute del tuo
                      alveare
                    </span>
                  </li>
                  <li>
                    <span>Controllo del miele a campione</span>
                  </li>
                  <li>
                    <span>Foto e Video caricati dall'apicoltore</span>
                  </li>
                  <li>
                    <span>Notizie settimanali sulle api e l'ambiente</span>
                  </li>
                  <li>
                    <span>Il costo è da intendersi annuale, non mensile</span>
                  </li>
                </ul>
              </StyledBeekeeperShopSummaryEditorialContentList>
            </StyledBeekeeperShopSummaryEditorialContent>
            <StyledBeekeeperShopSummaryEditorialPayments>
              <img src="/2020/images/shop/payments.jpg" />
              <img src="/2020/images/shop/sepa.png" />
            </StyledBeekeeperShopSummaryEditorialPayments>
            <StyledBeekeeperShopSummaryEditorialForm ref={summaryForm}>
              <StyledBeekeeperShopSummaryEditorialFormTitle>
                I tuoi dati personali
              </StyledBeekeeperShopSummaryEditorialFormTitle>
              <form>
                <StyledBeekeeperShopSummaryEditorialFormBlock>
                  <StyledBeekeeperShopSummaryEditorialFormInput
                    type="email"
                    placeholder="La tua Email *"
                  />
                  <span>
                    Verrà usata questa email per creare il tuo account
                    personale!
                  </span>
                </StyledBeekeeperShopSummaryEditorialFormBlock>
                <StyledBeekeeperShopSummaryEditorialFormBlock>
                  <StyledBeekeeperShopSummaryEditorialFormInput
                    type="text"
                    placeholder="Nome e cognome *"
                  />
                </StyledBeekeeperShopSummaryEditorialFormBlock>
                <StyledBeekeeperShopSummaryEditorialFormBlock>
                  <span>Indirizzo di spedizione</span>
                  <StyledBeekeeperShopSummaryEditorialFormInput
                    type="text"
                    placeholder="Via/Vicolo/Piazza"
                  />
                  <StyledBeekeeperShopSummaryEditorialFormInput
                    type="text"
                    placeholder="N. civico"
                    isSmall
                  />
                  <StyledBeekeeperShopSummaryEditorialFormInput
                    type="text"
                    placeholder="Città"
                    isMid
                  />
                  <StyledBeekeeperShopSummaryEditorialFormInput
                    type="text"
                    placeholder="Provincia"
                    isSmall
                  />
                  <StyledBeekeeperShopSummaryEditorialFormInput
                    type="text"
                    placeholder="CAP"
                    isSmall
                  />
                </StyledBeekeeperShopSummaryEditorialFormBlock>
                <StyledBeekeeperShopSummaryEditorialFormBlock>
                  <span>Paese di spedizione</span>
                  <StyledBeekeeperShopSummaryEditorialFormInput as="select">
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </StyledBeekeeperShopSummaryEditorialFormInput>
                </StyledBeekeeperShopSummaryEditorialFormBlock>
              </form>
            </StyledBeekeeperShopSummaryEditorialForm>
          </StyledBeekeeperShopSummaryEditorial>
          <StyledBeekeeperShopChooserCTA>
            <StyledBeekeeperShopChooserCTAButton
              onClick={() => {
                console.log('end')
              }}
            >
              Prosegui al pagamento{' '}
              <Icon icon={icons.arrowRight} color={Colors.black} />
            </StyledBeekeeperShopChooserCTAButton>
          </StyledBeekeeperShopChooserCTA>
        </StyledGridRow>
      )}
    </StyledFastChoose>
  )
}
