import React, { useContext, useEffect, useState, useRef } from 'react'
import {
  StyledHives,
  StyledTextHivesTitle,
  StyledContainerHives,
} from './style'
import PlantsNav from '../PlantsNav/PlantsNav'
import Hero from '../Hero/Hero'
import useApi from '../../hooks/useApi'
import HivesList from './HivesList'
import { LayoutContext } from '../../contexts/LayoutContext'
import ApiErrorForm from '../ApiErrorForm/ApiErrorForm'
import Loader from '../Loader/Loader'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import AddNewsletterModal from '../AddNewsletterModal/AddNewsletterModal'
gsap.registerPlugin(ScrollToPlugin)

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export default ({ themeColor, intro, labels }) => {
  const { getPlants, getHives } = useApi()
  const [currentPlant, setCurrentPlant] = useState(null)
  const [plants, setPlants] = useState([])
  const [hives, setHives] = useState([])
  const [isLoadingPlants, setIsLoadingPlants] = useState(false)
  const [isLoadingHives, setIsLoadingHives] = useState(false)
  const { setModalContent } = useContext(LayoutContext)
  const [urlParams, setUrlParams] = useState(``)
  const [coupon, setCoupon] = useState(``)
  const [rare, setRare] = useState(false)
  const [special, setSpecial] = useState(false)
  const [honey_id, setHoney_id] = useState(1)
  const [honey_id_first, setHoney_id_first] = useState(1)
  const [themeColorOverride, setThemeColorOverride] = useState({})
  const planRef = useRef(null)
 
  const scrollTop = () => {
    gsap.to(window, {
      scrollTo: {
        y: 0,
      },
      duration: 1.0,
    })
  }

  const executeScroll = () => scrollTop()

  useEffect(() => {
    executeScroll()
    const queryString = window.location.search
    const _urlParams = new URLSearchParams(queryString)
    const _coupon = _urlParams.get('coupon')
    const _rare = _urlParams.get('rare')
    const _special = _urlParams.get('special')
    const _honey_id_first = _urlParams.get('honey_id')

    if (_urlParams) {
      setUrlParams(_urlParams)
    }

    if (_coupon) {
      setCoupon(_coupon)
    }

    setIsLoadingPlants(true)
    getPlants(_coupon).then((res) => {
      if (res.error) {
        setModalContent(<ApiErrorForm message={res.message} />)
        setIsLoadingPlants(false)
      } else {
        const selectedPlantId = parseInt(_honey_id_first)
        const selectedPlant = res.find((p) => p.id === selectedPlantId)
        const unavailablePlants = res.filter((x) => x.av_quantity<0.5 && x.id != selectedPlantId)
        const availablePlants = res.filter((x) => x.av_quantity>=0.5 && x.id != selectedPlantId)
        res = shuffle(availablePlants).concat(unavailablePlants)
        if (selectedPlant) {
          res.unshift(selectedPlant)
          setCurrentPlant(selectedPlant)
        } else {
          setCurrentPlant(res[0])
        }
        setPlants(res)

        if (_rare && res.find((p) => p.rare === true)) {
          setRare(_rare === 'true')
        }
        if (_special && res.find((p) => p.special === true)) {
          setSpecial(_special === 'true')
        }
      
        setIsLoadingPlants(false)
      }
    })
  }, [])

  // Get parameter from urls.
  useEffect(() => {
    if (currentPlant) {
      setIsLoadingHives(true)
      setHoney_id(currentPlant.id)
      //setSpecial(currentPlant.special)
      //setRare(currentPlant.rare)
      let _urlParams = ''
      if (coupon) {
        _urlParams = 'coupon=' + coupon + '&'
      }
      if (honey_id) {
        _urlParams =
          _urlParams +
          'honey_id=' +
          currentPlant.id +
          '&special=' +
          currentPlant.special +
          '&rare=' +
          currentPlant.rare
      }
      setUrlParams(_urlParams)
      getHives({ honeyID: currentPlant.id, coupon }).then((res) => {
        if (res.error) {
          setModalContent(<ApiErrorForm message={res.message} />)
        } else {
          setHives(res)
          if (res.length == 0) {
            setModalContent(
              <AddNewsletterModal
                title={`${labels.BEEKEEPERS_NOT_FOUND} ${currentPlant?.name}`}
                paragraph={labels.BEEKEEPERS_NOT_FOUND_PARAGRAPH}
                listId={34}
              />
            )
          }
        }
        setIsLoadingHives(false)
      })
    }
  }, [currentPlant])

  return (
    <StyledHives ref={planRef}>
      {plants && plants.length > 0 && (
        <PlantsNav
          rareFilterOn={rare}
          specialFilter={special}
          honey_id_first={honey_id_first}
          plants={plants}
          handleClick={(id) => {
            setCurrentPlant(plants.find((x) => x.id === id))
          }}
        />
      )}
      <div id="honeyDescription"></div>

      <Hero
        title={currentPlant ? currentPlant.name : ''}
        subtitle={currentPlant ? currentPlant.description : ''}
        ctaText={labels.CTA_TEXT_BIODIVERSITY}
        themeColor={themeColorOverride}
        image={{
          mobile: currentPlant ? currentPlant.phone_image : '',
          desktop: currentPlant ? currentPlant.web_image : '',
        }}
        hasScroller={true}
      />
      {hives && hives.length > 0 && (
        <StyledTextHivesTitle id="hivesDescription">
          {labels.BEEKEEPERS_CHOOSE}{' '}
          <span className="color-yellow">
            {currentPlant ? currentPlant.name : ''}
          </span>
        </StyledTextHivesTitle>
      )}

      <StyledContainerHives>
        <HivesList
          isLoading={isLoadingHives}
          items={hives}
          urlParams={urlParams}
        />
      </StyledContainerHives>
      <Loader isVisible={isLoadingPlants} />
    </StyledHives>
  )
}
