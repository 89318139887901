import React, {useContext} from 'react'
import {
  StyledClientCardsScroller,
  StyledClientCardsScrollerItems,
  StyledClientCardsScrollerItem,
  StyledClientCardsScrollerItemLogo,
  StyledClientCardsScrollerItemText,
  StyledClientCardsScrollerItemCTA,
} from './style.jsx'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Link } from 'gatsby'
import { StyledGridRow } from '../../commons/Grid'
import Scroller from '../Scroller/Scroller'
import { DataContext } from '../../contexts/DataContext'

export default ({ items }) => {
  const { i18nLink } = useContext(DataContext)
  return (
    <StyledClientCardsScroller>
      <StyledGridRow isFullMobile>
        <Scroller>
          <StyledClientCardsScrollerItems>
            {items.map((item, index) => (
              <StyledClientCardsScrollerItem key={index}>
                <StyledClientCardsScrollerItemLogo src={item.logo} />
                <StyledClientCardsScrollerItemText>
                  {item.text}
                </StyledClientCardsScrollerItemText>
                <StyledClientCardsScrollerItemCTA>
                  <Button as="div" to={item.cta.url ? i18nLink(`${item.cta.url}`) : null}>
                    {item.cta.label} <Icon icon={icons.arrowRight} />
                  </Button>
                </StyledClientCardsScrollerItemCTA>
              </StyledClientCardsScrollerItem>
            ))}
          </StyledClientCardsScrollerItems>
        </Scroller>
      </StyledGridRow>
    </StyledClientCardsScroller>
  )
}
