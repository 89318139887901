import React, { useState, useContext } from 'react'
import {
  StyledAppPreview,
  StyledAppPreviewIntro,
  StyledAppPreviewIntroBigText,
  StyledAppPreviewIntroCTA,
  StyledAppPreviewIntroDescription,
  StyledAppPreviewIntroTitle,
  StyledAppPreviewView,
  StyledAppPreviewViewContent,
  StyledAppPreviewViewDescription,
  StyledAppPreviewViewImage,
  StyledAppPreviewViews,
  StyledAppPreviewViewTitle,
} from './style'
import Icon, { icons } from '../Icon/Icon'
import { StyledGridRow } from '../../commons/Grid'
import { Colors } from '../../commons/Theme'
import Button from '../Button/Button'
import { Link } from 'gatsby'
import Scroller from '../Scroller/Scroller'
import { DataContext } from '../../contexts/DataContext'

export default ({ hasGradient, theme, intro, views }) => {
  const isWhite = theme === 'whiteFull'
  const isFull = theme === 'whiteFull'
  const [currentView, setCurrentView] = useState(0)
  const { i18nLink } = useContext(DataContext)

  return (
    <StyledAppPreview hasGradient={hasGradient} isWhite={false}>
      <StyledGridRow isFullMobile noMargin={false}>
        <Scroller mobile>
          <StyledAppPreviewViews>
            {views.map((view, index) => (
              <StyledAppPreviewView
                key={index}
                isActive={currentView === index}
              >
                <StyledAppPreviewViewContent
                  onMouseOver={() => setCurrentView(index)}
                >
                  <StyledAppPreviewViewTitle>
                    <Icon icon={icons[view.icon]} color={Colors.yellow} />
                    {view.title}
                  </StyledAppPreviewViewTitle>
                  <StyledAppPreviewViewDescription
                    dangerouslySetInnerHTML={{ __html: view.description }}
                  />
                </StyledAppPreviewViewContent>
                <StyledAppPreviewViewImage src={view.image} />
              </StyledAppPreviewView>
            ))}
          </StyledAppPreviewViews>
        </Scroller>
      </StyledGridRow>
    </StyledAppPreview>
  )
}
