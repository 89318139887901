import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledScroller = styled.div`
  max-height: 100%;
  padding-bottom: ${Rem(24)};

  &.simplebar-scrollable-x {
    padding-bottom: ${Rem(24)};
  }

  .simplebar-track {
    background-color: ${Colors.transparent};
    bottom: ${Rem(3)};

    .simplebar-scrollbar {
      &:before {
        background-color: ${Colors.yellow};
        border-radius: ${Rem(3)};
        opacity: 1;
      }
    }

    &.simplebar-horizontal {
      height: ${Rem(10)};

      &:before {
        position: absolute;
        content: '';
        display: block;
        width: calc(100% - ${Rem(20)});
        height: ${Rem(2)};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: ${Rem(3)};
        background-color: ${Colors.gray};
      }

      .simplebar-scrollbar {
        &:before {
          height: ${Rem(5)};
        }
      }
    }

    &.simplebar-vertical {
      width: ${Rem(5)};
      transform: translateX(${Rem(40)});

      &:before {
        position: absolute;
        content: '';
        display: block;
        width: ${Rem(1)};
        height: calc(100% - ${Rem(20)});
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: ${Rem(3)};
        background-color: ${Colors.gray};
      }

      .simplebar-scrollbar {
        &:before {
          width: ${Rem(3)};
        }
      }
    }
  }
`
