import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledRemember = styled.div`
  text-align: center;
`

export const StyledRememberTitle = styled.div``

export const StyledRememberDescription = styled.div`
  font-size: ${Rem(24)};
  line-height: ${Rem(30)};
  font-weight: normal;
`
