import React, { useContext, useEffect, useState } from 'react'
import {
  StyledTechNav,
  StyledTechNavItems,
  StyledTechNavItem,
  StyledTechNavItemExtra,
  StyledTechNavItemIcon,
  StyledTechNavItemLabel,
} from './style'
import { Link } from 'gatsby'
import useFixedNav from '../../hooks/useFixedNav'
import Scroller from '../Scroller/Scroller'

export default ({ items, labels }) => {
  const { isVisible } = useFixedNav()

  return (
    <StyledTechNav isVisible={isVisible}>
      <Scroller>
        <StyledTechNavItems>
          {items.map(({ highlight, icon, label, url }, index) => (
            <StyledTechNavItem key={index}>
              <Link to={url}>
                <StyledTechNavItemIcon src={icon} />
                <StyledTechNavItemLabel highlight={highlight}>
                  {label}
                </StyledTechNavItemLabel>
                {highlight && (
                  <StyledTechNavItemExtra>
                    {labels.NOVELTY}
                  </StyledTechNavItemExtra>
                )}
              </Link>
            </StyledTechNavItem>
          ))}
        </StyledTechNavItems>
      </Scroller>
    </StyledTechNav>
  )
}
