import React from 'react'
import { Helmet } from 'react-helmet'
import favicon from '../../../static/2020/svg/favicon_48x48.svg'
export default ({ meta }) => {
  const url =
    typeof window !== 'undefined' ? window.location.href : 'https://3bee.it'

  return (
    <Helmet>
      <title>{meta.title}</title>
      <link rel="icon" href={favicon} />
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      <meta name="og:url" content={url} />
      <meta name="og:type" content="website" />
      <meta name="og:title" content={meta.title} />
      <meta name="og:site_name" content="3bee" />
      <meta name="og:image" content={meta.image} />
      <meta name="og:image:secure_url" content={meta.image} />
      <meta name="og:description" content={meta.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="3bee" />
      <meta name="twitter:creator" content="3bee" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />      
    </Helmet>
  )
}
