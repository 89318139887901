import React, { useContext, useState } from 'react'
import { StyledAddNewsletterModal } from './style'
import { DataContext } from '../../contexts/DataContext'
import { LayoutContext } from '../../contexts/LayoutContext'
import {
  StyledForm,
  StyledFormBlock,
  StyledFormInput,
  StyledFormLabel,
  StyledFormSubmit,
} from '../../commons/Form'
import { StyledParagraph, StyledTitle } from '../../commons/Typography'
export default ({ 
  title, 
  paragraph, 
  listId,
}) => {
  const [formData, setFormData] = useState({
    email: '',
  })

  const { labels } = useContext(DataContext)
  const { setModalContent } = useContext(LayoutContext)
  return (
    <StyledAddNewsletterModal>
      <StyledTitle
        dangerouslySetInnerHTML={{
          __html: title || labels.API_FORM_ERROR_TITLE,
        }}
      />
      <StyledParagraph
        dangerouslySetInnerHTML={{
          __html: paragraph || labels.API_FORM_ERROR_DESCRIPTION,
        }}
      />
      <StyledForm
        Validate
        onSubmit={(event) => {
          event.preventDefault()
          const form_data = new FormData(event.currentTarget)
          fetch('https://api.ipify.org/')
            .then(response => response.text())
            .then(response => {
              form_data.append('g-recaptcha-response', 'token')
              form_data.append('lang', 'IT')
              if (listId) {
                form_data.append('list_id', listId)
              }

              fetch('https://3bee.link:8989/api/addNewsletter', {
                method: 'POST',
                body: form_data
              })
                .then((response) => {
                  setModalContent(false)
                })
            }
            )
          event.target.reset()
          return false
        }}
      >
        <StyledFormBlock>
          <StyledFormInput
            id="newsletter-email"
            type="email"
            name="email"
            onInput={(e) =>
              setFormData({ ...formData, email: e.currentTarget.value })
            }
          />
          <StyledFormLabel
            htmlFor="newsletter-email"
            isHidden={formData.email && formData.email.length > 0}
          >
            {labels.MAIL_ADDRESS}
          </StyledFormLabel>
        </StyledFormBlock>
        <StyledFormSubmit type="submit">
          {labels.API_FORM_CTA_LABEL}
        </StyledFormSubmit>
      </StyledForm>
    </StyledAddNewsletterModal>
  )
}
