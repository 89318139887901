import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledNumbersToRevers = styled.div``

export const StyledNumbersToReversItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const StyledNumbersToReversItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-basis: 50%;
  padding-bottom: ${Rem(70)};

  @media (${BP.ipad}) {
    flex-direction: row;
    flex-basis: 25%;
  }
`

export const StyledNumbersToReversItemValue = styled.div`
  font-size: ${Rem(40)};
  line-height: ${Rem(25)};
  color: ${Colors.red};
  padding-bottom: ${Rem(28)};

  @media (${BP.ipad}) {
    font-size: ${Rem(35)};
    padding-bottom: 0;
  }

  ${(props) =>
    props.isGreen &&
    css`
      color: ${Colors.green};
    `}
`

export const StyledNumbersToReversItemLabel = styled.div`
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
  text-align: center;

  @media (${BP.ipad}) {
    text-align: left;
    padding: 0 ${Rem(32)} 0 ${Rem(16)};
  }
`
