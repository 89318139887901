import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledButton } from '../Button/style'

export const StyledTextAndImage = styled.div`
  @media (${BP.ipad}) {
    position: relative;
  }

  ${(props) =>
    props.upside &&
    css`
      display: flex;
      flex-direction: column-reverse;

      @media (${BP.ipad}) {
        display: block;
      }
    `}

  ${(props) =>
    props.theme === 'white' &&
    css`
      color: ${Colors.black};
      background-color: ${Colors.white};
    `}
`

export const StyledTextAndImageContent = styled.div`
  padding-top: ${Rem(60)};
  padding-bottom: ${Rem(50)};

  @media (${BP.ipad}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    padding-right: ${Rem(92)};
    padding-top: ${Rem(120)};
    padding-bottom: ${Rem(120)};

    ${StyledButton} {
      margin: 0;
    }

    ${(props) =>
      props.minHeight &&
      css`
        min-height: ${Rem(props.minHeight)};
      `}
  }
`

export const StyledTextAndImageContentList = styled.ul`
  list-style-type: disc;
  padding-left: ${Rem(20)};
`

export const StyledTextAndImageContentListItem = styled.li`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(30)};
  }
`

export const StyledTextAndImageImage = styled.div`
  ${Ratio(1, 1)};
  width: 100%;
  max-height: ${Rem(375)};
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  @media (${BP.ipad}) {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    max-height: none;
    
    ${(props) =>
      props.bgSize &&
      css`
        background-size: ${props.bgSize};
      `}
    
    ${(props) =>
      props.bgPosition &&
      css`
        background-position: ${props.bgPosition};
      `}
  }
`
