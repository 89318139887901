import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledBanner = styled.div`
  position: relative;
  overflow: hidden;
`

export const StyledBannerImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;

  @media (${BP.ipad}) {
    height: ${Rem(460)};
  }
`

export const StyledBannerContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${Rem(84)} ${Rem(20)} ${Rem(40)};

  @media (${BP.ipad}) {
    justify-content: center;
    padding: ${Rem(130)} ${Rem(20)} ${Rem(100)};
  }
`

export const StyledBannerText = styled.div`
  color: ${Colors.white};
  text-align: center;
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding: 0 ${Rem(20)};

  @media (${BP.ipad}) {
    font-size: ${Rem(36)};
    line-height: ${Rem(40)};
  }
`

export const StyledBannerAuthor = styled.div`
  width: 100%;
  padding-right: ${Rem(20)};
  padding-top: ${Rem(40)};
  color: ${Colors.white};
  font-size: ${Rem(25)};
  line-height: ${Rem(50)};
  text-align: right;

  @media (${BP.ipad}) {
    padding-right: ${Rem(40)};
  }
`

export const StyledBannerCta = styled.div`
  @media (${BP.ipad}) {
    display: none;
  }
`
