import React, { useContext } from 'react'
import {
  StyledShop,
  StyledShopItems,
  StyledShopItem,
  StyledShopItemImage,
  StyledShopItemContent,
  StyledShopItemTitle,
  StyledShopItemSubTitle,
  StyledShopItemFeatures,
  StyledShopItemFeature,
  StyledShopItemFeatureText,
  StyledShopItemCTA,
  StyledShopItemPrice,
  StyledShopItemDiscount,
  StyledShopItemDiscountContent,
} from './style'
import Intro from '../Intro/Intro'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import Button from '../Button/Button'
import { StyledGridRow } from '../../commons/Grid'
import { LayoutContext } from '../../contexts/LayoutContext'
import ProductSheet from '../ProductSheet/ProductSheet'
import Scroller from '../Scroller/Scroller'

export default ({ intro, labels, products }) => {
  const { setModalContent } = useContext(LayoutContext)
  return (
    <StyledShop>
      <Intro {...intro} />
      <StyledGridRow isFullMobile noMargin>
        <Scroller>
          <StyledShopItems>
            {products.map((item, index) => (
              <StyledShopItem key={index}>
                {item.discount && (
                  <StyledShopItemDiscount>
                    <StyledShopItemDiscountContent>
                      <span>{labels.DISCOUNT}</span>
                      <span>{item.discount}</span>
                    </StyledShopItemDiscountContent>
                  </StyledShopItemDiscount>
                )}
                <StyledShopItemImage bg={item.image} />
                <StyledShopItemContent>
                  <StyledShopItemTitle
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <StyledShopItemSubTitle
                    dangerouslySetInnerHTML={{ __html: item.subtitle }}
                  />
                  <StyledShopItemFeatures>
                    {item.features.map((feature, index) => (
                      <StyledShopItemFeature key={index}>
                        <Icon
                          icon={icons[feature.icon]}
                          color={Colors.yellow}
                        />
                        <StyledShopItemFeatureText
                          dangerouslySetInnerHTML={{ __html: feature.text }}
                        />
                      </StyledShopItemFeature>
                    ))}
                  </StyledShopItemFeatures>
                  <StyledShopItemCTA>
                    <Button
                      as="div"
                      noPadding
                      theme="transparent"
                      handleOnClick={() => {
                        setModalContent(
                          <ProductSheet product={products[index]} isModal />
                        )
                      }}
                    >
                      {item.cta.label}
                      <Icon icon={icons.arrowRight} color={Colors.black} />
                    </Button>
                    <StyledShopItemPrice>{item.price}</StyledShopItemPrice>
                  </StyledShopItemCTA>
                </StyledShopItemContent>
              </StyledShopItem>
            ))}
          </StyledShopItems>
        </Scroller>
      </StyledGridRow>
    </StyledShop>
  )
}
