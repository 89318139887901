import React, { useContext } from 'react'
import {
  StyledCrowdPlan,
  StyledDescription,
  StyledPlanBox,
  StyledName,
  StyledPriceRows,
  StyledInitialPrice,
  StyledFinalPrice,
  StyledSection,
  StyledSectionTitle,
  StyledSectionItem,
  StyledSectionItemImage,
  StyledSectionItemText,
  StyledIncludedItems,
  StyledBottomText,
  StyledCta,
  StyledStartAvailability,
  StyledDeliveryInfo,
  StyledDeliveryInfoImg,
  StyledDeliveryInfoText
} from './style'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import Button from '../Button/Button'
import { DataContext } from '../../contexts/DataContext'

export default ({
  id,
  name,
  description,
  price,
  cta,
  startAvailability,
  deliveryInfo,
  firstSection,
  secondSection,
  bottomText
}) => {
    const { labels } = useContext(DataContext)

    let cartData = null;
    switch(id) {
      case 'adventure':
        cartData = {
          id: 'Adopt-adventure',
          image: '/2020/images/products/app_save50.jpg',
          url: '/crowd',
          price: 119,
          name: `${labels.PLAN} ${name}`,
          weight: 0,
        }
        break;
      case 'relax':
        cartData = {
          id: 'Adopt-relax',
          image: '/2020/images/products/app_save50.jpg',
          url: '/crowd',
          price: 179,
          name: `${labels.PLAN} ${name}`,
          weight: 0,
        }
        break;
      case 'grown':
        cartData = {
          id: 'Adopt-grow',
          image: '/2020/images/products/app_save50.jpg',
          url: '/crowd',
          price: 239,
          name: `${labels.PLAN} ${name}`,
          weight: 0,
        }
        break;
      default:
        console.log("Configuration error, check plan configuration for ", name.toLowerCase())
    }

    return (
      <StyledCrowdPlan id={id}>
        <StyledDescription dangerouslySetInnerHTML={{__html: description}} />
        <StyledPlanBox>
          
          <StyledDeliveryInfo>
            <StyledDeliveryInfoImg src={deliveryInfo.image} alt='' />
            <StyledDeliveryInfoText dangerouslySetInnerHTML={{__html: deliveryInfo.label}} />
          </StyledDeliveryInfo>
          
          <StyledName dangerouslySetInnerHTML={{__html: name}} />

          <StyledPriceRows>
            <StyledInitialPrice dangerouslySetInnerHTML={{__html: price.initial}} />
            <p>&nbsp;</p>
            <p><StyledFinalPrice dangerouslySetInnerHTML={{__html: price.final}} />{' '}{price.periodicity}</p>
            <p>+</p>
            <p>{price.add}</p>
          </StyledPriceRows>
          
          {cartData && <Button as="div"
            theme="yellow"
            className="snipcart-add-item"
            noLine
            noPadding
            smaller
            dataitemid={cartData.id}
            dataitemurl={cartData.url}
            dataitemprice={cartData.price}
            dataitemname={cartData.name}
            dataitemimage={cartData.image}
            dataitemweight={cartData.weight}
          >
            <StyledCta>{cta.text}</StyledCta>
          </Button>
          }
          <StyledStartAvailability dangerouslySetInnerHTML={{__html: startAvailability}} />

          <StyledSection>
            <StyledSectionTitle dangerouslySetInnerHTML={{__html: firstSection.title}} />
            {firstSection.items.map((sectionItem, index) => (
              <StyledSectionItem key={`item-${id}-1-${index}`}>
                <StyledSectionItemImage src={sectionItem.image} alt='' />
                <StyledSectionItemText
                  dangerouslySetInnerHTML={{ __html: sectionItem.label }}
                />
              </StyledSectionItem>
            ))}
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle dangerouslySetInnerHTML={{__html: secondSection.title}} />
            
            {secondSection.includedItems && (
              <StyledIncludedItems dangerouslySetInnerHTML={{__html: secondSection.includedItems}} />
            )}
            
            {secondSection.items.map((sectionItem, index) => (
              <StyledSectionItem key={`item-${id}-1-${index}`}>
                <StyledSectionItemImage src={sectionItem.image} alt='' />
                <StyledSectionItemText
                  dangerouslySetInnerHTML={{ __html: sectionItem.label }}
                />
              </StyledSectionItem>
            ))}
          </StyledSection>
          
        </StyledPlanBox>
        <StyledBottomText dangerouslySetInnerHTML={{__html: bottomText}} />
        
      </StyledCrowdPlan>
    )
}
