import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'

export const StyledProductsSelector = styled.div`
  width: 100%;

`

export const StyledProductsSelectorForm = styled.div`
  width: 100%;
`

export const StyledProductsSelectorFormBlock = styled.div`
  margin-bottom: ${Rem(20)};
`

export const StyledProductsSelectorFormLegend = styled.div`
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  text-transform: uppercase;
  padding-bottom: ${Rem(12)};
`

export const StyledProductsSelectorFormSelect = styled.div`
  display: inline-block;
  margin-right: ${Rem(30)};
  margin-bottom: ${Rem(24)};
  min-width: ${Rem(140)};
`

export const StyledProductsSelectorFormCTA = styled.div`
  display: inline-flex;
  align-items: center;

  ${StyledIcon} {
    right: ${Rem(10)};
  }
`

export const StyledProductsSelectorFormPrice = styled.div`
  color: ${Colors.yellow};
  margin-left: ${Rem(24)};
  font-size: ${Rem(18)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
`
