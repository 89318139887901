import React from 'react'
import {
  StyledHiveTechCrowdSectionHeader,
  StyledHiveTechCrowdSectionHeaderTitle,
  StyledHiveTechCrowdSectionHeaderSubtitle,
} from './style.jsx'

export default ({
  id,
  title,
  subtitle,
  theme = 'black',
}) => {
  return (
    <StyledHiveTechCrowdSectionHeader id={id} isWhite={theme=='white'}>
      <StyledHiveTechCrowdSectionHeaderTitle dangerouslySetInnerHTML={{ __html: title }}/>
      {subtitle && (
        <StyledHiveTechCrowdSectionHeaderSubtitle dangerouslySetInnerHTML={{ __html: subtitle }}/>
      )}
    </StyledHiveTechCrowdSectionHeader>
  )
}

