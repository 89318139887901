import React, { useContext, useMemo } from 'react'
import {
  StyledHiveTechCrowdHeader,
  StyledHiveTechCrowdHeaderTitle,
  StyledHiveTechCrowdHeaderSubtitle,
  StyledHiveTechCrowdHeaderParagraph,
} from './style'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator'
import { DataContext } from '../../contexts/DataContext'
import useCounter from '../../hooks/useCounter'
import useScrollController from '../../hooks/useScrollController'

export default ({
  title,
  subtitle,
  soldDevices,
  goalDevices,
  buyers,
  buyButton,
  contactButton,
  contactLink,
}) => {
  const { labels } = useContext(DataContext)
  const { scrollToCssSelector } = useScrollController()
  
  const [oldSoldDevices, oldBuyers] = useMemo(() => {
    const t0 = Date.parse('2021-03-09T15:00:00')
    const now = Date.now()
    const hours = (now-t0)/1000/60/60
    if (hours < 0) {
      return [0, 0]
    }
    const devices = hours * 4
    const buyers = hours * 3
    return [
      devices > 401 ? 401 : Math.trunc(devices),
      buyers > 320 ? 320 : Math.trunc(buyers),
    ]
  }, [])

  const soldDevicesCounter = useCounter(soldDevices + oldSoldDevices, 500)
  const buyersCounter = useCounter(buyers + oldBuyers, 500)
  
  return (
    <StyledHiveTechCrowdHeader>
      <StyledHiveTechCrowdHeaderTitle dangerouslySetInnerHTML={{ __html: title }} />
      <StyledHiveTechCrowdHeaderSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
     
      <Button as="div" theme="yellow" handleOnClick={() => scrollToCssSelector('#perks', 64)}>
        {buyButton}
      </Button>

      {contactButton && contactLink && (
        <Button as="div" theme="yellow" to={contactLink} useBlankTarget>
          {contactButton}
        </Button>
      )}
    </StyledHiveTechCrowdHeader>
  )
}
