import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledMap = styled.div`
  position: relative;
  ${Ratio(375, 230)};
  width: 100%;

  @media (${BP.ipad}) {
    ${Ratio(1200, 408)};
  }

  .map__popup {
    background-color: ${Colors.white};
    padding: ${Rem(8)};
    border-radius: ${Rem(5)};

    &:after {
      display: block;
      content: '';
      position: absolute;
      width: ${Rem(18)};
      height: ${Rem(18)};
      background-color: inherit;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      z-index: -1;
    }
  }
`

export const StyledMapMap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`
