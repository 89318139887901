import React, { useState } from 'react'
import {
  StyledTechPreview,
  StyledTechPreviewSubtitle,
  StyledTechPreviewTitle,
  StyledTechPreviewNav,
  StyledTechPreviewNavItem,
  StyledTechPreviewNavItemLabel,
  StyledTechPreviewIntro,
  StyledTechPreviewVideos,
  StyledTechPreviewMedia,
  StyledTechPreviewMediaImage,
} from './style'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { StyledGridRow } from '../../commons/Grid'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import Countdown from '../Countdown/Countdown'
export default ({ id, title, subtitle, items, crowdDate }) => {
  const [currentImage, setCurrentImage] = useState(0)

  return (
    <StyledTechPreview id={id}>
      <StyledTechPreviewIntro>
        <StyledGridRow noMargin>
          <StyledTechPreviewTitle dangerouslySetInnerHTML={{ __html: title }} />
          <StyledTechPreviewSubtitle
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </StyledGridRow>
      </StyledTechPreviewIntro>
      {crowdDate && (<Countdown date={crowdDate}/>)}
      <StyledGridRow noMargin isFull>
        <StyledTechPreviewMedia>
          <StyledTechPreviewMediaImage bg={items[currentImage].image} />
        </StyledTechPreviewMedia>
      </StyledGridRow>
      <StyledGridRow noMargin>
        <StyledTechPreviewNav>
          {items.map((item, index) => (
            <StyledTechPreviewNavItem
              key={index}
              isActive={currentImage === index}
              onClick={() => setCurrentImage(index)}
            >
              <Icon icon={icons[item.icon]} color={Colors.white60} />
              <StyledTechPreviewNavItemLabel>
                {item.label}
              </StyledTechPreviewNavItemLabel>
            </StyledTechPreviewNavItem>
          ))}
        </StyledTechPreviewNav>
      </StyledGridRow>
    </StyledTechPreview>
  )
}
