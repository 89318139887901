import React, { useContext, useEffect } from 'react'
import * as Components from '..'
import { DataContext } from '../../contexts/DataContext'
import useIsMount from '../../hooks/useIsMount'
import useScrollController from '../../hooks/useScrollController'
import {
  StyledMultiSectionLayout,
  StyledMultiSectionLayoutSection,
} from './style'

const createComponent = ({ component, props }, index, usePaddingLeft) => {
  const Component = Components[component]
  return Component ? ( 
    <div key={index} className={usePaddingLeft ? 'col-70-children' : null}>
      <Component
        {...props}
      />
    </div>
  ) : (
    <div key={index}>
      <hr />
      componente {component} non trovato - controllare l'export nel file
      /src/components/index.js
      <hr />
    </div>
  )
}

export default ({
  sections = [],
  usePaddingLeft = false,
}) => {
  const { selectedSectionId } = useContext(DataContext)
  const { scrollToCssSelector } = useScrollController()
  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      scrollToCssSelector('#section-presentation', 64)
    }
  }, [selectedSectionId])

  return (
    <StyledMultiSectionLayout>
      {sections.map((section, index) => {
        return <StyledMultiSectionLayoutSection key={index} id={section.id} className={section.id == selectedSectionId ? 'show' : null}>
          {section.components.map((component, index) => createComponent(component, index, usePaddingLeft))}
        </StyledMultiSectionLayoutSection>
      })}
    </StyledMultiSectionLayout>
  )
}
