import React, { useState } from 'react'
import {
  StyledCatalogueForm,
  StyledCatalogueFormBlock,
  StyledCatalogueFormSubmit,
} from './style'
import {
  StyledTitle,
  StyledBigText,
  StyledParagraph,
} from '../../commons/Typography'
import { StyledGridRow } from '../../commons/Grid'
import {
  StyledForm,
  StyledFormBlock,
  StyledFormInput,
  StyledFormLabel,
  StyledFormSubmit,
} from '../../commons/Form'
import Icon from '../Icon/Icon'
import icons from '../Icon/icons'
import { Colors } from '../../commons/Theme'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default ({ title, subtitle, bigText, form }) => {
  const [formData, setFormData] = useState({})
  return (
    <StyledCatalogueForm>
      <StyledGridRow noMargin>
        <StyledCatalogueFormBlock>
          <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
          <StyledBigText dangerouslySetInnerHTML={{ __html: bigText }} />
        </StyledCatalogueFormBlock>
        <StyledCatalogueFormBlock>
          <StyledParagraph dangerouslySetInnerHTML={{ __html: subtitle }} />
          <StyledForm
            Validate
            onSubmit={(event) => {
              event.preventDefault()
              toast.configure();
              var link = document.createElement('a');
              link.href = "https://www.3bee.it/documents/catalogo_prodotti_2020_WEB.pdf";
              link.download = "Catalogo 3bee hive-tech";
              const form_data = new FormData(event.currentTarget);
              fetch('https://api.ipify.org/')
              .then(response => response.text())
              .then(response =>{
                form_data.append('g-recaptcha-response', 'token');
                form_data.append('lang', 'IT');
                fetch('https://3bee.link:8989/api/addNewsletter', {
                method: 'POST',
                body: form_data
                })
                .then((response) => {
                  if (response.status === 200){
                   toast.success("Iscritto!", {containerId: 'Alert', position: toast.POSITION.TOP_CENTER, autoClose: 2000 });  
                   document.body.appendChild(link);
                   link.click();
                   document.body.removeChild(link); 
                  } else {
                   toast.error("Errore, riprova o contatta info@3bee.it", {containerId: 'Alert', position: toast.POSITION.TOP_CENTER});   
                   document.body.appendChild(link);
                   link.click();
                   document.body.removeChild(link);
                  }
                })
                .catch(function(){
                  toast.error("Errore, contattaci a info@3bee.it", {containerId: 'Alert', position: toast.POSITION.BOTTOM_CENTER});   
                });
                }
              )
              event.target.reset();
              return false
              }}
          >
            <StyledFormBlock>
              <StyledFormInput
                isYellow
                id="catalogue-email"
                name="email"
                type="email"
                onInput={(e) => {
                  setFormData({ ...formData, email: e.currentTarget.value })
                }}
              />
              <StyledFormLabel
                htmlFor="catalogue-email"
                isHidden={formData.email && formData.email.length > 0}
              >
                {form.placeholder}
              </StyledFormLabel>
            </StyledFormBlock>
            <StyledCatalogueFormSubmit>
              <StyledFormSubmit type="submit">
                {form.cta}
                <Icon icon={icons.arrowRight} color={Colors.black} />
              </StyledFormSubmit>
            </StyledCatalogueFormSubmit>
          </StyledForm>
        </StyledCatalogueFormBlock>
      </StyledGridRow>
    </StyledCatalogueForm>
  )
}
