import React from 'react'
import {
  StyledTechnological,
  StyledTechnologicalIntro,
  StyledTechnologicalTitle,
  StyledTechnologicalSubtitle,
  StyledTechnologicalItems,
  StyledTechnologicalItem,
  StyledTechnologicalItemImage,
  StyledTechnologicalItemContent,
  StyledTechnologicalItemCounter,
  StyledTechnologicalItemTitle,
  StyledTechnologicalCTA,
} from './style.jsx'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Link } from 'gatsby'
import { StyledGridRow } from '../../commons/Grid'

export default ({ title, subtitle, items, cta }) => {
  return (
    <StyledTechnological>
      <StyledGridRow>
        <StyledTechnologicalIntro>
          <StyledTechnologicalTitle
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <StyledTechnologicalSubtitle
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </StyledTechnologicalIntro>
        <StyledTechnologicalItems>
          {items.map((item, index) => (
            <StyledTechnologicalItem key={index}>
              <StyledTechnologicalItemImage
                bg={item.image.mobile}
                bgDesktop={item.image.desktop}
              />
              <StyledTechnologicalItemContent>
                <StyledTechnologicalItemCounter>
                  0{index + 1}
                </StyledTechnologicalItemCounter>
                <StyledTechnologicalItemTitle>
                  {item.title}
                </StyledTechnologicalItemTitle>
              </StyledTechnologicalItemContent>
            </StyledTechnologicalItem>
          ))}
        </StyledTechnologicalItems>
      </StyledGridRow>
      <StyledTechnologicalCTA>
        <Button as="div" to={cta.url}>
          {cta.label} <Icon icon={icons.arrowRight} />
        </Button>
      </StyledTechnologicalCTA>
    </StyledTechnological>
  )
}
