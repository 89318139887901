import styled, { css } from 'styled-components'
import { BP, Colors, Rem, Ratio, Sizes, Font, Shadow } from '../../commons/Theme'

export const StyledSectionHeader = styled.div`
    text-align: center;
    font-size: ${Rem(32)};
    line-height: ${Rem(46)};
    margin-bottom: ${Rem(26)};

    margin-top: ${(props) => props.marginTop ? Rem(props.marginTop) : 'unset'};
`

export const StyledTitle = styled.div``

export const StyledUnderlinedTitle = styled.div`
  text-decoration: underline;
  text-decoration-color: ${Colors.yellow};
  text-decoration-thickness: ${Rem(2)};
  text-underline-offset: ${Rem(4)};

  ${props => props.showAsterisk && css`
    &:after {
      background-image: url('/2020/images/hive-tech-crowd/asterisk.png');
      content: "";
      width: ${Rem(20)};
      height: ${Rem(20)};
      position: absolute;
      display: inline-block;
      background-size: ${Rem(20)};
    }
  `}
  
`

export const StyledPs = styled.div`
    font-size: ${Rem(12)};
`
