import React, { useEffect, useRef, useState } from 'react'
import {
  StyledCausesList,
  StyledCausesListTitle,
  StyledCausesListScroller,
  StyledCausesListItems,
  StyledCausesListItem,
  StyledCausesListImage,
  StyledCausesListLabel,
  StyledCausesListPopup,
  StyledCausesListMobilePopup,
} from './style.jsx'
import { StyledGridRow } from '../../commons/Grid'
import useClickOutside from '../../hooks/useClickOutside'
import Scroller from '../Scroller/Scroller'

export default ({ title, items }) => {
  const popup = useRef(null)
  const [visiblePopup, setVisiblePopup] = useState(NaN)

  useClickOutside(popup, () => {
    setVisiblePopup(NaN)
  })

  return (
    <StyledGridRow isFullMobile id="causeslist">
      <StyledCausesList>
        <StyledCausesListTitle>{title}</StyledCausesListTitle>
        <Scroller>
          <StyledCausesListItems>
            {items.map((item, index) => (
              <StyledCausesListItem
                key={index}
                onMouseOver={() => setVisiblePopup(index)}
                onMouseLeave={() => setVisiblePopup(NaN)}
                onClick={() => setVisiblePopup(index)}
              >
                <StyledCausesListImage src={item.image} />
                <StyledCausesListLabel
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
                <StyledCausesListPopup
                  ref={popup}
                  isVisible={visiblePopup === index}
                  dangerouslySetInnerHTML={{ __html: item.popup }}
                />
              </StyledCausesListItem>
            ))}
          </StyledCausesListItems>
        </Scroller>
        <StyledCausesListMobilePopup
          ref={popup}
          dangerouslySetInnerHTML={{
            __html: visiblePopup >= 0 ? items[visiblePopup].popup : null,
          }}
        />
      </StyledCausesList>
    </StyledGridRow>
  )
}
