import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledFounders = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const StyledFoundersImage = styled.img`
  width: 100%;
  max-width: ${Rem(682)};
  margin-bottom: ${Rem(46)};
  background-color: ${Colors.grayLight};
`

export const StyledFoundersContent = styled.div`
  @media (${BP.ipad}) {
    padding-left: ${Rem(128)};
  }
`

export const StyledFoundersParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(12)};
`

export const StyledFoundersCTA = styled.div`
  display: inline-block;
`
