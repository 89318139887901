import styled, { css } from 'styled-components'
import { BP, Colors, Rem, Ratio, Sizes, Font, Shadow } from '../../commons/Theme'

export const StyledAdoptPayment = styled.div`
  text-align: center;
`

export const StyledPaymentParagraph = styled.p`
    min-height: ${Rem(40)};
`

export const StyledArrowParagraph = styled.p`
    min-height: ${Rem(40)};
    transform: rotate(270deg);

`
