import React, { useState, useContext } from 'react'
import { StyledGridRow } from '../../commons/Grid'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  StyledForm,
  StyledFormFieldset,
  StyledFormBlock,
  StyledFormInput,
  StyledFormSubmit,
  StyledFormSelect,
  StyledFormCheckbox,
  StyledFormCheckboxInput,
  StyledFormCheckboxCheckmark,
} from '../../commons/Form'
import { 
  StyledContactForm,
  StyledContactFormSubmit,
} from './style.jsx'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { Intro } from '../index'
import { DataContext } from '../../contexts/DataContext'
import usePipeform from '../../hooks/usePipeform.js';

export default ({ title, bigText, positiveMessages, negativeMessages, companyNamePlaceholder, showNumEmployees = true }) => {
  const [formData, setFormData] = useState({lang:'it',grecaptcharesponse: 'token'})
  const { labels } = useContext(DataContext)
  const { sendPipeform, pipeformHowKnowUsOptions } = usePipeform();

  const trackError = () => {
    window.dataLayer.push({
      event: "customError",
      custom: {
        errorSource: "Pipedrive",
        errorMessage: `ContactForm: ${window.location.href}`,
      },
    });
  };

  return (
    <StyledContactForm hasGradient="False" id="contactForm">
      <Intro title={title} bigText={bigText} themeColor="white" />
      <StyledGridRow>
        <StyledForm
          Validate
          onSubmit={(event) => {
            toast.configure();
            event && event.preventDefault();
            sendPipeform({
              name: formData.name, 
              email: formData.email, 
              companyName: formData.company,
              numEmployees: formData.numEmployees,
              phone: formData.tel,
              message: formData.request, 
              howKnowUsId: formData.howKnowUsId === "" ? undefined : parseInt(formData.howKnowUsId),
              acceptMarketing: formData.acceptMarketing,
            })
            .then((response) => {
              if (response.status === 202){
                toast.success(positiveMessages, {containerId: 'Alert', position: toast.POSITION.BOTTOM_CENTER });
                
                
              } else {
                toast.error(negativeMessages, {containerId: 'Alert', position: toast.POSITION.BOTTOM_CENTER});
                console.log("Contattaci a info@3bee.it");
                trackError();
              }

              //this.props.newCaptchaToken();
            })
            .catch(function(e){
              toast.error(negativeMessages, {containerId: 'Alert', position: toast.POSITION.BOTTOM_CENTER});
              console.log("Errore con la chiamata", e)
              trackError();
            });
            event.target.reset()
            return false
          }}
        >
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-user"
                type="text"
                placeholder={labels.NAME_SURNAME}
                required
                onInput={(e) => {
                  setFormData({ ...formData, name: e.currentTarget.value })
                }}
              />
              
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-email"
                type="email"
                required
                placeholder={labels.MAIL_ADDRESS}
                onInput={(e) => {
                  setFormData({ ...formData, email: e.currentTarget.value })
                }}
              />
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-phone"
                type="tel"
                required
                placeholder={labels.PHONE_MOBILE}
                onInput={(e) => {
                  setFormData({ ...formData, tel: e.currentTarget.value })
                }}
              />
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-company-name"
                type="text"
                required
                placeholder={companyNamePlaceholder ?? labels.companyName}
                onInput={(e) => {
                  setFormData({ ...formData, company: e.currentTarget.value });
                }}
              />
            </StyledFormBlock>
            {showNumEmployees && (
              <StyledFormBlock>
                <StyledFormInput
                  id="contacts-company-employees"
                  type="number"
                  pattern="[0-9]*"
                  min="1"
                  placeholder={labels.companyEmployees}
                  onInput={(e) => {
                    setFormData({ ...formData, numEmployees: e.currentTarget.value });
                  }}
                />
              </StyledFormBlock>
            )}
            <StyledFormBlock>
              <StyledFormSelect>
                <select
                  onChange={(e) => {
                    setFormData({ ...formData, howKnowUsId: e.currentTarget.value });
                  }}
                >
                  <option value=''>{labels.howKnowUs}</option>
                  {pipeformHowKnowUsOptions.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.value}
                    </option>
                  ))}
                </select>
              </StyledFormSelect>
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                as="textarea"
                rows={3}
                id="contacts-request"
                required
                placeholder={labels.REQUEST}
                onInput={(e) => {
                  setFormData({ ...formData, request: e.currentTarget.value })
                }}
              />

            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormCheckbox>
                <span dangerouslySetInnerHTML={{ __html: labels.acceptPrivacy }}></span>
                <StyledFormCheckboxInput required/>
                <StyledFormCheckboxCheckmark />
              </StyledFormCheckbox>
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormCheckbox>
                {labels.acceptMarketing}
                <StyledFormCheckboxInput 
                  checked={formData.acceptMarketing}
                  onInput={() => {
                    setFormData({
                      ...formData,
                      acceptMarketing: !formData.acceptMarketing,
                    });
                  }}
                />
                <StyledFormCheckboxCheckmark />
              </StyledFormCheckbox>
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledContactFormSubmit>
            <StyledFormSubmit type="submit">
              {labels.SEND_REQUEST}
              <Icon icon={icons.arrowRight} color={Colors.white} />
            </StyledFormSubmit>
          </StyledContactFormSubmit>
        </StyledForm>
      </StyledGridRow>
    </StyledContactForm>
  )
}
