import React from 'react'
import * as Components from '..'
import {
  StyledTwoColumnLayout,
  StyledTwoColumnLayoutFirst,
  StyledTwoColumnLayoutSecond,
} from './style'
import { StyledGridRow } from '../../commons/Grid'

const createComponent = ({ component, props }, index) => {
  const Component = Components[component]
  return Component ? (
    <Component
      key={index}
      {...props}
    />
  ) : (
    <div key={index}>
      <hr />
      componente {component} non trovato - controllare l'export nel file
      /src/components/index.js
      <hr />
    </div>
  )
}

export default ({
  breakpoint = 'tablet',
  firstColumnWidthPercentage = 50,
  reverseAfterBreakpoint = false,
  firstColumnComponents = [],
  secondColumnComponents = [],
  isFirstFull = false,
  isSecondFull = false,
  firstAlignSelf = 'auto',
  secondAlignSelf = 'auto',
}) => {
  return (
    <StyledTwoColumnLayout breakpoint={breakpoint} reverseAfterBreakpoint={reverseAfterBreakpoint}>
      <StyledTwoColumnLayoutFirst breakpoint={breakpoint} widthPercentage={firstColumnWidthPercentage} isFull={false} alignSelf={firstAlignSelf}>
        {firstColumnComponents.map(createComponent)}
      </StyledTwoColumnLayoutFirst>
      <StyledTwoColumnLayoutSecond breakpoint={breakpoint} widthPercentage={100-firstColumnWidthPercentage} isFull={false} alignSelf={secondAlignSelf}>
        {secondColumnComponents.map(createComponent)}
      </StyledTwoColumnLayoutSecond>
    </StyledTwoColumnLayout>
  )
}
