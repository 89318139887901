import styled, { css } from 'styled-components'
import { BP, Colors, Ratio, Rem } from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'

export const StyledVimeoPlayer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  background-color: inherit;

  @media (${BP.ipad}) {
  }
`

export const StyledVimeoPlayerLoading = styled.div`
  position: absolute;
  width: ${Rem(120)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  > img {
    width: 100%;
    height: auto;
  }
`

export const StyledVimeoPlayerVideo = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: auto;
  transform: translateY(-50%);
  z-index: 1;
`

export const StyledVimeoPlayerVideoPlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  pointer-events: none;

  ${StyledIcon} {
    width: ${Rem(60)};
    transition: transform 0.2s linear 0s;
  }

  ${(props) =>
    !props.showIcon &&
    css`
      display: none;
    `}
`

export const StyledVimeoPlayerThumb = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-out 0s;
  z-index: 3;

  &:hover {
    ~ * ${StyledIcon} {
      transform: scale(1.25);
    }
  }

  > img {
    width: 100%;
  }

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`
