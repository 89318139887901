import React, {useContext} from 'react'
import { DataContext } from '../../contexts/DataContext'
import NewsletterForm from '../NewsletterForm/NewsletterForm'
import { StyledGridRow } from '../../commons/Grid'
import useApi from '../../hooks/useApi'
import {
  StyledSignUp,
} from './style.jsx'

import {
  StyledFooterNewsletter,
  StyledFooterNewsletterText,
  StyledFooterNewsletterForm,
  StyledFooterNewsletterInput,
  StyledFooterNewsletterSubmit,
} from '../Footer/style.jsx'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


export default ({
  urlIframe
}) => {
  const {i18nLink, labels} = useContext(DataContext)
  const { addNewsletterContact } = useApi()
  return (
    <StyledGridRow noMargin isFull="true">
      <StyledSignUp id="contactForm">
        <StyledFooterNewsletter>
          <StyledFooterNewsletterText>
            {labels.NEWSLETTER_ABOUT}
          </StyledFooterNewsletterText>
          <StyledFooterNewsletterForm
            Validate
            onSubmit={(event) => {
              event.preventDefault()
              toast.configure();
              const form_data = new FormData(event.currentTarget)
              form_data.append('g-recaptcha-response', 'token')
              form_data.append('lang', 'IT')
              form_data.append('list_id', 25)

              window.dataLayer.push({
                'event': 'subscription-newsletter-crowd',
              });

              fetch('https://3bee.link:8989/api/addNewsletter', {
                  method: 'POST',
                  body: form_data
                })
                .then((response) => {
                  if (response.status === 200){
                   toast.success("Iscritto!", {containerId: 'Alert', position: toast.POSITION.TOP_CENTER, autoClose: 2000 });  
                  } else {
                   toast.error("Errore, riprova o contatta info@3bee.it", {containerId: 'Alert', position: toast.POSITION.TOP_CENTER});   
                  }
                });
              
              event.target.reset()
              return false
            }}
          >
            <StyledFooterNewsletterInput
                type="email"
                name="email"
                placeholder={labels.MAIL_ADDRESS}
            />
          <StyledFooterNewsletterSubmit>
            <Icon icon={icons.arrowRight} color={Colors.black} />
          </StyledFooterNewsletterSubmit>
          </StyledFooterNewsletterForm>
        </StyledFooterNewsletter>
      </StyledSignUp>
      <StyledSignUp>Approfitta ora dello sconto. Rimani aggiornato e si tra i primi 50 ad acquistare con il 50%</StyledSignUp>
    </StyledGridRow>
  )
}


/*fetch('https://3bee.link:8989/api/addNewsletter', {
                              method: 'POST',
                              body: form_data,
                            })
                              .then((response) => {
                                if (response.status === 200) {
                                  toast.success('Iscritto!', {
                                    containerId: 'Alert',
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                  })
                                } else {
                                  toast.error(
                                    'Errore, riprova o contatta info@3bee.it',
                                    {
                                      containerId: 'Alert',
                                      position: toast.POSITION.TOP_CENTER,
                                    }
                                  )
                                }
                              })
                              .catch(function () {
                                toast.error(
                                  'Errore, contattaci a info@3bee.it',
                                  {
                                    containerId: 'Alert',
                                    position: toast.POSITION.BOTTOM_CENTER,
                                  }
                                )
                              })*/

