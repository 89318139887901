import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledVimeoPlayer } from '../VimeoPlayer/style'
import { StyledBigText, StyledParagraph } from '../../commons/Typography'

export const StyledScroller = styled.div`
  background-color: ${Colors.black};
  padding-top: ${Rem(32)};
  
  ${(props) =>
    props.isWhite &&
    css`
      background-color: ${Colors.white};
    `}

  @media (${BP.ipad}) {
    padding-top: ${Rem(100)};

    ${StyledParagraph} {
      max-width: 50%;
      margin-bottom: ${Rem(100)};
    }

    ${StyledBigText} {
      position: absolute;
      right: ${Rem(20)};
      bottom: 0;
      max-width: 50%;
    }
  }
`

export const StyledScrollerItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
`

export const StyledScrollerItem = styled.div`
  position: relative;
  ${Ratio(1, 1)};
  min-width: 76%;
  margin-right: ${Rem(24)};

  @media (${BP.ipad}) {
    min-width: calc(25% - ${Rem(16)});
    max-width: calc(25% - ${Rem(16)});
  }

  ${StyledVimeoPlayer} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
