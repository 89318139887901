import styled, { css } from 'styled-components'
import { BP, Colors, Rem, Ratio, Sizes, Font, Shadow } from '../../commons/Theme'

export const StyledButtonBlock = styled.div`
  text-align: center;
  margin: ${Rem(20)} 0 ${Rem(30)};
`

export const StyledChildren = styled.div`
  // text-align: center;
  width: 100%;
`