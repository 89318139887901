import React, { useContext, useEffect, useState } from 'react'
import {
  StyledComparativeForm,
  StyledComparativeFormInput,
  StyledComparativeFormResults,
  StyledComparativeFormResult,
  StyledComparativeFormResultsLabel,
  StyledComparativeFormResultsValue,
  StyledComparativeFormTitle,
  StyledComparativeFormBlock,
} from './style'
import { DataContext } from '../../contexts/DataContext'

const getForm = ({ type, labels, callback }) => {
  const [values, setValues] = useState({
    hives: '',
    apiaries: '',
  })
  useEffect(() => {
    callback(values)
  }, [values])
      return (
        <div>
          <StyledComparativeFormTitle>
            {labels.COMPARATIVE_FORM_TITLE_FULL}
          </StyledComparativeFormTitle>
          <StyledComparativeFormBlock>
            <StyledComparativeFormInput
              type="text"
              name="alveari"
              placeholder={labels.HIVE_PLACEHOLDER}
              onInput={(e) =>
                setValues({ ...values, hives: e.currentTarget.value })
              }
            />
            <StyledComparativeFormInput
              type="text"
              name="apiari"
              placeholder={labels.APIARY_PLACEHOLDER}
              onInput={(e) =>
                setValues({ ...values, apiaries: e.currentTarget.value })
              }
            />
          </StyledComparativeFormBlock>
        </div>
      )
}

const getSuggestions = (values) => {
      return {
        hives: values.hives,
        apiaries: values.apiaries,
        devices: ((Number(values.hives) <=(Number(values.apiaries))) && (Number(values.apiaries)) >= 1) ? Math.round(Number(values.hives)/2) : Number(values.apiaries) + Math.round(Number(values.hives)/6),
        cost: ((Number(values.hives) <=(Number(values.apiaries))) && (Number(values.apiaries)) >= 1) ? (Math.round(Number(values.hives)/2))*439: (Number(values.apiaries) + Math.round(Number(values.hives)/6))*439,
      }
}

export default ({ type, onValuesChange }) => {
  const { labels } = useContext(DataContext)
  const [dynamicContents, setDynamicContents] = useState({
    devices: 1,
    cost: 10,
  })
  useEffect(() => {
    onValuesChange(dynamicContents)
  }, [dynamicContents])
  return (
    <StyledComparativeForm>
      {getForm({
        type,
        labels,
        callback: (values) => {
          setDynamicContents(getSuggestions(values))
        },
      })}
      <StyledComparativeFormResults>
        <StyledComparativeFormResult>
          <StyledComparativeFormResultsLabel>
            {labels.COMPARATIVE_DEVICES}:{' '}
            <span className="color-yellow">{dynamicContents.devices}</span>
          </StyledComparativeFormResultsLabel>
          <StyledComparativeFormResultsValue></StyledComparativeFormResultsValue>
        </StyledComparativeFormResult>
        <StyledComparativeFormResult>
          <StyledComparativeFormResultsLabel>
            {labels.COMPARATIVE_COST}:{' '}
            <span className="color-yellow">{dynamicContents.cost}</span>
          </StyledComparativeFormResultsLabel>
          <StyledComparativeFormResultsValue></StyledComparativeFormResultsValue>
        </StyledComparativeFormResult>
      </StyledComparativeFormResults>
    </StyledComparativeForm>
  )
}
