import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import {
  StyledForm,
  StyledFormInput,
  StyledFormSubmit,
} from '../../commons/Form'
import { StyledParagraph } from '../../commons/Typography'

export const StyledCatalogueForm = styled.div`
  padding-top: ${Rem(54)};
  padding-bottom: ${Rem(68)};
  background-color: ${Colors.yellow};

  ${StyledForm} {
    display: block;
    width: 100%;
    padding-top: ${Rem(24)};
  }

  @media (${BP.ipad}) {
    padding-top: ${Rem(60)};
    padding-bottom: ${Rem(82)};

    ${StyledForm} {
      position: relative;
      padding-top: 0;

      > *:first-child {
        width: calc(100% - ${Rem(240)});
      }
    }

    ${StyledParagraph} {
      font-size: ${Rem(16)};
      line-height: ${Rem(24)};
      max-width: 50%;
      padding-right: ${Rem(45)};
      padding-bottom: 0;
    }
  }
`

export const StyledCatalogueFormBlock = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media (${BP.ipad}) {
    flex-direction: row;

    &:last-child {
      align-items: center;
    }
  }
`

export const StyledCatalogueFormSubmit = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${Rem(44)};

  @media (${BP.ipad}) {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    padding-top: 0;
    height: ${Rem(66)};
  }

  ${StyledFormSubmit} {
    color: ${Colors.black};
    background-color: ${Colors.white};
    @media (${BP.ipad}) {
      height: 100%;
    }
  }
`
