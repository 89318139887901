import React, { useContext, useEffect, useState } from 'react'
import {
  StyledProductsSelector,
  StyledProductsSelectorForm,
  StyledProductsSelectorFormBlock,
  StyledProductsSelectorFormLegend,
  StyledProductsSelectorFormSelect,
  StyledProductsSelectorFormCTA,
  StyledProductsSelectorFormPrice,
} from './style'
import TextAndImage from '../TextAndImage/TextAndImage'
import IconsSlider from '../IconsSlider/IconsSlider'
import ProductSheet from '../ProductSheet/ProductSheet'
import { StyledFormSelect } from '../../commons/Form'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import PurchasePlans from '../PurchasePlans/PurchasePlans'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { DataContext } from '../../contexts/DataContext'

gsap.registerPlugin(ScrollToPlugin)

export default ({
  textAndImage,
  iconsSlider,
  productSheet,
  productPrice,
  productsName,
  purchasePlans,
  productsid,
  labels,
  products,
  plans,
}) => {
  const [currentProduct, setCurrentProduct] = useState(0)
  const [filters, setFilters] = useState({
    plan: 0,
    accessory1: 0,
    accessory2: 0,
  })
  const [price, setPrice] = useState(439)
  const [fullName, setFullName] = useState("base")
  const [idCartItem, setIdCartItem] = useState(400)
  const [buyUrl, setBuyUrl] = useState('/')
  const plansSelect = [labels.PLAN_0, labels.PLAN_1, labels.PLAN_2]
  const { path } = useContext(DataContext)
  const pageUrl = `/${path}`

  useEffect(() => {
      setPrice(productPrice.base+filters.plan*150+filters.accessory1*productPrice.accessory1+filters.accessory2*productPrice.accessory2)
      setIdCartItem(400+filters.plan*10+filters.accessory1*5+filters.accessory2*1)
      var nameString=""
      if (filters.accessory1){nameString="Con GPS e "} else {nameString="senza GPS e "}
      if (filters.accessory2){nameString=nameString + "Con Pannello solare"} else {nameString=nameString  + "senza Pannello solare"}
      setFullName(nameString)
  }, [filters])

  return (
    <StyledProductsSelector id="plansForm">
      <TextAndImage {...textAndImage}>
        <StyledProductsSelectorForm>
          <StyledProductsSelectorFormBlock>
            <StyledProductsSelectorFormLegend>
              {labels.CHOOSE_PLAN}
            </StyledProductsSelectorFormLegend>
            <StyledProductsSelectorFormSelect>
              <StyledFormSelect>
                <select
                  value={filters.plan}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      plan: Number(e.currentTarget.value),
                    })
                  }}
                >
                  {plansSelect.map((plan, index) => (
                    <option value={index} key={index}>
                      {plan}
                    </option>
                  ))}
                </select>
              </StyledFormSelect>
            </StyledProductsSelectorFormSelect>
          </StyledProductsSelectorFormBlock>
          <StyledProductsSelectorFormBlock multiple>
            <StyledProductsSelectorFormLegend>
              {labels.ACCESSORIES}
            </StyledProductsSelectorFormLegend>
            <StyledProductsSelectorFormSelect>
              <StyledFormSelect>
                <select
                    value={filters.accessory1}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        accessory1: Number(e.currentTarget.value),
                      })
                    }}
                  >
                  <option value={0}>Senza GPS</option>
                  <option value={1}>Con GPS</option>
                </select>
              </StyledFormSelect>
            </StyledProductsSelectorFormSelect>
            <StyledProductsSelectorFormSelect>
              <StyledFormSelect>
                <select
                      value={filters.accessory2}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          accessory2: Number(e.currentTarget.value),
                        })
                      }}
                    >
                  <option value={0}>Senza Pannello Solare</option>
                  <option value={1}>Con Pannello Solare</option>
                </select>
              </StyledFormSelect>
            </StyledProductsSelectorFormSelect>
          </StyledProductsSelectorFormBlock>
          <StyledProductsSelectorFormCTA>
            <Button as="div"
                    noPadding theme="white"
                    className="snipcart-add-item"
                    dataitemid={idCartItem}
                    dataitemprice={price}
                    dataitemimage={products[productsid].image}
                    dataitemurl={pageUrl}
                    dataitemname={productsName +" "+ fullName}>
                {labels.BUY}
                <Icon icon={icons.arrowRight} />   
            </Button>
 
            <StyledProductsSelectorFormPrice>
              {price}€
            </StyledProductsSelectorFormPrice>
          </StyledProductsSelectorFormCTA>
        </StyledProductsSelectorForm>
      </TextAndImage>
      <IconsSlider {...iconsSlider} />
      <ProductSheet {...productSheet} product={products[productsid]} />
      <PurchasePlans
        {...purchasePlans}
        plans={plans}
        labels={labels}
        onPlanClick={(index) => {
          setFilters({ ...filters, plan: index })
          gsap.to(window, {
            scrollTo: {
              y: '#plansForm',
            },
            duration: 0.4,
          })
        }}
      />
    </StyledProductsSelector>
  )
}
