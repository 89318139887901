import React, {useContext} from 'react'
import {
  StyledBannerImageWrapper,
  StyledBannerImage,
  StyledBannerTitle,
  StyledBannerSubtitle,
  StyledBannerButton,
  
} from './style.jsx'
import { StyledGridRow } from '../../commons/Grid'
import { Link } from 'gatsby'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import Carousel from '../Carousel/Carousel'
import { Colors } from '../../commons/Theme'
import { DataContext } from '../../contexts/DataContext'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default ({
  title,
  image,
  gallery,
  subtitle,
  bigText,
  smallText,
  ctaUrl,
  ctaTextContact,
  ctaTextDownload,
  ctaUrlDownload,
  hideTextOnMobile,
  showSubtitleMobile,
  noPadding,
}) => {
  const {i18nLink} = useContext(DataContext)
  return (
    <StyledGridRow noMargin isFull="true">
      <StyledBannerImageWrapper>
        <StyledBannerImage bg={image}/>
        <StyledBannerTitle>{title}</StyledBannerTitle>
        <StyledBannerSubtitle>{subtitle}</StyledBannerSubtitle>
        <StyledBannerButton>
          <Button as="div" noPadding theme="white" to="https://www.3bee.it/documents/3Bee_apiario_aziendale.pdf">
            {ctaTextDownload}{''}
          </Button>
          <Button noPadding theme="white" 
                  handleOnClick={() => {
                    gsap.to(window, {
                      scrollTo: {
                        y: '#contactForm',
                      },
                      duration: 1.4,
                  })}}>
            {ctaTextContact}{''}
          </Button>
        </StyledBannerButton>
      </StyledBannerImageWrapper>
    </StyledGridRow>
  )
}
