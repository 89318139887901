import styled, { css } from 'styled-components'
import { BP, Colors, Rem, Ratio, Sizes, Font, Shadow } from '../../commons/Theme'

export const StyledProductList = styled.div`
    margin: ${Rem(20)} auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    color: ${Colors.black};
    margin-left:${Rem(20)}; 
`

export const StyledProductListContainer = styled.div`
  margin-bottom: ${Rem(100)};
  ${() => css`
    .simplebar-mask {
      z-index: auto;
    }
  `}
`