import React from 'react'
import {
  StyledNumbersToRevers,
  StyledNumbersToReversItems,
  StyledNumbersToReversItem,
  StyledNumbersToReversItemValue,
  StyledNumbersToReversItemLabel,
} from './style.jsx'
import { StyledGridRow } from '../../commons/Grid'

export default ({ items }) => {
  return (
    <StyledGridRow noMargin>
      <StyledNumbersToRevers>
        <StyledNumbersToReversItems>
          {items.map((item, index) => (
            <StyledNumbersToReversItem key={index}>
              <StyledNumbersToReversItemValue isGreen={item.color === 'green'}>
                {item.value}
              </StyledNumbersToReversItemValue>
              <StyledNumbersToReversItemLabel>
                {item.label}
              </StyledNumbersToReversItemLabel>
            </StyledNumbersToReversItem>
          ))}
        </StyledNumbersToReversItems>
      </StyledNumbersToRevers>
    </StyledGridRow>
  )
}
