import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'

export const StyledPartnersCarousel = styled.div`
  background-color: ${Colors.grayLight};
  padding-top: ${Rem(36)};
  padding-bottom: ${Rem(52)};

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(90)};
  }
`

export const StyledPartnersCarouselControlPrev = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  width: ${Rem(52)};
  height: ${Rem(52)};
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: ${Colors.yellow};
  z-index: 99;
  cursor: pointer;

  ${StyledIcon} {
    width: ${Rem(8)};
  }

  @media (${BP.desktop}) {
    display: flex;
    left: ${Rem(-16)};
  }
`
export const StyledPartnersCarouselControlNext = styled(
  StyledPartnersCarouselControlPrev
)`
  left: auto;
  right: 0;

  @media (${BP.desktop}) {
    left: auto;
    right: ${Rem(-16)};
  }
`

export const StyledPartnersCarouselSwiperWrapper = styled.div`
  position: relative;
  flex-wrap: wrap;
  align-items: center !important;

  @media (${BP.ipad}) {
    flex-wrap: nowrap;
  }
`

export const StyledPartnersCarouselSlide = styled.div`
  text-align: center;
  padding: ${Rem(4)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;

  @media (${BP.ipad}) {
    width: auto;
    max-width: none;
  }
`

export const StyledPartnersCarouselItem = styled.div`
  border: 1px solid ${Colors.transparent};
  background-color: ${Colors.transparent};
  transition: all 0.4s ease-out 0s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${Rem(20)};

  &:hover {
    ${Shadow};
    background-color: ${Colors.white};
  }
`

export const StyledPartnersCarouselItemLogo = styled.img`
  filter: grayscale(1);
  transition: filter 0.4s ease-out 0s;
  width: 100%;
  height: auto;

  ${StyledPartnersCarouselItem}:hover & {
    filter: grayscale(0);
  }
`
