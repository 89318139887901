import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledFormSelect } from '../../commons/Form'
import { StyledIcon } from '../Icon/style'

export const StyledFastChoose = styled.div`
  * {
    user-select: none;
  }

  padding-top: ${Rem(35)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(64)};
  }
`

export const StyledFastChooseTitle = styled.div`
  font-size: ${Rem(45)};
  line-height: ${Rem(40)};
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
  }
`

export const StyledFastChooseSubtitle = styled.div`
  font-size: ${Rem(16)};
  line-height: ${Rem(25)};
  margin-bottom: ${Rem(35)};

  @media (${BP.ipad}) {
    margin-bottom: ${Rem(48)};
  }
`

export const StyledFastChooseItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const StyledFastChooseItem = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${Rem(24)};
  padding: ${Rem(24)} ${Rem(16)} ${Rem(88)};
  border-radius: ${Rem(10)};
  overflow: hidden;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1px solid ${Colors.yellow};
    border-radius: ${Rem(10)};
    pointer-events: none;
  }

  @media (${BP.tablet}) {
    width: calc(50% - ${Rem(12)});
    padding: ${Rem(24)} ${Rem(24)} ${Rem(88)};
  }

  ${(props) =>
    props.isActive &&
    css`
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

      &:after {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border: 3px solid ${Colors.yellow};
      }

      ${StyledFastChooseItemCTA} {
        color: ${Colors.white};
        background-color: ${Colors.yellow};
      }
    `}

  ${(props) =>
    props.adoption &&
    css`
      border-radius: 0;
      padding-bottom: ${Rem(25)};
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

      @media (${BP.tablet}) {
        padding-bottom: ${Rem(25)};
        height: auto;
      }

      &:after {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border: 3px solid ${Colors.yellow};
      }

      ${StyledFastChooseItemDetails} {
        margin-bottom: ${Rem(20)};
      }
    `}
`

export const StyledFastChooseItemBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${Rem(24)};

  ${() => css`
    ${StyledFastChooseItemBlock} + & {
      @media (${BP.ipad}) {
        margin-bottom: 0;
      }
    }
  `}

  @media (${BP.ipad}) {
    margin-bottom: ${Rem(28)};
  }
`

export const StyledFastChooseItemExtra = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  color: ${Colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.yellow80};
  font-size: ${Rem(28)};
  line-height: ${Rem(32)};
  padding: 0 ${Rem(78)};
  text-align: center;

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`

export const StyledFastChooseItemExtraTrigger = styled.div`
  position: absolute;
  width: ${Rem(18)};
  height: ${Rem(18)};
  top: ${Rem(16)};
  right: ${Rem(16)};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #bdc3c7;
  color: #bdc3c7;
  border-radius: 50%;
  font-size: ${Rem(14)};
  z-index: 4;
  cursor: pointer;

  ${(props) =>
    props.isClose &&
    css`
      color: ${Colors.white};
      border-color: ${Colors.white};
    `}
`

export const StyledFastChooseItemImage = styled.div`
  width: 100%;
  max-width: ${Rem(80)};
  height: ${Rem(80)};
  background-image: url('${(props) => props.bg}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${Rem(8)};
  overflow: hidden;
  
  @media(${BP.ipad}) {
    max-width: ${Rem(120)};
    height: ${Rem(120)};
  }
`

export const StyledFastChooseItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: calc(100% - ${Rem(80)});
  padding-left: ${Rem(8)};

  @media (${BP.ipad}) {
    max-width: calc(100% - ${Rem(120)});
    padding-left: ${Rem(24)};
  }
`

export const StyledFastChooseItemName = styled.div`
  font-size: ${Rem(25)};
  line-height: ${Rem(34)};
  margin-bottom: ${Rem(16)};

  @media (${BP.ipad}) {
    font-size: ${Rem(28)};
  }
`

export const StyledFastChooseItemDetails = styled.div``

export const StyledFastChooseItemDetail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: ${Rem(16)};
  }
`

export const StyledFastChooseItemDetailIcon = styled.div`
  > img {
    width: ${Rem(20)};
  }
`

export const StyledFastChooseItemDetailText = styled.div`
  font-size: ${Rem(16)};
  line-height: ${Rem(20)};
  font-weight: 300;
  padding-left: ${Rem(8)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(22)};
  }
`

export const StyledFastChooseItemFilters = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    flex-wrap: nowrap;
  }
`

export const StyledFastChooseItemFilter = styled.div`
  &:first-child {
    margin-right: ${Rem(16)};
    margin-bottom: ${Rem(12)};

    @media (${BP.ipad}) {
      margin-bottom: 0;
    }
  }
`

export const StyledFastChooseItemFilterLabel = styled.div`
  font-size: ${Rem(16)};
  margin-bottom: ${Rem(8)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
  }
`

export const StyledFastChooseItemFilterInput = styled.div`
  border: 1px solid #d8d8d8;
  border-radius: ${Rem(10)};
  padding: ${Rem(10)};
  font-size: ${Rem(16)};

  @media (${BP.ipad}) {
    padding: ${Rem(16)};
    font-size: ${Rem(18)};
  }

  ${StyledFormSelect} {
    > select {
      border: none;
      padding: 0 ${Rem(30)} 0 0;
      height: auto;
      letter-spacing: normal;
      font-size: ${Rem(16)};
      font-weight: bold;
      text-transform: none;
      text-overflow: ellipsis;
      background-color: ${Colors.white};

      @media (${BP.ipad}) {
        font-size: ${Rem(18)};
      }
    }

    &:after {
      right: 0;
      background-image: url('/2020/svg/icons/chevron-down-yellow.svg');
    }
  }

  ${StyledFastChooseItemFilter}:last-child & {
    padding: ${Rem(10)} ${Rem(4)};
    @media (${BP.ipad}) {
      padding: ${Rem(13)} ${Rem(6)};
    }
  }
`

export const StyledFastChooseItemFilterCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;

      ${StyledFastChooseItemFilterCounterValue} {
        color: rgb(170, 170, 170);
      }
      ${StyledFastChooseItemFilterCounterButton} {
        background-color: rgb(170, 170, 170);
        pointer-events: none;
      }
    `}
`

export const StyledFastChooseItemFilterCounterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${Rem(24)};
  height: ${Rem(24)};
  font-size: ${Rem(22)};
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  border-radius: 50%;
  cursor: pointer;

  @media (${BP.ipad}) {
    width: ${Rem(28)};
    height: ${Rem(28)};
    font-size: ${Rem(25)};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      background-color: rgb(170, 170, 170);
      pointer-events: none;
    `}
`

export const StyledFastChooseItemFilterCounterValue = styled.div`
  min-width: ${Rem(60)};
  padding: 0 ${Rem(10)};
  text-align: center;
  font-size: ${Rem(18)};
`

export const StyledFastChooseItemFilterCounterMax = styled.div`
  width: 100%;
  margin-bottom: ${Rem(8)};
  font-weight: 300;
  position: relative;
  transform: translateY(${Rem(-6)});
`

export const StyledFastChooseItemPrice = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: ${Rem(24)};
  min-height: ${Rem(34)};

  @media (${BP.ipad}) {
    font-size: ${Rem(30)};
    position: absolute;
    right: ${Rem(20)};
    bottom: ${Rem(90)};
  }
`

export const StyledFastChooseItemPriceOriginal = styled.div`
  font-size: ${Rem(16)};
  color: #979797;
  text-decoration: line-through;
  padding-right: ${Rem(12)};

  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
  }
`

export const StyledFastChooseItemCTA = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${Rem(64)};
  left: 0;
  bottom: 0;
  border-top: 1px solid ${Colors.yellow60};
  color: ${Colors.yellow};
  font-size: ${Rem(20)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
  cursor: pointer;
`

export const StyledFastChooseCTA = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: ${Rem(30)};
`

export const StyledFastChooseCTAButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${Rem(66)};
  color: ${Colors.black};
  background-color: ${Colors.yellow};
  cursor: pointer;
  font-size: ${Rem(20)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
  padding: 0 ${Rem(40)};

  @media (${BP.ipad}) {
    width: auto;
  }

  ${StyledIcon} {
    margin-left: ${Rem(16)};
    margin-right: ${Rem(16)};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      filter: grayscale(1);
      pointer-events: none;
      cursor: default;
    `}
`
