import styled from 'styled-components'

export const StyledMultiSectionLayout = styled.div`
`

export const StyledMultiSectionLayoutSection = styled.div`
  height: 0;
  opacity: 0;
  transition: opacity 1s ease-out;
  overflow: hidden;

  &.show {
    height: auto;
    opacity: 1;
  }
`