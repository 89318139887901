import styled, { css } from 'styled-components'
import { Rem, BP, Ratio, Colors } from '../../commons/Theme'
import { StyledFooterNewsletterInput, StyledFooterNewsletterText } from '../Footer/style.jsx'


export const StyledSignUp = styled.div`
  padding:${Rem(40)};
  display: inline-block;
  align-items: center;
  background-color: #000000;
  z-index:1;
  text-align: center;
  font-size: ${Rem(24)};
  @media (${BP.ipad}) {
    padding-top:${Rem(220)};
    padding-bottom:${Rem(180)};
    margin-left:3%;
    margin-right:3%;
    width: 43%;
  }

  ${StyledFooterNewsletterText}{
    margin-bottom: ${Rem(16)};
    font-size: ${Rem(24)};
    }

  ${StyledFooterNewsletterInput}{
    font-size: ${Rem(24)};
  }
`