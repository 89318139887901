import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledVimeoPlayer } from '../VimeoPlayer/style'
import { StyledIcon } from '../Icon/style'
import { StyledMobileScroller } from '../MobileScroller/style'

export const StyledVideoReviews = styled.div`
  padding-bottom: ${Rem(62)};
`

export const StyledVideoReviewsSwiper = styled.div``

export const StyledVideoReviewsCarousel = styled(StyledMobileScroller)`
  @media (${BP.ipad}) {
    overflow: visible;
  }
`

export const StyledVideoReviewsControl = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  width: ${Rem(52)};
  height: ${Rem(52)};
  top: 50%;
  left: 0;
  transform: translateY(-100%);
  background-color: ${Colors.yellow};
  z-index: 1;
  cursor: pointer;

  ${StyledIcon} {
    width: ${Rem(8)};
  }

  @media (${BP.desktop}) {
    display: flex;
    left: ${Rem(-16)};

    ${(props) =>
      props.next &&
      css`
        left: auto;
        right: ${Rem(-16)};
      `}
  }
`

export const StyledVideoReviewsItem = styled.div`
  min-width: 76%;
  max-width: 76%;

  @media (${BP.ipad}) {
    min-width: ${Rem(586)};
    max-width: ${Rem(586)};
    margin: 0 ${Rem(20)};
  }
`

export const StyledVideoReviewsItemVideo = styled.div`
  position: relative;
  ${Ratio(586, 326)};
  width: 100%;
  margin-bottom: ${Rem(44)};

  @media (${BP.ipad}) {
    transform: scale(0.4);
    transition: transform 0.25s ease-out 0s;
  }

  .swiper-slide-visible & {
    @media (${BP.ipad}) {
      transform: scale(1);
      transform-origin: center center;
    }
  }

  .swiper-slide-visible.swiper-slide-prev & {
    @media (${BP.ipad}) {
      transform: scale(0.4);
      transform-origin: right center;
    }
  }

  .swiper-slide-visible.swiper-slide-next & {
    @media (${BP.ipad}) {
      transform: scale(0.4);
      transform-origin: left center;
    }
  }

  ${StyledVimeoPlayer} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const StyledVideoReviewsItemTitle = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${Rem(-44)};
  font-size: ${Rem(20)};
  letter-spacing: ${Rem(-0.5)};

  @media (${BP.ipad}) {
    text-align: center;
  }
`
