import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledSeasons = styled.div``

export const StyledSeasonsScroller = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (${BP.ipad}) {
    overflow: hidden;
    padding: ${Rem(16)} 0;
    justify-content: space-between;
  }
`

export const StyledSeasonsItem = styled.div`
  margin-left: ${Rem(20)};
  min-width: ${Rem(255)};
  max-width: ${Rem(255)};
  text-align: center;

  @media (${BP.ipad}) {
    margin-left: 0;
  }

  &:last-child {
    margin-top: -1px;

    &:after {
      position: relative;
      content: '';
      display: block;
      width: ${Rem(20)};
      height: 1px;
      left: 100%;

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }
`

export const StyledSeasonsItemImage = styled.div`
  width: ${Rem(124)};
  height: ${Rem(82)};
  margin:0 auto;
  background-image: url('${(props) => props.bg}');
  background-size: contain;
  background-position: center;
`

export const StyledSeasonsItemTitle = styled.h2`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
  padding-bottom: ${Rem(12)};
  color: ${Colors.yellow};
`

export const StyledSeasonsItemDescription = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
`
