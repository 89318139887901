import styled from 'styled-components'
import { BP, Colors, Rem } from '../../commons/Theme'


export const StyledDiscoverHiveTechButtonContainer = styled.div`
  padding-bottom: ${Rem(30)};
`

export const StyledDiscoverHiveTechButton = styled.div`
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  padding: ${Rem(30)};
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  font-size: ${Rem(30)};
  max-width: 90%;

  @media (${BP.tablet}) {
    max-width: 80%;
  }

  @media (${BP.ipad}) {
    max-width: 70%;
  }

  @media (${BP.desktop}) {
    max-width: 70%;
  }
`
