import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledVimeoPlayer } from '../VimeoPlayer/style'

export const StyledTechPreview = styled.div`
  background-image: ${Colors.gradient};
  padding-bottom: ${Rem(16)};

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(90)};
  }
`

export const StyledTechPreviewIntro = styled.div`
  padding-top: ${Rem(24)};
  padding-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(42)};
    padding-bottom: ${Rem(50)};
  }
`

export const StyledTechPreviewTitle = styled.h2`
  font-size: ${Rem(45)};
  font-weight: normal;
  padding-bottom: ${Rem(32)};
  @media (${BP.ipad}) {
    font-size: ${Rem(80)};
  }

  > br {
    @media (${BP.ipad}) {
      display: none;
    }
  }
`

export const StyledTechPreviewSubtitle = styled.p`
  font-size: ${Rem(21)};
  line-height: ${Rem(25)};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    line-height: ${Rem(55)};
  }
`

export const StyledTechPreviewMedia = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${Rem(186)};

  @media (${BP.ipad}) {
    height: ${Rem(490)};
  }
`

export const StyledTechPreviewMediaImage = styled.div`
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
`

export const StyledTechPreviewNav = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: ${Rem(28)} 0;
  border-bottom: 1px solid ${Colors.white60};

  @media (${BP.ipad}) {
    padding: ${Rem(60)} 0;
  }
`

export const StyledTechPreviewNavItem = styled.div`
  position: relative;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.white60};
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: ${Rem(3)};
    left: 0;
    bottom: ${Rem(-30)};
    background-color: ${Colors.yellow};
    opacity: 0;
    transition: opacity 0.25s ease-out 0s;

    @media (${BP.ipad}) {
      bottom: ${Rem(-62)};
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      color: ${Colors.yellow};

      &:after {
        opacity: 1;
      }

      path {
        transition: fill 0.25s ease-out 0s;
        fill: ${Colors.yellow};
      }
    `}
`

export const StyledTechPreviewNavItemLabel = styled.div`
  font-size: ${Rem(13)};
  line-height: ${Rem(24)};
  padding-left: ${Rem(4)};
  transition: color 0.25s ease-out 0s;
`
