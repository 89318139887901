import React, { useEffect, useRef, useState } from 'react'
import {
  StyledComparativeSlider,
  StyledComparativeSwiper,
  StyledComparativeSwiperSlider,
  StyledComparativeSwiperControls,
  StyledComparativeSwiperControlsPrev,
  StyledComparativeSwiperControlsNext,
  StyledComparativeSwiperSlide,
  StyledComparativeSwiperSlideImage,
  StyledComparativeSwiperSlideTitle,
  StyledComparativeSliderText,
  StyledComparativeSwiperSlideProsCons,
  StyledComparativeSwiperSlidePros,
  StyledComparativeSwiperSlideCons,
  StyledComparativeSwiperSlideProsConsBig,
  StyledComparativeSwiperSlideProsConsSmall,
  StyledComparativeSliderForm,
  StyledComparativeSliderFormItem,
} from './style'
import Swiper, { Navigation, Controller } from 'swiper'
import { StyledGridRow } from '../../commons/Grid'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { StyledBigText } from '../../commons/Typography'
import ComparativeForm from '../ComparativeForm/ComparativeForm'

Swiper.use([Navigation, Controller])

const evaluateForm = (data, callback) => {
    const { hives, apiaries, devices, cost } = data

    const cons=new Array(); 
    cons[0] = apiaries >= 1 ? apiaries*540 +  hives*40 : 0
    cons[1] = hives >= 1 ? hives*60 : 0
    cons[2] = hives >= 1 ? hives*70 : 0
    cons[3] = devices >= 1 ? 0 : 0
    cons[4] = devices >= 1 ? 0 : 0

    const pro=new Array(); 
    pro[0] = apiaries >= 1 ? apiaries*378 +  hives*30 : 0
    pro[1] = hives >= 1 ? hives*45 : 0
    pro[2] = hives >= 1 ? 0 : 0
    pro[3] = devices >= 1 ? devices*100*11 : 0
    pro[4] = devices >= 1 ? pro[3]+(cons[2]-pro[2])+(cons[1]-pro[1])+(cons[0]-pro[0]) : 0

  callback({
    pro,
    cons,
  })
}

export default ({ bigText, items }) => {
  const sliderProConsEl = useRef(null)
  const sliderEl = useRef(null)
  const prevArrowEl = useRef(null)
  const nextArrowEl = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [dynamicContents, setDynamicContents] = useState({
    pro: [],
    cons: '',
  })

  useEffect(() => {
    if (sliderEl && sliderProConsEl) {
      const slider = new Swiper(sliderEl.current, {
        allowTouchMove: 'ontouchstart' in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        virtualTranslate: true,
        navigation: {
          prevEl: prevArrowEl.current,
          nextEl: nextArrowEl.current,
        },
        on: {
          slideChange: () => {
            setCurrentSlide(slider.realIndex)
          },
        },
      })
      const sliderProCons = new Swiper(sliderProConsEl.current, {
        allowTouchMove: 'ontouchstart' in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        virtualTranslate: true,
        controller: {
          control: slider,
        },
        slideChange: () => {
          setCurrentSlide(slider.realIndex)
        },
      })

      slider.controller.control = sliderProCons
    }
  }, [sliderEl, sliderProConsEl])

  return (
    <StyledComparativeSlider>
      <StyledGridRow noMargin>
        <StyledBigText dangerouslySetInnerHTML={{ __html: bigText }} />
        <StyledComparativeSliderForm>
          <StyledComparativeSliderFormItem>
            <ComparativeForm
              onValuesChange={(data) =>
                evaluateForm(data, setDynamicContents)
              }
            />
          </StyledComparativeSliderFormItem>
        </StyledComparativeSliderForm>
        <StyledComparativeSwiper ref={sliderEl} className="swiper-container">
          <StyledComparativeSwiperSlider className="swiper-wrapper">
            {items.map((item, index) => (
              <StyledComparativeSwiperSlide
                className="swiper-slide"
                key={index}
              >
                <StyledComparativeSwiperSlideImage src={item.image} />
                <StyledComparativeSwiperSlideTitle>
                  {item.title}
                </StyledComparativeSwiperSlideTitle>
                <StyledComparativeSliderText>
                  {item.text}
                </StyledComparativeSliderText>
              </StyledComparativeSwiperSlide>
            ))}
            <StyledComparativeSwiperControls>
              <StyledComparativeSwiperControlsPrev ref={prevArrowEl}>
                <Icon icon={icons.triangleLeft} color={Colors.yellow} />
              </StyledComparativeSwiperControlsPrev>
              <StyledComparativeSwiperControlsNext ref={nextArrowEl}>
                <Icon icon={icons.triangleRight} color={Colors.yellow} />
              </StyledComparativeSwiperControlsNext>
            </StyledComparativeSwiperControls>
          </StyledComparativeSwiperSlider>
        </StyledComparativeSwiper>
        <StyledComparativeSwiper
          ref={sliderProConsEl}
          className="swiper-container"
        >
          <StyledComparativeSwiperSlider className="swiper-wrapper">
            {items.map((item, index) => (
              <StyledComparativeSwiperSlideProsCons
                className="swiper-slide"
                key={index}
              >
                <StyledComparativeSwiperSlidePros>
                  <StyledComparativeSwiperSlideProsConsBig>
                    {item.pros.big}
                  </StyledComparativeSwiperSlideProsConsBig>
                  <StyledComparativeSwiperSlideProsConsSmall>
                    {dynamicContents.pro[item.formType]} €
                  </StyledComparativeSwiperSlideProsConsSmall>
                </StyledComparativeSwiperSlidePros>
                <StyledComparativeSwiperSlideCons>
                  <StyledComparativeSwiperSlideProsConsBig>
                    {item.cons.big}
                  </StyledComparativeSwiperSlideProsConsBig>
                  <StyledComparativeSwiperSlideProsConsSmall>
                    {dynamicContents.cons[item.formType]} €
                  </StyledComparativeSwiperSlideProsConsSmall>
                </StyledComparativeSwiperSlideCons>
              </StyledComparativeSwiperSlideProsCons>
            ))}
          </StyledComparativeSwiperSlider>
        </StyledComparativeSwiper>
      </StyledGridRow>
    </StyledComparativeSlider>
  )
}



/*</StyledComparativeSliderForm>/</StyledComparativeSliderFormItem>*/