import React, {} from 'react'
import {StyledProductListContainer, StyledProductList} from './style'
import ProductCard from '../ProductCard/ProductCard'
import Scroller from '../Scroller/Scroller'

export default ({
  items,
  }) => {

    return (
      <StyledProductListContainer id="perks">
        <Scroller>
          <StyledProductList>
            {items.map((item, index) => (
              <ProductCard key={index} {...item} />
            ))}
          </StyledProductList>
        </Scroller>
      </StyledProductListContainer>
    )
}
