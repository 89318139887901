import React, { useEffect, useRef, useState } from 'react'
import { StyledMap, StyledMapMap } from './style'
import Map, { rebuildMarkers } from './Map'
import { StyledGridRow } from '../../commons/Grid'

const OpenLayers = ({
  mapID = 'open-layers-map',
  center,
  markers,
  onMapReady,
}) => {
  const map = useRef(null)
  const [mapController, setMapController] = useState(null)
  useEffect(() => {
    const controller = Map({
      target: map.current,
      mapID,
      markers,
      center,
      onMapReady,
    })
    setMapController(controller)
  }, [])

  useEffect(() => {
    if (mapController) {
      rebuildMarkers(mapController, markers)
    }
  }, [mapController, markers.reduce((res, marker) => res + marker.popupID, '')])

  return (
    <StyledGridRow noMargin isFullMobile>
      <StyledMap>
        <StyledMapMap ref={map} />
      </StyledMap>
    </StyledGridRow>
  )
}

export default OpenLayers
