import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'
import { StyledGridRow } from '../../commons/Grid'

export const StyledAppPreview = styled.div`
  position: relative;
  ${StyledGridRow}:last-child {
    position: initial;
  }

  ${(props) =>
    props.hasGradient &&
    css`
      background-image: #ffffff;

      ${StyledGridRow}:last-child {
        position: relative;

        @media (${BP.ipad}) {
          overflow: visible;
          margin-bottom: 0;
          z-index: 2;
        }
      }

      ${StyledAppPreviewViews} {
        @media (${BP.ipad}) {
          min-height: ${Rem(630)};
          margin-left: 50%;
          padding-top: ${Rem(20)};
        }
      }

      ${StyledAppPreviewViewImage} {
        @media (${BP.ipad}) {
          right: auto;
          left: 0;
          transform: none;
        }
      }
    `}

  ${(props) =>
    props.isWhite &&
    css`
      color: ${Colors.black};
      background-color: ${Colors.white};

      ${StyledGridRow}:last-child {
        position: relative;

        @media (${BP.ipad}) {
          z-index: 2;
        }
      }

      ${StyledAppPreviewIntroDescription} {
        @media (${BP.ipad}) {
          position: relative !important;
          top: auto !important;
          right: auto !important;
          width: 50% !important;
          padding-right: ${Rem(40)};
        }
      }

      ${StyledAppPreviewIntroBigText} {
        @media (${BP.ipad}) {
          position: absolute;
          top: auto;
          right: auto;
          left: 50%;
        }
      }

      ${StyledAppPreviewViews} {
        @media (${BP.ipad}) {
          padding-top: ${Rem(140)};
          padding-bottom: ${Rem(220)};
        }
      }

      ${StyledAppPreviewViewContent} {
        color: ${Colors.black};
        @media (${BP.ipad}) {
          color: ${Colors.gray};
        }
      }

      ${StyledIcon} {
        path {
          fill: ${Colors.black} !important;

          @media (${BP.ipad}) {
            fill: ${Colors.gray} !important;
          }
        }
      }

      ${StyledAppPreviewViewImage} {
        @media (${BP.ipad}) {
          width: 50vw;
          min-width: ${Rem(700)};
          max-width: ${Rem(1000)};
          left: 50%;
          transform: none;
        }
      }
    `}
`

export const StyledAppPreviewIntro = styled.div`
  padding-bottom: ${Rem(90)};

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(60)};
  }

  ${(props) =>
    props.noCta &&
    css`
      padding-bottom: ${Rem(20)};

      @media (${BP.ipad}) {
        padding-bottom: ${Rem(20)};
      }
    `}
`

export const StyledAppPreviewIntroTitle = styled.div`
  text-transform: uppercase;
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(4)};
  margin-bottom: ${Rem(24)};
  padding-top: ${Rem(52)};

  @media (${BP.ipad}) {
    font-size: ${Rem(15)};
    letter-spacing: ${Rem(5)};
    padding-top: ${Rem(100)};
  }
`

export const StyledAppPreviewIntroBigText = styled.div`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  margin-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(55)};
  }
`

export const StyledAppPreviewIntroDescription = styled.div`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  margin-bottom: ${Rem(36)};

  @media (${BP.ipad}) {
    width: ${Rem(488)};
    font-size: ${Rem(18)};
    line-height: ${Rem(26)};
    margin-bottom: ${Rem(94)};
  }

  ${StyledAppPreviewIntroTitle} ~ & {
    @media (${BP.ipad}) {
      position: absolute;
      top: ${Rem(160)};
      right: ${Rem(20)};
      width: 62%;
      max-width: ${Rem(692)};
    }
  }
`

export const StyledAppPreviewIntroCTA = styled.div`
  display: inline-block;
  position: absolute;
  bottom: ${Rem(32)};
  left: ${Rem(20)};

  @media (${BP.ipad}) {
    position: relative;
    left: auto;
    bottom: auto;
  }
`

export const StyledAppPreviewViews = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (${BP.ipad}) {
    overflow: hidden;
    flex-direction: column;
    width: ${Rem(488)};
  }
`

export const StyledAppPreviewView = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-basis: 80%;
  text-align: center;
  margin-left: ${Rem(20)};

  @media (${BP.ipad}) {
    margin-left: 0;
    width: 100%;
  }

  &:last-child {
    &:after {
      content: '';
      display: block;
      position: relative;
      width: ${Rem(20)};
      height: 1px;
      left: 100%;
      margin-top: -1px;

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      @media (${BP.ipad}) {
        ${StyledAppPreviewViewContent} {
          color: ${Colors.black};
          background-color: ${Colors.yellow};
        }

        ${StyledIcon} {
          path {
            fill: ${Colors.black} !important;
          }
        }

        ${StyledAppPreviewViewDescription} {
          display: inline-block;
        }

        ${StyledAppPreviewViewImage} {
          opacity: 1;
          pointer-events: all;
        }
      }
    `}
`

export const StyledAppPreviewViewContent = styled.div`
  text-align: left;

  @media (${BP.ipad}) {
    padding: ${Rem(26)} ${Rem(16)};
    border-radius: ${Rem(16)};
    color: ${Colors.black50};
  }

  ${StyledIcon} {
    margin-right: ${Rem(16)};

    @media (${BP.ipad}) {
      width: ${Rem(24)};

      path {
        fill: ${Colors.black50};
      }
    }
  }
`

export const StyledAppPreviewViewTitle = styled.h2`
  font-size: ${Rem(22)};
  line-height: ${Rem(30)};
  margin-bottom: ${Rem(6)};

  @media (${BP.ipad}) {
    font-size: ${Rem(25)};
  }
`

export const StyledAppPreviewViewDescription = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    display: none;
  }
`

export const StyledAppPreviewViewImage = styled.img`
  width: ${Rem(266)};
  margin-bottom: ${Rem(12)};

  @media (${BP.ipad}) {
    width: ${Rem(380)};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(50%);
    opacity: 0;
    pointer-events: none;
  }
`
