import React, { useEffect, useState } from 'react'
import {
  StyledTeamTabs,
  StyledTeamTabsContent,
  StyledTeamTabsContentBlock,
  StyledTeamTabsNav,
  StyledTeamTabsNavItem,
  StyledTeamTabsNavItemDefault,
  StyledTeamTabsNavItemShort,
  StyledTeamTabsTeammate,
  StyledTeamTabsTeammateDescription,
  StyledTeamTabsTeammateImage,
  StyledTeamTabsTeammateInfo,
  StyledTeamTabsTeammateName,
  StyledTeamTabsTeammateRole,
} from './style'
import Intro from '../Intro/Intro'
import { StyledGridRow } from '../../commons/Grid'
import Scroller from '../Scroller/Scroller'

export default ({ intro, tabs, theme }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const [currentTeammate, setCurrentTeammate] = useState(null)

  useEffect(() => {
    setCurrentTeammate(null)
  }, [currentTab])

  return (
    <StyledTeamTabs isWhite={theme !== 'black'}>
      {intro && (
          <Intro {...intro} />
        )}
      <StyledGridRow isFullMobile>
        <StyledTeamTabsNav>
          {tabs.map(({ label }, index) => (
            <StyledTeamTabsNavItem
              key={index}
              isActive={currentTab === index}
              onClick={() => setCurrentTab(index)}
              isWhite={theme !== 'black'}
            >
              <StyledTeamTabsNavItemShort
                dangerouslySetInnerHTML={{ __html: label.short }}
              />
              <StyledTeamTabsNavItemDefault
                dangerouslySetInnerHTML={{ __html: label.default }}
              />
            </StyledTeamTabsNavItem>
          ))}
        </StyledTeamTabsNav>
        <StyledTeamTabsContent>
          {tabs.map(({ content }, index) =>
            currentTab === index ? (
              <Scroller key={index}>
                <StyledTeamTabsContentBlock isActive={currentTab === index}>
                  {content.map((teammate, index) => (
                    <StyledTeamTabsTeammate
                      key={index}
                      showInfo={currentTeammate === index}
                      onMouseOver={() => setCurrentTeammate(index)}
                      onMouseLeave={() => setCurrentTeammate(null)}
                      onClick={() => setCurrentTeammate(index)}
                    >
                      <StyledTeamTabsTeammateImage bg={teammate.image} />
                      <StyledTeamTabsTeammateInfo isWhite={theme !== 'black'}>
                        <StyledTeamTabsTeammateName
                          dangerouslySetInnerHTML={{ __html: teammate.name }}
                        />
                        <StyledTeamTabsTeammateRole
                          dangerouslySetInnerHTML={{ __html: teammate.role }}
                        />
                        <StyledTeamTabsTeammateDescription
                          dangerouslySetInnerHTML={{
                            __html: teammate.description,
                          }}
                        />
                      </StyledTeamTabsTeammateInfo>
                    </StyledTeamTabsTeammate>
                  ))}
                </StyledTeamTabsContentBlock>
              </Scroller>
            ) : null
          )}
        </StyledTeamTabsContent>
      </StyledGridRow>
    </StyledTeamTabs>
  )
}
