import styled, { css } from 'styled-components'
import { Colors, Rem } from '../../commons/Theme'


export const StyledImageBlock = styled.img`
  width: 100%;
  height: auto;
  padding-top: ${({paddingTop}) => Rem(paddingTop)};
  padding-bottom: ${({paddingBottom}) =>Rem(paddingBottom)};
  vertical-align: middle;

  background-color: ${Colors.black};

  ${(props) =>
    props.isWhite &&
    css`
      background-color: ${Colors.white};
    `}
`