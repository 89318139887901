import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledParagraph, StyledBigText } from '../../commons/Typography'
import { StyledButton } from '../Button/style'

export const StyledProductSheet = styled.div`
  color: ${Colors.black};
  background-color: ${Colors.white};
  padding-top: ${Rem(64)};
  padding-bottom: ${Rem(90)};

  ${(props) =>
    props.isModal &&
    css`
      padding-top: ${Rem(20)};
      padding-bottom: ${Rem(20)};
    `}

  ${StyledParagraph} {
    display: none;
    max-width: 50%;
    padding-right: ${Rem(40)};

    @media (${BP.ipad}) {
      display: inline-block;
    }
  }

  ${StyledBigText} {
    @media (${BP.ipad}) {
      position: absolute;
      top: 0;
      left: 50%;
    }
  }
`

export const StyledProductSheetIntro = styled.div`
  position: relative;
`

export const StyledProductSheetProduct = styled.div`
  @media (${BP.ipad}) {
    padding-top: ${Rem(110)};
    columns: 2;
  }
`

export const StyledProductSheetProductInfo = styled.div``

export const StyledProductSheetProductImage = styled.img`
  width: auto;
  height: auto;
  @media (${BP.ipad}) {
    margin-top: ${Rem(-110)};
  }
`

export const StyledProductSheetProductTitle = styled.div`
  font-size: ${Rem(30)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
`

export const StyledProductSheetProductSubTitle = styled.div`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(24)};
`

export const StyledProductSheetProductFeatures = styled.div``

export const StyledProductSheetProductFeature = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledProductSheetProductFeatureText = styled.div`
  font-size: ${Rem(18)};
  line-height: ${Rem(30)};
  padding-left: ${Rem(20)};
`

export const StyledProductSheetProductDetails = styled.div``

export const StyledProductSheetProductDetail = styled.div`
  padding: ${Rem(24)} 0;
  border-bottom: 1px solid ${Colors.gray};
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
`

export const StyledProductSheetProductDetailTitle = styled.div`
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  color: ${Colors.yellow};
  text-transform: uppercase;
`

export const StyledProductSheetProductDetailContent = styled.div`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
`
export const StyledProductSheetProductCTA = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${Rem(20)};
  padding-right: ${Rem(20)};
  background-color: ${Colors.grayLight};
  width: 100%;

  @media (${BP.ipad}) {
    width: auto;
    margin-top: ${Rem(44)};
    margin-left: 50%;
  }

  ${StyledButton} {
    color: ${Colors.black};
    margin-left: 0;
  }
`

export const StyledProductSheetProductPrice = styled.div`
  display: inline-block;
  font-size: ${Rem(20)};
  line-height: ${Rem(20)};
  letter-spacing: ${Rem(0.5)};
  color: ${Colors.yellow};
`
export const StyledProductSheetProductDiscount = styled.div`
  ${Ratio(523, 348)};
  width: ${Rem(100)};
  position: fixed;
  top: calc(5vh - ${Rem(10)});
  right: ${Rem(24)};
  background-image: url('/2020/images/discount.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;

  @media (${BP.ipad}) {
    right: 50%;
    transform: translateX(${Rem(466)});
  }
`

export const StyledProductSheetProductDiscountContent = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: ${Rem(6)};
  left: ${Rem(16)};
  text-transform: uppercase;
  font-size: ${Rem(14)};

  span:last-child {
    position: relative;
    top: ${Rem(-2)};
    font-size: ${Rem(24)};
  }
`
