import React, { useContext, useEffect, useState } from 'react'
import {
  StyledBookModal,
  StyledBookModalImage,
  StyledBookModalImageBlock,
  StyledBookModalTitleBlock,
  StyledBookModalTitle,
  StyledBookModalBigText,
  StyledBookModalRow,
  StyledBookModalFormBlock,
  StyledBookModalFormError,
  StyledBookModalFormSubmit,
  StyledBookModalFormInputBlock,
} from './style'
import { DataContext } from '../../contexts/DataContext'
import {
  StyledForm,
  StyledFormInput,
  StyledFormLabel,
  StyledFormSubmit,
} from '../../commons/Form'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { LayoutContext } from '../../contexts/LayoutContext'

export default ({
  title,
  subtitle,
  image,
  pdfUrl,
  cookieName,
  listId,
  gtmEvent,
}) => {
  const { labels } = useContext(DataContext)
  const [formData, setFormData] = useState({})
  const [errorMsg, setErrorMsg] = useState(null)
  const { setModalContent } = useContext(LayoutContext)

  function setCookie(cname, cvalue, exdays) {
    var d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    var expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }

  useEffect(() => {
    setCookie(cookieName, false, 3)
  }, [])


  return (
    <StyledBookModal>
      <StyledBookModalRow>
        <StyledBookModalImageBlock>
          <StyledBookModalImage src={image} />
        </StyledBookModalImageBlock>

        <StyledBookModalTitleBlock>
          <div>
            <StyledBookModalBigText
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <StyledBookModalTitle>
              {subtitle}
            </StyledBookModalTitle>
          </div>
        </StyledBookModalTitleBlock>
      </StyledBookModalRow>

      <StyledBookModalFormBlock>
        <StyledForm
          Validate
          onSubmit={(event) => {
            event.preventDefault()
            const form_data = new FormData(event.currentTarget)
            fetch('https://api.ipify.org/')
              .then(response => response.text())
              .then(response => {
                form_data.append('g-recaptcha-response', 'token')
                form_data.append('lang', 'IT')

                if (listId) {
                  form_data.append('list_id', listId)
                }

                if (gtmEvent) {
                  window.dataLayer.push({
                    'event': gtmEvent,
                  })
                }

                fetch('https://3bee.link:8989/api/addNewsletter', {
                  method: 'POST',
                  body: form_data
                })
                  .then((response) => {
                    if (response.status === 200) {
                      window.open(pdfUrl, '_blank')
                      setCookie(cookieName, true, 10 * 365)
                      setModalContent(false)
                    } else {
                      setErrorMsg("Errore, riprova o contatta info@3bee.it")
                    }
                  })
                  .catch(function (e) {
                    setErrorMsg("Errore, contattaci a info@3bee.it")
                  })
              }
              )
            event.target.reset()
            return false
          }}
        >
          <StyledBookModalFormInputBlock>
            <StyledFormInput
              id="newsletter-book-email"
              type="email"
              name="email"
              onInput={(e) => {
                setFormData({ ...formData, email: e.currentTarget.value })
              }}
            />
            <StyledFormLabel
              htmlFor="newsletter-book-email"
              isHidden={formData.email && formData.email.length > 0}
            >
              {labels.MAIL_ADDRESS}
            </StyledFormLabel>
          </StyledBookModalFormInputBlock>
          <StyledBookModalFormError>
            {errorMsg}
          </StyledBookModalFormError>
          <StyledBookModalFormSubmit>
            <StyledFormSubmit type="submit">
              {labels.BOOK_MODAL_DOWNLOAD}
              <Icon icon={icons.arrowRight} color={Colors.white} />
            </StyledFormSubmit>
          </StyledBookModalFormSubmit>
        </StyledForm>
      </StyledBookModalFormBlock>
    </StyledBookModal>
  )
}
