import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledCards = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: ${Rem(80)};

  &:last-child {
    margin-bottom: 0;
  }

  @media (${BP.ipad}) {
    min-width: calc((100% / 3) - ${Rem(20)});
    max-width: calc((100% / 3) - ${Rem(20)});
    margin-bottom: 0;
  }
`

export const StyledCardIcon = styled.img`
  margin-bottom: ${Rem(20)};
  transform: scale(0.8);
  transform-origin: 0;

  @media (${BP.ipad}) {
    transform: none;
    margin-bottom: ${Rem(40)};
  }
`

export const StyledCardTitle = styled.h2`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
  margin-bottom: ${Rem(12)};

  @media (${BP.ipad}) {
    margin-bottom: ${Rem(16)};
  }
`

export const StyledCardParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
`
