import React, { useEffect, useRef, useState } from 'react'
import {
  StyledScroller,
  StyledScrollerItem,
  StyledScrollerItems,
} from './style'

import {
  StyledTitle,
  StyledParagraph,
  StyledBigText,
} from '../../commons/Typography'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import { StyledGridRow } from '../../commons/Grid'
import Scroller from '../Scroller/Scroller'

export default ({ title, subtitle, bigText, videos, theme }) => {
  const [currentVideo, setCurrentVideo] = useState(null)
  return (
    <StyledScroller isWhite={theme === 'white'}>
      <StyledGridRow noMargin>
        <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
        {subtitle && (
          <StyledParagraph dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}
        {bigText && (
          <StyledBigText dangerouslySetInnerHTML={{ __html: bigText }} />
        )}
      </StyledGridRow>
      <StyledGridRow isFullMobile noMargin>
        <Scroller desktop>
          <StyledScrollerItems>
            {videos.map((video, index) => (
              <StyledScrollerItem
                key={index}
                onClick={() => setCurrentVideo(index)}
              >
                <VimeoPlayer {...video} autoplay={currentVideo === index} />
              </StyledScrollerItem>
            ))}
          </StyledScrollerItems>
        </Scroller>
      </StyledGridRow>
    </StyledScroller>
  )
}
