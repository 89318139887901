import React, { useState } from 'react'
import Scroller from '../Scroller/Scroller'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import {
  StyledMultiVideoPlayer,
  StyledMultiVideoPlayerMainVideo,
  StyledMultiVideoPlayerItem,
  StyledMultiVideoPlayerItems,
} from './style'

export default ({
  videos,
  id,
}) => {
  const [currentVideo, setCurrentVideo] = useState(videos[0])
  const [autoplay, setAutoplay] = useState(false)
  return (
    <StyledMultiVideoPlayer id={id}>
      <StyledMultiVideoPlayerMainVideo onClick={() => setAutoplay(true)}>
        <VimeoPlayer {...currentVideo} autoplay={autoplay} />
      </StyledMultiVideoPlayerMainVideo>
      
      <Scroller desktop>
        <StyledMultiVideoPlayerItems>
          {videos.map((video, index) => (
            <StyledMultiVideoPlayerItem
              key={index}
              image={videos[index].thumb}
              className={videos[index].videoID == currentVideo.videoID ? 'selected' : null}
              onClick={() => {
                setAutoplay(false)
                setCurrentVideo(videos[index])
              }}>
            </StyledMultiVideoPlayerItem>
          ))}
        </StyledMultiVideoPlayerItems>
      </Scroller>
    </StyledMultiVideoPlayer>
  )
}
