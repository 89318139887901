import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledWhatDoWeDo = styled.div`
  background-color: ${Colors.grayLight};
  padding-top: ${Rem(72)};
  padding-bottom: ${Rem(18)};
  margin-bottom: ${Rem(50)};

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(48)};
    margin-bottom: ${Rem(70)};
  }
`

export const StyledWhatDoWeDoContent = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ${Rem(72)};
  }
`

export const StyledWhatDoWeDoTitle = styled.h2`
  font-size: ${Rem(25)};
  line-height: ${Rem(35)};
  padding-bottom: ${Rem(30)};

  @media (${BP.ipad}) {
    min-width: ${Rem(426)};
    max-width: ${Rem(426)};
    padding-right: ${Rem(60)};
  }
`

export const StyledWhatDoWeDoColumns = styled.div`
  padding-bottom: ${Rem(40)};

  @media (${BP.ipad}) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`

export const StyledWhatDoWeDoColumn = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    flex-direction: column;
    flex-basis: calc(50% - ${Rem(22)});
  }
`

export const StyledWhatDoWeDoParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(24)};
`

export const StyledWhatDoWeDoCards = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const StyledWhatDoWeDoCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-basis: 50%;
  padding-bottom: ${Rem(35)};

  @media (${BP.ipad}) {
    flex-basis: 25%;
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const StyledWhatDoWeDoCardIcon = styled.div`
  width: ${Rem(70)};
  height: ${Rem(66)};
  background-image: url('${(props) => props.bg}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: ${Rem(16)};
  
  @media (${BP.ipad}) {
    margin-bottom: 0;
  }
`

export const StyledWhatDoWeDoCardInfo = styled.div`
  text-align: center;

  @media (${BP.ipad}) {
    text-align: left;
    padding-left: ${Rem(8)};
  }
`

export const StyledWhatDoWeDoCardText = styled.div`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
`

export const StyledWhatDoWeDoCardValue = styled.div`
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
`
