import React, { useEffect, useContext, useRef, useState } from 'react'
import {
  StyledVimeoPlayer,
  StyledVimeoPlayerThumb,
  StyledVimeoPlayerVideo,
} from './style.jsx'
import Player from '@vimeo/player'
import {
  StyledVimeoPlayerLoading,
  StyledVimeoPlayerVideoPlayIcon,
} from './style'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { DataContext } from '../../contexts/DataContext'

export default ({
  videoID,
  thumb,
  autoplay,
  playsInLine = false,
  showIcon,
  controls = true,
  videoIconColor = 'white',
}) => {
  const playerEl = useRef()
  const [hasThumb, setHasThumb] = useState(true)

  const { languages } = useContext(DataContext)
  const currentLanguage = languages.filter( (language) => language.isCurrent)
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : null

  useEffect(() => {
    if (autoplay && videoID) {
      setHasThumb(false)
      const player = new Player(playerEl.current, {
        id: videoID,
        responsive: true,
        autoplay,
        volume: 0.4,
        playsinline: playsInLine,
        muted: false,
        controls: true,
        autopause: true,
      })
      player.on('loaded', () => {
        setHasThumb(false)
      })
      if (currentLanguageCode) {
        // console.log(`loading ${currentLanguageCode} subtitles...`)
        player.enableTextTrack(currentLanguageCode).then(function(track) {
          // console.log(`${currentLanguageCode} subtitles loaded.`)
        }).catch(function(error) {
          console.log('Subtitles not loaded:', error);
        })
      }
      
    }
  }, [autoplay])

  return (
    <StyledVimeoPlayer>
      {typeof thumb === 'string' ? (
        <StyledVimeoPlayerThumb src={thumb} isVisible={hasThumb || !autoplay} />
      ) : (
        <StyledVimeoPlayerThumb as="picture" isVisible={hasThumb || !autoplay}>
          <source media="(max-width:1023px)" srcSet={thumb.mobile} />
          <img src={thumb.desktop} />
        </StyledVimeoPlayerThumb>
      )}
      {showIcon !== false && videoID && (
        <StyledVimeoPlayerVideoPlayIcon showIcon={hasThumb || !autoplay}>
          <Icon icon={icons.play} color={Colors[videoIconColor]} />
        </StyledVimeoPlayerVideoPlayIcon>
      )}
      {videoID && <StyledVimeoPlayerLoading>
        <img src="/2020/images/loading.gif" />
      </StyledVimeoPlayerLoading>}
      {autoplay && videoID && <StyledVimeoPlayerVideo ref={playerEl} />}
    </StyledVimeoPlayer>
  )
}
