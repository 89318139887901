import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'
import { StyledButton } from '../Button/style'
import { StyledBigText, StyledParagraph } from '../../commons/Typography'

export const StyledPurchasePlans = styled.div`
  padding-top: ${Rem(50)};
  padding-bottom: ${Rem(60)};
  color: ${Colors.black};
  background-color: ${Colors.white};
  
  ${(props) =>
    props.isBlack &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.black};

      ${StyledPurchaseItem} {
        background-color: ${Colors.transparent};
      }

      ${StyledPurchaseItemPriceDevices} {
        color: ${Colors.white50};
      }
    `}

  @media (${BP.ipad}) {
    padding-top: ${Rem(72)};
    padding-bottom: ${Rem(40)};
    padding-bottom: ${Rem(40)};
  }
`

export const StyledPurchaseIntro = styled.div`
  position: relative;

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(40)};
    ${StyledParagraph} {
      max-width: 50%;
      padding-right: ${Rem(70)};
    }

    ${StyledBigText} {
      position: absolute;
      width: 50%;
      top: 0;
      left: 50%;
    }
  }
`

export const StyledPurchaseItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const StyledPurchaseItem = styled.div`
  position: relative;
  background-color: ${Colors.grayLight};
  width: 100%;
  text-align: center;
  font-weight: normal;
  padding: ${Rem(20)} ${Rem(4)} ${Rem(160)};
  margin-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    width: ${Rem(388)};
    padding: ${Rem(62)} ${Rem(16)} ${Rem(180)};
  }

  ${(props) =>
    props.highlight &&
    css`
      ${Shadow};
      background-color: ${Colors.transparent};
      padding-top: ${Rem(56)};

      ${StyledPurchaseItemTitle}:after {
        display: inline-block;
        content: '★';
        color: ${Colors.yellow};
        font-size: ${Rem(28)};
        padding-left: ${Rem(6)};

        @media (${BP.ipad}) {
          font-size: ${Rem(34)};
        }
      }

      ${StyledPurchaseItemPriceValue} {
        color: ${Colors.yellow};
      }
    `}
`

export const StyledPurchaseItemPreferred = styled.span`
  position: absolute;
  top: ${Rem(12)};
  left: 50%;
  transform: translateX(-50%);
  font-size: ${Rem(14)};
  font-weight: bold;
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(4)};
  text-transform: uppercase;
  color: ${Colors.yellow};

  @media (${BP.ipad}) {
    top: ${Rem(22)};
  }
`

export const StyledPurchaseItemTitle = styled.h2`
  font-size: ${Rem(30)};
  line-height: ${Rem(30)};
  font-weight: bold;
  padding-bottom: ${Rem(16)};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    padding-bottom: ${Rem(22)};
  }
`

export const StyledPurchaseItemPrice = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${Rem(29)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(42)};
  }
`

export const StyledPurchaseItemPriceValue = styled.span`
  font-weight: bold;
`

export const StyledPurchaseItemPriceDevices = styled.span`
  font-size: ${Rem(25)};
  font-weight: lighter;
  color: ${Colors.black50};
  vertical-align: middle;
`

export const StyledPurchaseItemDetails = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(42)};
  font-weight: bold;
`

export const StyledPurchaseItemFeatures = styled.div`
  @media (${BP.ipad}) {
    text-align: left;
  }
`

export const StyledPurchaseItemFeature = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: ${Rem(22)};

  ${StyledIcon} {
    display: none;
    width: ${Rem(34)};
    margin-right: ${Rem(24)};

    @media (${BP.ipad}) {
      display: inline-block;
    }
  }
`

export const StyledPurchaseItemFeatureText = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  font-weight: lighter;
`

export const StyledPurchaseItemCTA = styled.div`
  position: absolute;
  left: 50%;
  bottom: ${Rem(40)};
  transform: translateX(-50%);

  @media (${BP.ipad}) {
    bottom: ${Rem(62)};
  }

  ${StyledButton} {
    min-width: ${Rem(240)};
  }
`
