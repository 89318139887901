import React from 'react'
import {
  StyledMakeDifference,
  StyledMakeDifferenceIntro,
  StyledMakeDifferenceIntroTitle,
  StyledMakeDifferenceIntroSubTitle,
  StyledMakeDifferenceGrid,
  StyledMakeDifferenceGridTitle,
  StyledMakeDifferenceGridSubTitle,
  StyledMakeDifferenceGridItems,
  StyledMakeDifferenceGridItem,
  StyledMakeDifferenceGridItemImage,
  StyledMakeDifferenceGridItemTitle,
  StyledMakeDifferenceGridItemParagraph,
  StyledMakeDifferenceGridItemCta,
} from './style.jsx'
import { StyledGridRow } from '../../commons/Grid'
import { Link } from 'gatsby'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'

export default ({ title, subtitle, grid }) => {
  return (
    <StyledMakeDifference>
      <StyledGridRow noMargin>
        <StyledMakeDifferenceIntro>
          <StyledMakeDifferenceIntroTitle
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <StyledMakeDifferenceIntroSubTitle
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </StyledMakeDifferenceIntro>
      </StyledGridRow>
      <StyledGridRow isFull>
        <StyledMakeDifferenceGrid>
          <StyledGridRow noMargin>
            <StyledMakeDifferenceGridTitle
              dangerouslySetInnerHTML={{ __html: grid.title }}
            />
            <StyledMakeDifferenceGridSubTitle
              dangerouslySetInnerHTML={{ __html: grid.subtitle }}
            />
            <StyledMakeDifferenceGridItems>
              {grid.items.map((item, index) => (
                <StyledMakeDifferenceGridItem key={index}>
                  <StyledMakeDifferenceGridItemImage bg={item.image} />
                  <StyledMakeDifferenceGridItemTitle>
                    {item.title}
                  </StyledMakeDifferenceGridItemTitle>
                  <StyledMakeDifferenceGridItemParagraph>
                    {item.paragraph}
                  </StyledMakeDifferenceGridItemParagraph>
                  <StyledMakeDifferenceGridItemCta>
                    <Button as="div" to={item.cta.url}>
                      {item.cta.label}{' '}
                      <Icon icon={icons.arrowRight} color={Colors.black} />
                    </Button>
                  </StyledMakeDifferenceGridItemCta>
                </StyledMakeDifferenceGridItem>
              ))}
            </StyledMakeDifferenceGridItems>
          </StyledGridRow>
        </StyledMakeDifferenceGrid>
      </StyledGridRow>
    </StyledMakeDifference>
  )
}
