import React, { useContext, useEffect, useState, useRef } from 'react'
import {
  StyledBeekeepers,
  StyledTextBeekeepersTitle,
  StyledContainerBeekeepers,
  StyledLogoBrand,
} from './style'
import Intro from '../Intro/Intro'
import PlantsNav from '../PlantsNav/PlantsNav'
import Hero from '../Hero/Hero'
import useApi from '../../hooks/useApi'
import BeekeepersList from './BeekeepersList'
import { StyledGridRow } from '../../commons/Grid'
import { LayoutContext } from '../../contexts/LayoutContext'
import ApiErrorForm from '../ApiErrorForm/ApiErrorForm'
import Loader from '../Loader/Loader'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default ({ themeColor, intro, labels }) => {
  const { getPlants, getBeekeepers, checkRedeemCode, getAdoptionPlans } = useApi()
  const [currentPlant, setCurrentPlant] = useState(null)
  const [plants, setPlants] = useState([])
  const [beekeepers, setBeekeepers] = useState([])
  const [isLoadingPlants, setIsLoadingPlants] = useState(false)
  const [isLoadingBeekeepers, setIsLoadingBeekeepers] = useState(false)
  const [isMissingBeekeepers, setIsMissingBeekeepers] = useState(false)
  const { setModalContent } = useContext(LayoutContext)
  const [urlParams, setUrlParams] = useState(``)
  const [coupon, setCoupon] = useState(``)
  const [rare, setRare] = useState(false)
  const [special, setSpecial] = useState(false)
  const [honey_id, setHoney_id] = useState(1)
  const [honey_id_first, setHoney_id_first] = useState(4)
  const [themeColorOverride, setThemeColorOverride] = useState({})
  const [primeurLogo, setPrimeurLogo] = useState(false)
  const planRef = useRef(null)
  
  const scrollTop = () => {
    gsap.to(window, {
      scrollTo: {
        y: 0,
      },
      duration: 1.0,})
  }

  const executeScroll = () => scrollTop()

function shufflePlants(filteredPlants) {
  return filteredPlants.sort(() => .5 - Math.random() )
}

  useEffect(() => {
    executeScroll()
    const queryString = window.location.search
    const _urlParams = new URLSearchParams(queryString)
    const _coupon = _urlParams.get('coupon')
    const _rare = _urlParams.get('rare')
    //const _special = _urlParams.get('special')
    const _honey_id_first = _urlParams.get('honey_id')
    
    if (_urlParams) {
      setUrlParams(_urlParams)
    }

    if (_coupon) {
      setCoupon(_coupon)
      checkRedeemCode(_coupon).then((res) => {
        if (res && res.error) {
          console.log('err')
        } else {
          if (res) {
            if (res.purchaser==="PRIMEUR GROUP")
              setPrimeurLogo(true);
          } 
        }
      })
    }

    setIsLoadingPlants(true)

    

    Promise.all([getPlants(_coupon), getAdoptionPlans()]).then((results) => {
      const res = results[0]
      const plans = results[1]?.data
      if (res.error) {
        setModalContent(<ApiErrorForm message={res.message} />)
        setIsLoadingPlants(false)
      } else {
        let filteredPlants = res.filter((x) => x.av_quantity>0.5)
        filteredPlants=shufflePlants(filteredPlants)
        let _honey_id = filteredPlants[0].id
        filteredPlants = filteredPlants.filter(plant => 
          plans.filter(plan => plan.id != 18 && plan.honey_ids.includes(plant.id)).length > 0
        )
        setPlants(filteredPlants) 
        if (res){
          if ((_rare) & (filteredPlants.find((p) => p.rare === true))) {
            {
              setRare(_rare === 'true')
            }
          }
          /*if (res.find((p) => p.special === true)) {
            if (_special) {
              setSpecial(_special === 'false')  //!! FORZO SPECIAL A FALSE
            }
          }*/
          if (filteredPlants.find((p) => p.id == _honey_id_first)) {
            if (_honey_id_first) {
              setHoney_id_first(_honey_id_first)
              _honey_id = _honey_id_first
            }
          }
        }
       // let _id=setHoneyFilters(res, _rare, _special, _honey_id_first)
        setCurrentPlant(filteredPlants.find((p) => p.id == _honey_id))
        setIsLoadingPlants(false)
      }
    })
  }, [])

  // Get parameter from urls.
  useEffect(() => {
    executeScroll()
    if (currentPlant) {
      setIsLoadingBeekeepers(true)
      setHoney_id(currentPlant.id)
      //setSpecial(currentPlant.special)
      //setRare(currentPlant.rare)
      let _urlParams = ''
      if (coupon) {
        _urlParams = 'coupon=' + coupon + '&'
      }
      if (honey_id) {
        _urlParams =
          _urlParams +
          'honey_id=' +
          currentPlant.id +
          '&special=' +
          currentPlant.special +
          '&rare=' +
          currentPlant.rare
      }
      setUrlParams(_urlParams)
      getBeekeepers(currentPlant.id, coupon).then((res) => {
        if (res.error) {
          setModalContent(<ApiErrorForm message={res.message} />)
        } else {
          setBeekeepers(res)
          if (res.length === 0) {
            setIsMissingBeekeepers(true)
          } else {
            setIsMissingBeekeepers(false)
          }
        }
        setIsLoadingBeekeepers(false)
      })
    }
  }, [currentPlant])

  return (
    <StyledBeekeepers ref={planRef}>
      {currentPlant && (
        <>
          {primeurLogo && (<StyledLogoBrand src="/2020/images/primeur/logowhite.png"></StyledLogoBrand>)}
          <PlantsNav
            rareFilterOn={rare}
            specialFilter={special}
            honey_id_first={honey_id_first}
            plants={plants.filter((x) => x.av_quantity>0)}
            handleClick={(id) => {
              setCurrentPlant(plants.find((x) => x.id === id))
            }}
          />
          <div id="honeyDescription"></div>

          <Hero
            title={currentPlant.name}
            subtitle={currentPlant.description}
            ctaText={labels.CTA_TEXT_BIODIVERSITY}
            themeColor={themeColorOverride}
            image={{
              mobile: currentPlant.phone_image,
              desktop: currentPlant.web_image,
            }}
            hasScroller={true}
          />
          {beekeepers.length > 0 && (
            <StyledTextBeekeepersTitle id="beekeepersDescription">
              {labels.BEEKEEPERS_CHOOSE}{' '}
              <span className="color-yellow">{currentPlant.name}</span>
            </StyledTextBeekeepersTitle>
          )}

          <StyledContainerBeekeepers>
            {
              beekeepers.length > 0 ? (
                <BeekeepersList
                  isLoading={isLoadingBeekeepers}
                  items={beekeepers}
                  urlParams={urlParams}
                />
              ) : (
                <></>
              ) /*<Loader isVisibleBekeepers={isLoadingBeekeepers} />*/
            }

            {isMissingBeekeepers && (
              <ApiErrorForm
                title={`${labels.BEEKEEPERS_NOT_FOUND} ${currentPlant.name}`}
                paragraph={labels.BEEKEEPERS_NOT_FOUND_PARAGRAPH}
                message={labels.BEEKEEPERS_NOT_FOUND}
                isFull
              />
            )}
          </StyledContainerBeekeepers>
        </>
      )}
      <Loader isVisible={isLoadingPlants} />
    </StyledBeekeepers>
  )
}
