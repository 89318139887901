import React from 'react'
import {
  StyledWhatDoWeDo,
  StyledWhatDoWeDoCard,
  StyledWhatDoWeDoCardIcon,
  StyledWhatDoWeDoCardInfo,
  StyledWhatDoWeDoCards,
  StyledWhatDoWeDoCardText,
  StyledWhatDoWeDoCardValue,
  StyledWhatDoWeDoColumn,
  StyledWhatDoWeDoColumns,
  StyledWhatDoWeDoContent,
  StyledWhatDoWeDoParagraph,
  StyledWhatDoWeDoTitle,
} from './style'
import { StyledGridRow } from '../../commons/Grid'
import Intro from '../Intro/Intro'
export default ({ intro, title, columns, cards }) => {
  return (
    <StyledWhatDoWeDo>
      <StyledGridRow noMargin>
        <Intro {...intro} noPadding />
        <StyledWhatDoWeDoContent>
          <StyledWhatDoWeDoTitle dangerouslySetInnerHTML={{ __html: title }} />
          <StyledWhatDoWeDoColumns>
            {columns.map((column, index) => (
              <StyledWhatDoWeDoColumn key={index}>
                {column.paragraphs.map((paragraph, index) => (
                  <StyledWhatDoWeDoParagraph
                    key={index}
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  />
                ))}
              </StyledWhatDoWeDoColumn>
            ))}
          </StyledWhatDoWeDoColumns>
        </StyledWhatDoWeDoContent>
        <StyledWhatDoWeDoCards>
          {cards.map((card, index) => (
            <StyledWhatDoWeDoCard key={index}>
              <StyledWhatDoWeDoCardIcon bg={card.icon} />
              <StyledWhatDoWeDoCardInfo>
                <StyledWhatDoWeDoCardText>{card.text}</StyledWhatDoWeDoCardText>
                <StyledWhatDoWeDoCardValue>
                  {card.value}
                </StyledWhatDoWeDoCardValue>
              </StyledWhatDoWeDoCardInfo>
            </StyledWhatDoWeDoCard>
          ))}
        </StyledWhatDoWeDoCards>
      </StyledGridRow>
    </StyledWhatDoWeDo>
  )
}
