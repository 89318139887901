import React from 'react'
import {
  StyledBanner,
  StyledBannerImage,
  StyledBannerContent,
  StyledBannerText,
  StyledBannerCta,
} from './style.jsx'
import { StyledGridRow } from '../../commons/Grid'
import { Link } from 'gatsby'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { StyledBannerAuthor } from './style'
export default ({ image, text, author, cta }) => {
  return (
    <StyledGridRow isFull>
      <StyledBanner>
        <StyledBannerImage bg={image} />
        <StyledBannerContent>
          <StyledBannerText dangerouslySetInnerHTML={{ __html: text }} />
          <StyledBannerAuthor dangerouslySetInnerHTML={{ __html: author }} />
          {cta && (
            <StyledBannerCta>
              <Button as="div" theme="transparent" to={cta.url}>
                {cta.label}
                <Icon icon={icons.arrowRight} color={Colors.yellow} />
              </Button>
            </StyledBannerCta>
          )}
        </StyledBannerContent>
      </StyledBanner>
    </StyledGridRow>
  )
}
