import React, { useEffect, useRef, useState } from 'react'
import {
  StyledBusinessSlider,
  StyledBusinessSwiper,
  StyledBusinessSwiperSlider,
  StyledBusinessSwiperControls,
  StyledBusinessSwiperControlPrev,
  StyledBusinessSwiperControlNext,
} from './style.jsx'

import Swiper, { Navigation } from 'swiper'
import { StyledGridRow } from '../../commons/Grid'
import Slide from './Slide'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'

Swiper.use([Navigation])

export default ({ items }) => {
  const sliderEl = useRef()
  const prevArrowEl = useRef()
  const nextArrowEl = useRef()
  useEffect(() => {
    new Swiper(sliderEl.current, {
      allowTouchMove: 'ontouchstart' in window,
      observer: true,
      observeParents: true,
      autoHeight: true,
      navigation: {
        prevEl: prevArrowEl.current,
        nextEl: nextArrowEl.current,
      },
    })
  }, [sliderEl])

  return (
    <StyledGridRow isFull noMargin>
      <StyledBusinessSlider>
        <StyledBusinessSwiper ref={sliderEl} className="swiper-container">
          <StyledBusinessSwiperSlider className="swiper-wrapper">
            {items.map((item, index) => (
              <Slide {...item} count={index + 1} key={index} />
            ))}
          </StyledBusinessSwiperSlider>
          <StyledBusinessSwiperControls>
            <StyledBusinessSwiperControlPrev ref={prevArrowEl}>
              <Icon icon={icons.chevronLeft} color={Colors.white} />
            </StyledBusinessSwiperControlPrev>
            <StyledBusinessSwiperControlNext ref={nextArrowEl}>
              <Icon icon={icons.chevronRight} color={Colors.white} />
            </StyledBusinessSwiperControlNext>
          </StyledBusinessSwiperControls>
        </StyledBusinessSwiper>
      </StyledBusinessSlider>
    </StyledGridRow>
  )
}
