import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledButton } from '../Button/style'
import { StyledIcon } from '../Icon/style'
import { StyledGridRow } from '../../commons/Grid'
import { StyledVimeoPlayerThumb } from '../VimeoPlayer/style'

export const StyledVideoAndText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
  ${StyledButton} {
    margin: 0;
  }
  
  ${StyledGridRow} {
      ${StyledGridRow}{
        @media(${BP.ipad}) {
          margin: 0;
          padding: 0;
        }
      }
  }

  ${(props) =>
    props.isWhite &&
    css`
      color: ${Colors.black};
      background-color: ${Colors.white};
    `}
  
  ${(props) =>
    props.upside &&
    css`
      flex-direction: column-reverse;
    `}

  @media (${BP.ipad}) {
    display: block;

    ${(props) =>
      props.inverted &&
      css`
        ${StyledVideoAndTextMedia} {
          left: 50%;
        }

        ${StyledVideoAndTextContent} {
          margin-left: 0;
          padding-left: 0;
          padding-right: ${Rem(110)};
        }
      `}
  }
`

export const StyledVideoAndTextMedia = styled.div`
  position: relative;
  ${Ratio(1, 1)};
  width: 100%;
  max-height: ${Rem(375)};
  overflow: hidden;

  @media (${BP.ipad}) {
    position: absolute;
    width: 50%;
    height: 100%;
    max-height: none;
  }

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  ${StyledVimeoPlayerThumb} {
    width: auto;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const StyledVideoAndTextContent = styled.div`
  position: relative;
  padding-top: ${Rem(20)};
  padding-bottom: ${Rem(52)};

  @media (${BP.ipad}) {
    width: 50%;
    margin-left: 50%;
    padding-left: ${Rem(110)};
    padding-top: ${Rem(72)};
    padding-bottom: ${Rem(72)};
    min-height: ${Rem(720)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`

export const StyledVideoAndTextTitle = styled.h2`
  font-size: ${Rem(20)};
  line-height: ${Rem(30)};
  padding-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    font-size: ${Rem(25)};
    padding-bottom: ${Rem(48)};
  }

  &:before {
    display: inline-block;
    content: '';
    width: ${Rem(50)};
    height: 1px;
    background-color: ${Colors.yellow};
    vertical-align: middle;
    margin-right: ${Rem(12)};

    @media (${BP.ipad}) {
      width: ${Rem(70)};
      margin-right: ${Rem(20)};
    }
  }
`

export const StyledVideoAndTextSubtitle = styled.h3`
  font-size: ${Rem(25)};
  line-height: ${Rem(35)};
  padding-bottom: ${Rem(22)};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    line-height: ${Rem(55)};
    padding-bottom: ${Rem(32)};
  }
`

export const StyledVideoAndTextParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(44)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(30)};
    padding-bottom: ${Rem(60)};
  }
`

export const StyledVideoAndTextCTA = styled.div`
  width: 100%;
`

export const StyledVideoAndTextIcon = styled.div`
  ${Ratio(75, 34)};
  width: 100%;
  background-image: url('${(props) => props.bg}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: ${Rem(24)};
`

export const StyledVideoAndTextItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  @media (${BP.ipad}) {
    justify-content: space-between;
  }
`

export const StyledVideoAndTextItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: ${Rem(126)};
  max-width: ${Rem(126)};
  padding: 0 ${Rem(20)};

  @media (${BP.ipad}) {
    justify-content: space-between;
    min-width: 25%;
    max-width: 25%;
    padding: 0;
  }

  ${StyledIcon} {
    width: ${Rem(80)};
  }
`

export const StyledVideoAndTextItemText = styled.span`
  display: inline-block;
  font-size: ${Rem(18)};
  line-height: ${Rem(25)};
  opacity: 0.6;
  text-align: center;
`
