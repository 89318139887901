import React, { useEffect, useRef } from 'react'
import {
  StyledCarousel,
  StyledCarouselSlider,
  StyledCarouselSlide,
  StyledCarouselThumbs,
  StyledCarouselThumb,
} from './style.jsx'
import Swiper, { Controller, Thumbs, Mousewheel } from 'swiper'

Swiper.use([Thumbs, Mousewheel])

export default ({ items, withThumbs }) => {
  const sliderEl = useRef(null)
  const sliderThumbsEl = useRef(null)

  useEffect(() => {
    if (sliderEl && sliderThumbsEl) {
      const sliderThumbs = new Swiper(sliderThumbsEl.current, {
        allowTouchMove: true,
        observer: true,
        observeParents: true,
        autoHeight: true,
        slidesPerView: 'auto',
        spaceBetween: 24,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        mousewheel: true,
      })

      new Swiper(sliderEl.current, {
        allowTouchMove: 'ontouchstart' in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        thumbs: {
          swiper: sliderThumbs,
        },
        controller: {
          control: sliderThumbs,
        },
      })
    }
  }, [sliderEl, sliderThumbsEl])

  return (
    <StyledCarousel>
      <StyledCarouselSlider ref={sliderEl} className="swiper-container">
        <div className="swiper-wrapper">
          {items.map((item, index) => (
            <StyledCarouselSlide
              className="swiper-slide"
              key={index}
              bg={item}
            />
          ))}
        </div>
      </StyledCarouselSlider>
      {withThumbs && (
        <StyledCarouselThumbs ref={sliderThumbsEl} className="swiper-container">
          <div className="swiper-wrapper">
            {items.map((item, index) => (
              <StyledCarouselThumb
                className="swiper-slide"
                key={index}
                bg={item}
              />
            ))}
          </div>
        </StyledCarouselThumbs>
      )}
    </StyledCarousel>
  )
}
