import React, {useContext} from 'react'
import {
  StyledUsersMap,
  StyledUsersMapContent,
  StyledUsersMapContentCTA,
  StyledUsersMapImage,
} from './style'
import { Link } from 'gatsby'
import Icon, { icons } from '../Icon/Icon'
import Button from '../Button/Button'
import { StyledGridRow } from '../../commons/Grid'
import {
  StyledBigText,
  StyledParagraph,
  StyledTitle,
} from '../../commons/Typography'
import { DataContext } from '../../contexts/DataContext'

export default ({ title, bigText, description, cta, image }) => {
  const { i18nLink } = useContext(DataContext)
  return (
    <StyledUsersMap>
      <StyledGridRow noMargin>
        <StyledUsersMapContent>
          <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
          <StyledBigText dangerouslySetInnerHTML={{ __html: bigText }} />
          <StyledParagraph dangerouslySetInnerHTML={{ __html: description }} />
          <StyledUsersMapContentCTA>
            <Button as="div" noPadding theme="transparent" to={cta.url ? i18nLink(`${cta.url}`) : null}>
              {cta.label} <Icon icon={icons.arrowRight} />
            </Button>
          </StyledUsersMapContentCTA>
        </StyledUsersMapContent>
      </StyledGridRow>
      <StyledUsersMapImage bg={image} />
    </StyledUsersMap>
  )
}
