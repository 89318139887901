import React, { useContext, useEffect, useRef, useState } from 'react'


import {
  StyledPlantsFilter,
  StyledPlantsFilterLabel,
  StyledPlantsItem,
  StyledPlantsItemContainer,
  StyledPlantsItemIcon,
  StyledPlantsItemName,
  StyledPlantsItemRarity,
  StyledPlantsItemSoldOut,
  StyledPlantsItemSoldOutContainer,
  StyledPlantsItems,
  StyledPlantsItemsSwiper,
  StyledPlantsNav,
  StyledPlantsNavControl,
  StyledFloatingBee,
} from './style'
import { DataContext } from '../../contexts/DataContext'
import Swiper, { Navigation } from 'swiper'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { StyledGridRow } from '../../commons/Grid'
import { StyledFormToggle } from '../../commons/Form'

Swiper.use([Navigation])
export default ({ plants, handleClick, rareFilterOn=false, specialFilter=false, honey_id_first=3}) => {
  const [currentItem, setCurrentItem] = useState(plants[0].id)
  const rarePlants = plants.filter((x) => x.rare)
  const specialPlants = plants.filter((x) => x.special)
  const [hasFilter, setHasFilter] = useState()
  const [rareFlagOn, setRareFlagOn] = useState(rareFilterOn)
  const [specialFlagOn, setSpecialFlagOn] = useState(specialFilter)
  const [isVisible, setIsVisible] = useState(true)
  const [items, setItems] = useState([])
  const { labels } = useContext(DataContext)
  const sliderEl = useRef()
  const prevArrowEl = useRef()
  const nextArrowEl = useRef()

  useEffect(()=>{
    if (rareFilterOn){setHasFilter('rarity')} else
    if (specialFilter){setHasFilter('special')} else
    {setHasFilter('default')}
  },[])

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        let st = window.pageYOffset || document.documentElement.scrollTop
        if (st>40){
          setIsVisible(false);}
        else
          setIsVisible(true);
      },
    )
  }, [])

  useEffect(() => {
    new Swiper(sliderEl.current, {
      allowTouchMove: true, //'ontouchstart' in window,
      observer: true,
      observeParents: true,
      autoHeight: true,
      slidesPerView: 'auto',
      freeMode: true,
      navigation: {
        prevEl: prevArrowEl.current,
        nextEl: nextArrowEl.current,
      },
    })
  }, [sliderEl])

  useEffect(() => {
    if(hasFilter!=null){
      if (hasFilter === 'rarity'){
        const firstPlants=rarePlants.find(p => p.id==honey_id_first)
        const plantsSelected = firstPlants ? firstPlants.id : rarePlants[0].id
        setRareFlagOn(true)
        setSpecialFlagOn(false)
        setItems(rarePlants)
        setCurrentItem(plantsSelected )
        handleClick(plantsSelected )
      } else if (hasFilter === 'special'){
        const firstPlants=specialPlants.find(p => p.id==honey_id_first)
        const plantsSelected = firstPlants ? firstPlants.id : specialPlants[4].id
        setRareFlagOn(false)
        setSpecialFlagOn(true)
        setItems(specialPlants)
        setCurrentItem(plantsSelected)
        handleClick(plantsSelected)
      } else{
        const firstPlants=plants.find(p => p.id==honey_id_first)
        const plantsSelected = firstPlants ? firstPlants.id : plants[0].id
        setItems(plants)
        setCurrentItem(plantsSelected)
        handleClick(plantsSelected)
        setRareFlagOn(false)
        setSpecialFlagOn(false)
      }}
  }, [hasFilter, honey_id_first])


  return (
    <>
      <StyledPlantsNav isVisible={isVisible}>
        {specialFlagOn && <StyledFloatingBee src="/2020/images/biodiversity/apenatale.jpg"/>}
        <StyledGridRow isFullMobile noMargin>
          <StyledPlantsItems>
            <StyledPlantsFilter>
              {rarePlants.length>0 && (
              <>
                <StyledFormToggle className="switch" htmlFor="rarityCheckbox">
                  <input
                    type="checkbox"
                    id="rarityCheckbox"
                    checked={rareFlagOn}
                    onChange={(e) => setHasFilter(e.currentTarget.checked && 'rarity')}
                  />
                  <span />
                </StyledFormToggle>
                
                <StyledPlantsFilterLabel htmlFor="rarityCheckbox">
                  {labels.RARI}
                </StyledPlantsFilterLabel>
              </>)}

              {specialPlants.length>100 && (
              <>
                <StyledFormToggle className="switch" htmlFor="specialCheckbox">
                  <input
                    type="checkbox"
                    id="specialCheckbox"
                    checked={specialFlagOn}
                    onChange={(e) => setHasFilter(e.currentTarget.checked && 'special')}
                  />
                  <span />
                </StyledFormToggle>
              
                <StyledPlantsFilterLabel htmlFor="specialCheckbox">
                  {labels.SPECIAL_EDITION}
                </StyledPlantsFilterLabel>
              </>
              )}
            </StyledPlantsFilter>
            <StyledPlantsItemsSwiper
              ref={sliderEl}
              className="swiper-container"
            >
              <div className="swiper-wrapper">
                {items.map((plant, index) => (
                  <StyledPlantsItemContainer
                    key={index}
                    className="swiper-slide"
                    onClick={() => {
                      setCurrentItem(plant.id)
                      handleClick(plant.id)
                    }}>
                    <StyledPlantsItem
                      isActive={currentItem === plant.id}
                    >
                      <StyledPlantsItemIcon src={plant.plant_icon} />
                      <StyledPlantsItemName
                        dangerouslySetInnerHTML={{ __html: plant.plant }}
                      />
                      {plant.rare && (
                        <StyledPlantsItemRarity>raro</StyledPlantsItemRarity>
                      )}
                    </StyledPlantsItem>
                    {plant.av_quantity < 0.5 && (
                      <StyledPlantsItemSoldOutContainer>
                        <StyledPlantsItemSoldOut>Esaurito</StyledPlantsItemSoldOut>
                      </StyledPlantsItemSoldOutContainer>
                    )}
                  </StyledPlantsItemContainer>
                ))}
              </div>
            </StyledPlantsItemsSwiper>
            <StyledPlantsNavControl ref={prevArrowEl}>
              <Icon icon={icons.chevronLeft} color={Colors.black} />
            </StyledPlantsNavControl>
            <StyledPlantsNavControl ref={nextArrowEl} next>
              <Icon icon={icons.chevronRight} color={Colors.black} />
            </StyledPlantsNavControl>
          </StyledPlantsItems>
        </StyledGridRow>
      </StyledPlantsNav>
    </>
  )
}

/*
<StyledPlantsFilterMobile>
        <StyledFormToggle className="switch">
          <input
            type="checkbox"
            checked={hasFilter}
            onChange={(e) => setHasFilter(e.currentTarget.checked)}
          />
          <span />
        </StyledFormToggle>
        <StyledPlantsFilterLabel>{labels.NETTARE_RARO}</StyledPlantsFilterLabel>
      </StyledPlantsFilterMobile>
      */