import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledComparativeForm = styled.div``

export const StyledComparativeFormTitle = styled.div`
  text-transform: uppercase;
  font-size: ${Rem(12)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  padding-bottom: ${Rem(8)};
`

export const StyledComparativeFormBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const StyledComparativeFormInput = styled.input`
  appearance: none;
  width: 100%;
  height: ${Rem(68)};
  font-size: ${Rem(16)};
  border-radius: 0;
  border: 1px solid ${Colors.gray};
  padding: ${Rem(10)};
  background-color: ${Colors.transparent};
  font-family: ${Font.sans};

  @media (${BP.ipad}) {
    width: ${Rem(272)};
  }

  ${StyledComparativeFormBlock} & {
    width: calc(50% - ${Rem(8)});
  }
`

export const StyledComparativeFormResults = styled.div`
  padding-top: ${Rem(8)};
`

export const StyledComparativeFormResult = styled.div`
  font-size: ${Rem(12)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  text-transform: uppercase;
`

export const StyledComparativeFormResultsLabel = styled.span``

export const StyledComparativeFormResultsValue = styled.span`
  color: ${Colors.yellow};
`
