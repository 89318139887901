import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledIntroSubtitle } from '../Intro/style'

export const StyledTeamTabs = styled.div`
  ${StyledIntroSubtitle} {
    max-width: ${Rem(540)};
  }

  color: ${Colors.white};
  background-color: ${Colors.black};

  ${(props) =>
    props.isWhite &&
    css`
      color: ${Colors.black};
      background-color: ${Colors.white};
    `}
`

export const StyledTeamTabsNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${Rem(24)} ${Rem(16)};

  @media (${BP.ipad}) {
    justify-content: center;
    padding-bottom: ${Rem(58)};
  }
`

export const StyledTeamTabsNavItem = styled.div`
  position: relative;
  text-transform: uppercase;
  font-size: ${Rem(13)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(4)};
  cursor: pointer;

  @media (${BP.ipad}) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${Rem(180)};
    background-color: ${(props) => props.isWhite ? Colors.grayLight : Colors.grayDark};
    padding: ${Rem(20)} ${Rem(12)};
    margin: 0 ${Rem(4)};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 96%;
    height: 1px;
    left: 0;
    bottom: ${Rem(6)};
    background-color: ${Colors.transparent};
    transition: background 0.25s ease-out 0s;

    @media (${BP.ipad}) {
      bottom: 0;
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      color: ${Colors.yellow};

      @media (${BP.ipad}) {
        background-color: ${Colors.transparent};
      }

      &:after {
        background-color: ${Colors.yellow};
      }
    `}
`

export const StyledTeamTabsNavItemShort = styled.span`
  display: inline-block;

  @media (${BP.ipad}) {
    display: none;
  }
`

export const StyledTeamTabsNavItemDefault = styled.span`
  display: none;

  @media (${BP.ipad}) {
    display: inline-block;
  }
`

export const StyledTeamTabsContent = styled.div`
  overflow: hidden;
`

export const StyledTeamTabsContentBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledTeamTabsTeammate = styled.div`
  position: relative;
  min-width: 80%;
  height: ${Rem(500)};
  margin-left: ${Rem(24)};
  overflow: hidden;

  @media (${BP.ipad}) {
    min-width: calc(100% / 3.25);
  }

  &:first-child {
    @media (${BP.ipad}) {
      margin-left: 0;
    }
  }

  &:last-child {
    &:after {
      position: relative;
      display: block;
      content: '';
      left: 100%;
      width: ${Rem(24)};
      height: 1px;
      background-color: ${Colors.transparent};

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }

  ${(props) =>
    props.showInfo &&
    css`
      ${StyledTeamTabsTeammateInfo} {
        transform: translate(0, -100%);
      }

      ${StyledTeamTabsTeammateName} {
        color: ${Colors.yellow};
      }
    `}
`

export const StyledTeamTabsTeammateImage = styled.div`
  filter: grayscale(1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const StyledTeamTabsTeammateInfo = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: ${(props) => props.isWhite ? Colors.grayLight : Colors.grayDark};
  transition: transform 0.25s ease-out 0s;
  transform: translate(0, ${Rem(-80)});
  padding: ${Rem(18)} ${Rem(20)};
`

export const StyledTeamTabsTeammateName = styled.h2`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
  font-weight: bold;
  padding-bottom: ${Rem(4)};
  transition: color 0.25s ease-out 0s;
`

export const StyledTeamTabsTeammateRole = styled.h3`
  font-size: ${Rem(14)};
  line-height: ${Rem(20)};
  padding-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(24)};
    padding-bottom: ${Rem(32)};
  }
`

export const StyledTeamTabsTeammateDescription = styled.p`
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
`
