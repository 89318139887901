import React from 'react'
import {
  StyledPurchaseIntro,
  StyledPurchaseItem,
  StyledPurchaseItemCTA,
  StyledPurchaseItemDetails,
  StyledPurchaseItemFeature,
  StyledPurchaseItemFeatures,
  StyledPurchaseItemFeatureText,
  StyledPurchaseItemPrice,
  StyledPurchaseItemPriceDevices,
  StyledPurchaseItemPriceValue,
  StyledPurchaseItems,
  StyledPurchaseItemPreferred,
  StyledPurchaseItemTitle,
  StyledPurchasePlans,
} from './style'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import Button from '../Button/Button'
import { Link } from 'gatsby'
import {
  StyledTitle,
  StyledParagraph,
  StyledBigText,
} from '../../commons/Typography'
import { StyledGridRow } from '../../commons/Grid'

export default ({
  theme,
  title,
  subtitle,
  bigText,
  onPlanClick,
  plans,
  labels,
}) => {
  const isBlack = theme === 'black'
  return (
    <StyledPurchasePlans isBlack={isBlack}>
      <StyledGridRow noMargin>
        <StyledPurchaseIntro>
          <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
          <StyledParagraph dangerouslySetInnerHTML={{ __html: subtitle }} />
          <StyledBigText dangerouslySetInnerHTML={{ __html: bigText }} />
        </StyledPurchaseIntro>
        <StyledPurchaseItems>
          {plans.map((plan, index) => (
            <StyledPurchaseItem key={index} highlight={plan.highlight}>
              {plan.highlight && (
                <StyledPurchaseItemPreferred>
                  {labels.THE_MOST_CHOSEN}
                </StyledPurchaseItemPreferred>
              )}
              <StyledPurchaseItemTitle>{plan.title}</StyledPurchaseItemTitle>
              <StyledPurchaseItemPrice>
                <StyledPurchaseItemPriceValue>
                  {plan.price}
                </StyledPurchaseItemPriceValue>
                <StyledPurchaseItemPriceDevices>
                  &nbsp;/ {plan.devices}
                </StyledPurchaseItemPriceDevices>
              </StyledPurchaseItemPrice>
              <StyledPurchaseItemDetails
                dangerouslySetInnerHTML={{ __html: plan.details }}
              />
              <StyledPurchaseItemFeatures>
                {plan.features.map((feature, index) => (
                  <StyledPurchaseItemFeature key={index}>
                    <Icon icon={icons[feature.icon]} color={Colors.yellow} />
                    <StyledPurchaseItemFeatureText
                      dangerouslySetInnerHTML={{ __html: feature.text }}
                    />
                  </StyledPurchaseItemFeature>
                ))}
              </StyledPurchaseItemFeatures>
              <StyledPurchaseItemCTA>
                <div>
                  {onPlanClick ? (
                    <Button
                      as="div"
                      theme={plan.highlight ? 'yellow' : 'white'}
                      noLine
                      handleOnClick={() => onPlanClick(index)}
                    >
                      {plan.cta.label}
                      <Icon
                        icon={icons.arrowRight}
                        color={plan.highlight ? Colors.white : Colors.black}
                      />
                    </Button>
                  ) : (
                    <Button
                      as="div"
                      theme={plan.highlight ? 'yellow' : 'white'}
                      noLine
                      to={plan.cta.url}
                    >
                      {plan.cta.label}
                      <Icon
                        icon={icons.arrowRight}
                        color={plan.highlight ? Colors.white : Colors.black}
                      />
                    </Button>
                  )}
                </div>
              </StyledPurchaseItemCTA>
            </StyledPurchaseItem>
          ))}
        </StyledPurchaseItems>
      </StyledGridRow>
    </StyledPurchasePlans>
  )
}
