import React, { useContext } from 'react'
import {
  StyledProductSheet,
  StyledProductSheetIntro,
  StyledProductSheetProduct,
  StyledProductSheetProductCTA,
  StyledProductSheetProductDetail,
  StyledProductSheetProductDetailContent,
  StyledProductSheetProductDetails,
  StyledProductSheetProductDetailTitle,
  StyledProductSheetProductDiscount,
  StyledProductSheetProductDiscountContent,
  StyledProductSheetProductFeature,
  StyledProductSheetProductFeatures,
  StyledProductSheetProductFeatureText,
  StyledProductSheetProductImage,
  StyledProductSheetProductInfo,
  StyledProductSheetProductPrice,
  StyledProductSheetProductSubTitle,
  StyledProductSheetProductTitle,
} from './style'
import {
  StyledTitle,
  StyledBigText,
  StyledParagraph,
} from '../../commons/Typography'
import { StyledGridRow } from '../../commons/Grid'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import Button from '../Button/Button'
import { Link } from 'gatsby'
import { LayoutContext } from '../../contexts/LayoutContext'
import { DataContext } from '../../contexts/DataContext'

export default ({ title, subtitle, bigText, product, isModal }) => {
  const { setModalContent } = useContext(LayoutContext)
  const { labels } = useContext(DataContext)
  return (
    <StyledProductSheet isModal={isModal}>
      {isModal && product.discount && (
        <StyledProductSheetProductDiscount>
          <StyledProductSheetProductDiscountContent>
            <span>{labels.DISCOUNT}</span>
            <span>{product.discount}</span>
          </StyledProductSheetProductDiscountContent>
        </StyledProductSheetProductDiscount>
      )}
      <StyledGridRow noMargin>
        <StyledProductSheetIntro>
          {title && <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />}
          {subtitle && (
            <StyledParagraph dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}
          {bigText && (
            <StyledBigText dangerouslySetInnerHTML={{ __html: bigText }} />
          )}
        </StyledProductSheetIntro>
        <div>
          <StyledProductSheetProduct>
            <StyledProductSheetProductInfo>
              <StyledProductSheetProductImage src={product.image} />
              <StyledProductSheetProductTitle
                dangerouslySetInnerHTML={{ __html: product.title }}
              />
              <StyledProductSheetProductSubTitle
                dangerouslySetInnerHTML={{ __html: product.subtitle }}
              />
            </StyledProductSheetProductInfo>
            <StyledProductSheetProductFeatures>
              {product.features.map((feature, index) => (
                <StyledProductSheetProductFeature key={index}>
                  <Icon icon={icons[feature.icon]} color={Colors.yellow} />
                  <StyledProductSheetProductFeatureText
                    dangerouslySetInnerHTML={{ __html: feature.text }}
                  />
                </StyledProductSheetProductFeature>
              ))}
            </StyledProductSheetProductFeatures>
            <StyledProductSheetProductDetails>
              {product.details.map((detail, index) => (
                <StyledProductSheetProductDetail key={index}>
                  <StyledProductSheetProductDetailTitle
                    dangerouslySetInnerHTML={{ __html: detail.title }}
                  />
                  <StyledProductSheetProductDetailContent
                    dangerouslySetInnerHTML={{ __html: detail.content }}
                  />
                </StyledProductSheetProductDetail>
              ))}
            </StyledProductSheetProductDetails>
          </StyledProductSheetProduct>
          {isModal && (
            <StyledProductSheetProductCTA>
              <Button
                as="div"
                theme="transparent"
                handleOnClick={() => setModalContent(false)}
                to={product.cta.url}
              >
                {product.cta.label}{' '}
                <Icon icon={icons.arrowRight} color={Colors.black} />
              </Button>
              <StyledProductSheetProductPrice>
                {product.price}
              </StyledProductSheetProductPrice>
            </StyledProductSheetProductCTA>
          )}
        </div>
      </StyledGridRow>
    </StyledProductSheet>
  )
}
