import React from 'react'
import {
  StyledBusinessSlide,
  StyledBusinessSlideCard,
  StyledBusinessSlideCardBlock,
  StyledBusinessSlideCardMedia,
  StyledBusinessSlideCardContent,
  StyledBusinessSlideCardSmallText,
  StyledBusinessSlideCardCounter,
  StyledBusinessSlideCardText,
  StyledBusinessSlideCardTitle,
  StyledBusinessSlideCardParagraph,
  StyledBusinessSlideCardCta,
  StyledBusinessSlideCardLogo,
} from './style'
import { Link } from 'gatsby'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'

export default ({ count, theme, image, smallText, title, paragraph, cta, logo }) => (
  <StyledBusinessSlide className="swiper-slide">
    <StyledBusinessSlideCard theme={theme}>
      <StyledBusinessSlideCardBlock>
        <StyledBusinessSlideCardMedia bg={image} />
      </StyledBusinessSlideCardBlock>
      <StyledBusinessSlideCardBlock>
        <StyledBusinessSlideCardContent>
          <StyledBusinessSlideCardSmallText>
            <StyledBusinessSlideCardCounter>
              {count < 10 ? `0${count}` : count}
            </StyledBusinessSlideCardCounter>
            {smallText}
          </StyledBusinessSlideCardSmallText>
          <StyledBusinessSlideCardText>
            <StyledBusinessSlideCardTitle
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <StyledBusinessSlideCardParagraph
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          </StyledBusinessSlideCardText>
          {cta &&
            <StyledBusinessSlideCardCta>
              <Button as="div" theme="yellow" to={cta.url}>
                {cta.label}
                <Icon icon={icons.arrowRight} color={Colors.white} />
              </Button>
            </StyledBusinessSlideCardCta>
          }
          <StyledBusinessSlideCardLogo src={logo}/>
        </StyledBusinessSlideCardContent>
      </StyledBusinessSlideCardBlock>
    </StyledBusinessSlideCard>
  </StyledBusinessSlide>
)
