import React, {} from 'react'
import {
  StyledSectionHeader,
  StyledTitle,
  StyledUnderlinedTitle,
  StyledPs
} from './style'

export default ({
  id,
  title,
  underlinedTitle,
  showAsterisk,
  ps,
  marginTop
}) => {

    return (
      <StyledSectionHeader id={id} marginTop={marginTop}>
        { title && (
          <StyledTitle dangerouslySetInnerHTML={{ __html: title }}/>
        )}
        { underlinedTitle && (
          <StyledUnderlinedTitle
            showAsterisk={showAsterisk}
            dangerouslySetInnerHTML={{ __html: underlinedTitle }}
          />
        )}
        
        { ps && (
          <StyledPs dangerouslySetInnerHTML={{ __html: ps }}/>
        )}
      </StyledSectionHeader>
    )
}
