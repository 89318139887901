import React from 'react'
import {
  StyledTextBlock,
} from './style'

export default ({
  text,
  theme,
  marginTop = 0,
  marginBottom = 0,
}) => {
  return (
    <StyledTextBlock 
      dangerouslySetInnerHTML={{ __html: text }} 
      isWhite={theme === 'white'} 
      marginTop={marginTop} 
      marginBottom={marginBottom}/>
  )
}
