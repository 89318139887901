import React from 'react'
import {
  StyledCards,
  StyledCard,
  StyledCardIcon,
  StyledCardTitle,
  StyledCardParagraph,
} from './style.jsx'
import { StyledGridRow } from '../../commons/Grid'
export default ({ items }) => {
  return (
    <StyledGridRow>
      <StyledCards>
        {items.map((item, index) => (
          <StyledCard key={index}>
            <StyledCardIcon src={item.icon} />
            <StyledCardTitle>{item.title}</StyledCardTitle>
            <StyledCardParagraph>{item.paragraph}</StyledCardParagraph>
          </StyledCard>
        ))}
      </StyledCards>
    </StyledGridRow>
  )
}
