import React from 'react'
import {
  StyledRemember,
  StyledRememberDescription,
  StyledRememberTitle,
} from './style'
import { StyledGridRow } from '../../commons/Grid'
import { StyledBigText } from '../../commons/Typography'
export default ({ title, description }) => {
  return (
    <StyledRemember>
      <StyledGridRow>
        <StyledRememberTitle>
          <StyledBigText dangerouslySetInnerHTML={{ __html: title }} />
        </StyledRememberTitle>
        <StyledRememberDescription
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </StyledGridRow>
    </StyledRemember>
  )
}
