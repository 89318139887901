import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
} from '../../commons/Theme'
import { StyledGridRow } from '../../commons/Grid'
import { StyledMap } from '../OpenLayers/style'
import { StyledHeroSubtitle, StyledHeroTitle, StyledHero } from '../Hero/style'
import { StyledButton } from '../Button/style'
import { StyledApiErrorForm } from '../ApiErrorForm/style'
import { StyledLoader } from '../Loader/style'

export const StyledTextHivesTitle = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto ${Rem(14)};
  padding: 0 ${Rem(20)};
  font-size: ${Rem(18)};

  @media (${BP.desktop}) {
    font-size: ${Rem(25)};
    margin: 0 auto ${Rem(34)};
    max-width: ${Rem(1248)};
  }
`

export const StyledHives = styled.div`
  ${StyledHeroTitle} {
    font-size: ${Rem(45)};
    line-height: ${Rem(57)};
    background-color: rgba(0, 0, 0, 0);

    @media (${BP.desktop}) {
      font-size: ${Rem(80)};
      line-height: ${Rem(80)};
    }
  }

  ${StyledHero} {
    padding-top: ${Rem(0)};
    margin-top: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  ${StyledHeroSubtitle} {
    font-size: ${Rem(16)};
    line-height: ${Rem(24)};
    @media (${BP.desktop}) {
      display: inline-block;
      font-size: ${Rem(18)};
      line-height: ${Rem(30)};
    }
  }
`

export const StyledHivesWrapper = styled.div`
  position: relative;
  margin-bottom: ${Rem(40)};
  > ${StyledGridRow} {
    position: unset;
  }
  @media (${BP.desktop}) {
    display: flex;
    margin-bottom: 0;

    > ${StyledGridRow} {
      position: relative;
      @media (${BP.desktop}) {
        max-width: ${Rem(1500)};
      }
    }
  }

  & > ${StyledLoader} {
    position: absolute;
    z-index: 9;
  }
`

export const StyledHivesListScroller = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto hidden;

  @media (${BP.desktop}) {
    display: block;
    position: relative;
    max-height: 100%;
    padding-right: ${Rem(24)};
    width: auto;
    overflow: hidden auto;
  }

  scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
    background-color: ${Colors.gray};
  }

  scrollbar {
    width: ${Rem(3)};
    height: ${Rem(3)};
    background-color: ${Colors.black};
  }

  scrollbar-thumb {
    border-radius: ${Rem(3)};
    width: ${Rem(3)};
    height: ${Rem(3)};
    background-color: ${Colors.yellow};
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
    background-color: ${Colors.gray};
  }

  ::-webkit-scrollbar {
    width: ${Rem(3)};
    height: ${Rem(3)};
    background-color: ${Colors.black};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${Rem(3)};
    width: ${Rem(3)};
    height: ${Rem(3)};
    background-color: ${Colors.yellow};
  }
`

export const StyledHivesList = styled.div`
  position: absolute;
  bottom: ${Rem(15)};
  left: ${Rem(5)};
  width: calc(97%);
  z-index: 3;
  @media (${BP.desktop}) {
    position: relative;
    display: block;
    width: calc(50%);
    height: ${Rem(860)};
    overflow: hidden;
    bottom: auto;
    left: auto;
  }
`

export const StyledHivesMap = styled.div`
  position: relative;
  display: block;
  width: 100%;
  right: 0;

  ${StyledGridRow} {
    max-width: none;
    padding: 0;
  }

  ${StyledMap} {
    height: 68vh;
    @media (${BP.desktop}) {
      height: ${Rem(860)};
    }

    &:before {
      display: none;
    }
  }

  @media (${BP.desktop}) {
    position: absolute;
    display: block;
    width: calc(50%);
  }
`

export const StyledBeekeeper = styled.div``

export const StyledBeekeeperName = styled.div`
  display: none;
  font-style: normal;
  font-weight: bold;
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${Colors.black};

  @media (${BP.desktop}) {
    display: inline-block;
  }

  &:before {
    position: relative;
    display: inline-block;
    content: '';
    vertical-align: middle;
    width: ${Rem(20)};
    height: ${Rem(2)};
    top: ${Rem(-3)};
    margin-right: ${Rem(8)};
    background-color: ${Colors.yellow};
  }
`

export const StyledBeekeeperHives = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  @media (${BP.desktop}) {
    display: block;
    margin-bottom: ${Rem(30)};
  }
`

export const StyledHive = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  background-color: ${Colors.white};
  min-width: 80vw;
  max-width: 80vw;
  height: ${Rem(120)};
  margin-right: ${Rem(20)};
  margin-bottom: ${Rem(10)};
  border-radius: ${Rem(10)};
  overflow: hidden;

  @media (${BP.tablet}) {
    min-width: 60vw;
    max-width: 60vw;
  }

  @media (${BP.ipad}) {
    min-width: 40vw;
    max-width: 40vw;
  }

  @media (${BP.desktop}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ${Rem(30)};
    padding-bottom: ${Rem(140)};
    width: 100%;
    height: auto;
    min-width: 0;
    max-width: none;
    border-bottom: 1px solid ${Colors.gray};
    margin-bottom: 0;
    margin-right: 0;
    border-radius: 0;
  }
`

export const StyledHiveGallery = styled.div`
  display: none;

  @media (${BP.desktop}) {
    position: relative;
    display: inline-block;
    min-width: ${Rem(312)};
    max-width: ${Rem(312)};
    margin-right: ${Rem(16)};
    margin-bottom: ${Rem(1)};
  }
`
export const StyledHiveMobileImage = styled.div`
  display: inline-block;
  position: relative;
  ${Ratio(265, 384)};
  min-width: 50%;
  max-width: 50%;
  height: auto;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  
  @media (${BP.desktop}) {
    display: none;
  }
`

export const StyledHiveContent = styled.div`
  margin: ${Rem(5)} ${Rem(15)};
  cursor: pointer;
`

export const StyledHiveActions = styled.div`
  display: none;
  ${StyledButton} {
    &:last-child {
      display: none;

      @media (${BP.desktop}) {
        display: inline-flex;
      }
    }
  }

  @media (${BP.desktop}) {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    bottom: ${Rem(24)};
  }
`

export const StyledHiveName = styled.div`
  font-size: ${Rem(12)};
  line-height: ${Rem(16)};
  letter-spacing: ${Rem(5)};
  text-transform: uppercase;

  @media (${BP.desktop}) {
    font-size: ${Rem(15)};
    line-height: ${Rem(20)};
    letter-spacing: ${Rem(5)};
  }
`

export const StyledHiveDescription = styled.div`
  font-size: ${Rem(13)};
  line-height: ${Rem(15)};
  font-weight: 300;
  padding: 2px 6px;

  @media (${BP.tablet}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(22)};
  }
  @media (${BP.desktop}) {
    font-weight: 300;
    font-size: ${Rem(24)};
    line-height: ${Rem(26)};
    overflow: hidden;
    max-height: ${Rem(180)};
  }
`

export const StyledHiveInformation = styled.div`
  font-size: ${Rem(13)};
  line-height: ${Rem(21)};
  color: ${Colors.gray};

  @media (${BP.tablet}) {
    font-size: ${Rem(16)};
    line-height: ${Rem(26)};
  }

  @media (${BP.desktop}) {
    display: inline-block;
    font-size: ${Rem(18)};
    line-height: ${Rem(30)};
  }
`

export const StyledHiveServices = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  top: ${Rem(10)};
  left: ${Rem(10)};
  z-index: 2;
`

export const StyledHiveService = styled.div`
  font-size: ${Rem(13)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  text-transform: uppercase;
  border: 1px solid ${Colors.gray};
  border-radius: ${Rem(10)};
  padding: ${Rem(8)};
  margin-right: ${Rem(16)};
  margin-bottom: ${Rem(16)};
  background-color: ${Colors.white};
`

export const StyledContainerHives = styled.div`
  & > ${StyledLoader} {
    position: relative;
    padding-top: ${Rem(0)};
    margin-top: 0;
    display: none;
    ${(props) =>
      props.isVisible &&
      css`
        display: inline-block;
        opacity: 1;
        pointer-events: all;
      `}
  }
`

export const StyledEmptyHives = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(${Rem(10)});
  z-index: 9;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-out 0s;

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  > img {
    width: ${Rem(100)};

    @media (${BP.ipad}) {
      width: ${Rem(180)};
    }
  }

  & > ${StyledApiErrorForm} {
    background-color: white;
  }
`