import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledMakeDifference = styled.div``

export const StyledMakeDifferenceIntro = styled.div`
  padding-bottom: ${Rem(52)};
  text-align: center;

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(112)};
  }
`

export const StyledMakeDifferenceIntroTitle = styled.h2`
  font-size: ${Rem(30)};
  line-height: ${Rem(39)};
  padding-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    line-height: ${Rem(60)};
  }
`

export const StyledMakeDifferenceIntroSubTitle = styled.h3`
  font-size: ${Rem(16)};
  line-height: ${Rem(28)};

  @media (${BP.ipad}) {
    font-size: ${Rem(30)};
    line-height: ${Rem(28)};
  }
`

export const StyledMakeDifferenceGrid = styled.div`
  background-color: ${Colors.yellow};
  padding: ${Rem(54)} 0;

  @media (${BP.ipad}) {
    padding: ${Rem(78)} 0 ${Rem(102)};
  }
`

export const StyledMakeDifferenceGridTitle = styled.h4`
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(4)};
  padding-bottom: ${Rem(24)};
  text-transform: uppercase;

  @media (${BP.ipad}) {
    text-align: center;
    font-size: ${Rem(15)};
  }
`

export const StyledMakeDifferenceGridSubTitle = styled.p`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    text-align: center;
    font-size: ${Rem(50)};
    line-height: ${Rem(60)};
  }
`

export const StyledMakeDifferenceGridItems = styled.div`
  display: flex;
  flex-direction: column;

  @media (${BP.ipad}) {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
`

export const StyledMakeDifferenceGridItem = styled.div`
  margin-bottom: ${Rem(40)};

  @media (${BP.ipad}) {
    position: relative;
    margin-bottom: 0;
    min-width: ${Rem(310)};
    max-width: ${Rem(310)};
    padding-bottom: ${Rem(80)};
  }
`

export const StyledMakeDifferenceGridItemImage = styled.div`
  ${Ratio(290, 174)};
  width: 100%;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  overflow: hidden;
  margin-bottom: ${Rem(24)};
`

export const StyledMakeDifferenceGridItemTitle = styled.h2`
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  padding-bottom: ${Rem(16)};
  text-transform: uppercase;
`

export const StyledMakeDifferenceGridItemParagraph = styled.p`
  font-size: ${Rem(14)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(40)};
`

export const StyledMakeDifferenceGridItemCta = styled.div`
  display: inline-block;

  @media (${BP.ipad}) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`
