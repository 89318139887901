import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledButton } from '../Button/style'
import { StyledIntroSubtitle } from '../Intro/style'
import { StyledIcon } from '../Icon/style'

export const StyledShop = styled.div`
  color: ${Colors.black};
  background-color: ${Colors.grayLight};
  padding-top: ${Rem(40)};
  padding-bottom: ${Rem(50)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(120)};
    padding-bottom: ${Rem(100)};

    ${StyledIntroSubtitle} {
      max-width: 50%;
      padding-right: ${Rem(110)};
    }
  }
`

export const StyledShopItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: ${Rem(20)};
  padding-bottom: ${Rem(32)};
`

export const StyledShopItem = styled.div`
  position: relative;
  min-width: ${Rem(285)};
  max-width: ${Rem(285)};
  margin-left: ${Rem(20)};
  padding: ${Rem(16)} ${Rem(12)} ${Rem(140)};
  background-color: ${Colors.white};

  &:last-child {
    margin-top: -1px;

    &:after {
      position: relative;
      content: '';
      display: block;
      width: ${Rem(20)};
      height: 1px;
      left: 100%;
      margin-left: ${Rem(12)};
    }
  }
`

export const StyledShopItemDiscount = styled.div`
  ${Ratio(523, 348)};
  width: ${Rem(100)};
  position: absolute;
  top: ${Rem(-12)};
  right: 0;
  background-image: url('/2020/images/discount.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const StyledShopItemDiscountContent = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: ${Rem(6)};
  left: ${Rem(16)};
  text-transform: uppercase;
  font-size: ${Rem(14)};

  span:last-child {
    position: relative;
    top: ${Rem(-2)};
    font-size: ${Rem(24)};
  }
`

export const StyledShopItemImage = styled.div`
  width: ${Rem(275)};
  height: ${Rem(180)};
  margin: 0 auto ${Rem(50)};
  background-image: url('${(props) => props.bg}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export const StyledShopItemContent = styled.div``

export const StyledShopItemTitle = styled.div`
  font-size: ${Rem(20)};
  line-height: ${Rem(30)};
`

export const StyledShopItemSubTitle = styled.div`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
`

export const StyledShopItemFeatures = styled.div`
  padding-top: ${Rem(12)};
`

export const StyledShopItemFeature = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: ${Rem(8)};
`

export const StyledShopItemFeatureText = styled.div`
  font-size: ${Rem(13)};
  line-height: ${Rem(14)};
  padding-left: ${Rem(16)};
`

export const StyledShopItemCTA = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${Rem(12)};
  width: 100%;
  left: 0;
  bottom: ${Rem(16)};

  ${StyledButton} {
    color: ${Colors.black};
    margin-left: 0;

    ${StyledIcon} {
      right: ${Rem(10)};
    }
  }
`

export const StyledShopItemPrice = styled.div`
  display: inline-block;
  font-size: ${Rem(20)};
  line-height: ${Rem(20)};
  letter-spacing: ${Rem(0.5)};
  color: ${Colors.yellow};
`
