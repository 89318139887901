import React, { useContext, useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import {
  StyledBeekeepersMap,
  StyledBeekeepersWrapper,
  StyledHivesButton,
  StyledHivesText,
} from './style'
import { DataContext } from '../../contexts/DataContext'
import { StyledGridRow } from '../../commons/Grid'
import { LayoutContext } from '../../contexts/LayoutContext'
import Button from '../Button/Button'
import useApi from '../../hooks/useApi'
import OpenLayers from '../OpenLayers/OpenLayers'
import Community from '../Community/Community'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import ApiErrorForm from '../ApiErrorForm/ApiErrorForm'
gsap.registerPlugin(ScrollToPlugin)

export default ({ isLoading, purchaser, coupon, markers = [] }) => {
  const { getAdoptedHives } = useApi()
  const { labels,  i18nLink } = useContext(DataContext)
  const [hivesAdopted, setHivesAdopted] = useState([])
  const [kpi, setKpi] = useState([{image: '/2020/images/adotta/cuore.svg', count: '4.050',label: 'porpo'}])
  const [activeMapItem, setActiveMapItem] = useState(null)
  const [map, setMap] = useState(null)
  const scroller = useRef(null)
  const { setModalContent } = useContext(LayoutContext)

  useEffect(() => {
    getAdoptedHives(purchaser, coupon).then((res) => {
        if (res.error) {
          setModalContent(<ApiErrorForm message={res.message} />)
          console.log('error getting Hives')
        } else {
          let total_adopted_qty = 0
          let totalHoney = 0
          var arrHives = []
          for (var key in res) {
            res[key].forEach((x) =>{
              x.slug=key.replace(/ /g, '-').toLowerCase()
              arrHives.push(x)
              if(x.adopted_qty) {
                total_adopted_qty = total_adopted_qty+x.adopted_qty
              } else {
                x.adopted_qty = 0
              }
              if(x.honeys.length) {
                totalHoney = totalHoney + x.honeys.length
              }
            })
          }
          console.log(arrHives)
          setHivesAdopted(arrHives)
          //setKpi([{image: '/2020/svg/icons/bee.svg', count: total_adopted_qty*2, label: 'Mila Api Monitorate'},
          //{image: '/2020/images/adotta/fiore.svg', count: total_adopted_qty*0.2*6, label: 'Milioni Fiori Impollinati'},
          //{image: '/2020/svg/icons/hive.svg', count: 0, label: 'Alveari Monitorati'},
          //{image: '/2020/images/adotta/fiore.svg', count:  0, label: 'Fioriture impollinate'}])

          setKpi([{image: '/2020/svg/icons/bee.svg', count: 180, label: 'Mila Api Monitorate'},
          {image: '/2020/images/adotta/fiore.svg', count: 180, label: 'Milioni Fiori Impollinati'},
          {image: '/2020/svg/icons/hive.svg', count: 3, label: 'Alveari Monitorati'},
          {image: '/2020/images/adotta/fiore.svg', count:  5, label: 'Fioriture impollinate'}])

          /*if (res.length === 0) {
            setIsMissingBeekeepers(true)
          } else {
            setIsMissingBeekeepers(false)
          }*/
        }
        //setIsLoadingBeekeepers(false)
      })
  }, [])

  return (
    <>

    <StyledBeekeepersWrapper>
    <StyledHivesButton>
      <StyledHivesText>
        Codice sconto {coupon}
      </StyledHivesText>
      <Button as="div" to={`/biodiversity?coupon=${coupon}`}>
        Espandi l'apiario
      </Button>
    </StyledHivesButton>
      <StyledBeekeepersMap>
      {hivesAdopted.length > 0 && (
          <OpenLayers
            markers={[
              ...hivesAdopted.map((x) => {
                let urlPin = '/2020/images/biodiversity/pin.png'
                const link = i18nLink(`/beekeeper/${x.slug}`)
                return {
                  pin: urlPin,
                  center: {
                    lat: x.latitude,
                    lng: x.longitude,
                  },
                  scale: 0.5,
                  infowindow: `<strong>${x.adopted_qty*2000} Bees</strong><br/><a href='${link}'>Aumenta le api</a>`
                  /*onClick: () => {
                    if (activeMapItem===x.id) {navigate(i18nLink(`/beekeeper/${x.slug}?${urlParams}`))}
                      setActiveMapItem(x.id)
                      const target = scroller.current.querySelector(
                        `[data-id="${x.id.toString()}"]`
                      )
                      scroller.current.scrollTo({
                        top: target.offsetTop,
                        left: target.offsetLeft,
                        behavior: 'smooth',
                      })
                  },*/
                }
              }),
              ...markers.map(marker => {
                return {
                  pin: marker.icon,
                  center: {
                    lat: marker.lat,
                    lng: marker.lng,
                  },
                  infowindow: marker.infowindow,
                }
              }),
            ]}
            onMapReady={setMap}
          />
        )}
      </StyledBeekeepersMap>
    </StyledBeekeepersWrapper>
    <Community title= "Api GreenTime" subtitle = 'I nostri <span class="color-yellow">numeri</span>' items={kpi}></Community>
    </>
  )
}




/*
- component: Community
props:
  items:
    - image: /2020/images/adotta/cuore.svg
      count: '4.050'
      label: People
    - image: /2020/images/adotta/casa.svg
      count: '65'
      label: Brand
    - image: /2020/images/adotta/ape.svg
      count: '850.235.321'
      label: Bees
    - image: /2020/images/adotta/uomo.svg
      count: 150
      label: Beekeepers
    - image: /2020/images/adotta/fiore.svg
      count: '1.532.300.000'
      label: Polliante Flowers   
        */