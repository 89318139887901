import React, { useState } from 'react'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import {
  StyledSingleVimeoPlayer,
} from './style'

export default ({
  id,
  videoID,
  thumb,
  theme = 'black',
  playColor = 'white',
}) => {
  const [autoplay, setAutoplay] = useState(false)

  return (
    <StyledSingleVimeoPlayer id={id} onClick={() => setAutoplay(true)} isWhite={theme=='white'}>
      <VimeoPlayer videoID={videoID} thumb={thumb} autoplay={autoplay} videoIconColor={playColor}/>
    </StyledSingleVimeoPlayer>
  )
}
