import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledCausesList = styled.div``

export const StyledCausesListTitle = styled.div`
  text-align: center;
  font-size: ${Rem(32)};
  margin-bottom: ${Rem(50)};
`

export const StyledCausesListScroller = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`

export const StyledCausesListItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:after {
    content: '';
    display: block;
    min-width: ${Rem(50)};
    max-width: ${Rem(50)};
    height: 1px;

    @media (${BP.ipad}) {
      display: none;
    }
  }
`

export const StyledCausesListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: ${Rem(50)};

  @media (${BP.ipad}) {
    margin: initial;
    width: 100%;
  }
`

export const StyledCausesListImage = styled.img`
  width: ${Rem(80)};
  margin-bottom: ${Rem(40)};
`

export const StyledCausesListLabel = styled.div`
  text-align: center;
  font-size: ${Rem(15)};
  line-height: ${Rem(25)};
  letter-spacing: ${Rem(5)};
`

export const StyledCausesListMobilePopup = styled.div`
  position: absolute;
  width: calc(100vw - ${Rem(40)});
  top: auto;
  left: 50%;
  transform: translate(-50%, 0);
  color: ${Colors.white};
  background-color: ${Colors.gray};
  padding: ${Rem(16)};
  z-index: 2;
  opacity: 1;
  pointer-events: all;

  &:empty {
    opacity: 0;
    pointer-events: none;
  }

  @media (${BP.ipad}) {
    display: none;
  }
`

export const StyledCausesListPopup = styled.div`
  position: absolute;
  display: none;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: auto;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: ${Rem(20)};
  color: ${Colors.white};
  background-color: ${Colors.grayTransparent};

  @media (${BP.ipad}) {
    display: block;
  }

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
    `}
`
