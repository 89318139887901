import React from 'react'
import {
  StyledSeasons,
  StyledSeasonsItem,
  StyledSeasonsItemDescription,
  StyledSeasonsItemImage,
  StyledSeasonsItemTitle,
  StyledSeasonsScroller,
} from './style'
import { StyledGridRow } from '../../commons/Grid'
import Scroller from '../Scroller/Scroller'
export default ({ items }) => {
  return (
    <StyledSeasons>
      <StyledGridRow isFullMobile>
        <Scroller>
          <StyledSeasonsScroller>
            {items.map((season, index) => (
              <StyledSeasonsItem key={index}>
                <StyledSeasonsItemImage bg={season.image} />
                <StyledSeasonsItemTitle
                  dangerouslySetInnerHTML={{ __html: season.title }}
                />
                <StyledSeasonsItemDescription
                  dangerouslySetInnerHTML={{ __html: season.description }}
                />
              </StyledSeasonsItem>
            ))}
          </StyledSeasonsScroller>
        </Scroller>
      </StyledGridRow>
    </StyledSeasons>
  )
}
