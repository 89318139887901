import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'
import { StyledFormToggle } from '../../commons/Form'

export const StyledPlantsNav = styled.div`
  width: 100%;
  position: fixed;
  height: auto;
  top: ${Sizes.headerMobile};
  padding-top: ${Rem(4)};
  background-color: rgba(0, 0, 0, 0);
  z-index: 9;
  transform: translate3d(0, -60%, 0);
  transition: transform 0.5s ease-in-out 0s;

  @media (${BP.ipad}) {
    
    top: ${Sizes.headerDesktop};
  }

  ${(props) =>
    props.isVisible &&
    css`
      transform: translate3d(0, 0, 0);
    `}
`

export const StyledPlantsItems = styled.div`
  position: relative;
  margin-bottom: ${Rem(5)};
  display: flex;
  height: ${Rem(100)};
  box-shadow: 0 ${Rem(2)} ${Rem(2)} ${Colors.black50};
  background-color: rgba(255, 255, 255, 1);
`

export const StyledPlantsFilterMobile = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: ${Rem(8)};
  left: ${Rem(74)};
  z-index: 100;

  @media (${BP.ipad}) {
    display: none;
  }

  ${StyledFormToggle} {
    transform: scale(0.8);
  }

  ${() => css`
    ${StyledPlantsFilterLabel} {
      font-size: ${Rem(10)};
      padding-top: 0;
    }
  `}
`

export const StyledPlantsFilter = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align:center;
  margin:0;
  padding:0;
  width: ${Rem(66)};
  padding-left:${Rem(4)};
  @media (${BP.ipad}) {
    display: flex;
  }
`

export const StyledPlantsFilterLabel = styled.label`
  padding-top: ${Rem(4)};
  font-size:${Rem(11)};
  padding-bottom: ${Rem(4)};
  @media (${BP.ipad}) {
    font-size:${Rem(12)};
  }
`

export const StyledPlantsItemsSwiper = styled.div`
  width: 100%;
  margin-top:10px;
  @media (${BP.ipad}) {
    position: relative;
    
    max-width: calc(100% - ${Rem(220)});
  }
`

export const StyledPlantsItemContainer = styled.div`
  cursor: pointer;
  text-align: center;
  min-width: ${Rem(100)};
  max-width: ${Rem(100)};
`

export const StyledPlantsItem = styled.div`
  filter: grayscale(1);

  &:hover {
    filter: grayscale(0);
    color: ${Colors.yellow};
  }

  ${(props) =>
    props.isActive &&
    css`
      filter: grayscale(0);
      color: ${Colors.yellow};
    `}
`

export const StyledPlantsItemIcon = styled.img`
  height: ${Rem(60)};
`

export const StyledPlantsItemName = styled.div`
  font-size: ${Rem(12)};
  line-height: ${Rem(13)};
`

export const StyledPlantsItemRarity = styled.div`
  font-size: ${Rem(10)};
  line-height: ${Rem(13)};
  text-transform: capitalize;
`

export const StyledPlantsItemSoldOutContainer = styled.div`
  height: ${Rem(60)};
  top: 0;
  position: absolute;
  width: 100%;
`

export const StyledPlantsItemSoldOut = styled.div`
  font-size: ${Rem(10)};
  line-height: ${Rem(13)};
  text-transform: uppercase;
  background-color: ${Colors.yellow};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  padding: 5px;
  border-radius: 5px;
  color: ${Colors.white};
`

export const StyledFloatingBee = styled.img`
  position:absolute;
  height: ${Rem(120)};
  top:${Rem(120)};
  right:${Rem(50)};
  z-index:0;
`

export const StyledPlantsNavControl = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  width: ${Rem(8)};
  top: 50%;
  left: ${Rem(134)};
  margin-right: ${Rem(4)};
  z-index: 2;
  transform: translateY(-50%);

  @media (${BP.ipad}) {
    display: block;
  }

  ${StyledIcon} {
    width: 100%;
  }

  ${(props) =>
    props.next &&
    css`
      left: auto;
      margin-right: ${Rem(10)};
      right:${Rem(10)};
    `};
  
`
