import React, {} from 'react'
import {
  StyledButtonBlock,
  StyledChildren
} from './style'
import Button from '../Button/Button'
import useScrollController from '../../hooks/useScrollController';

export default ({
  label,
  destination
}) => {
    const { scrollToCssSelector } = useScrollController()

    return (
      <StyledButtonBlock >
        
        <Button
          as="div"
          theme="yellow" 
          handleOnClick={()=>scrollToCssSelector(destination, 84)}
          noLine
        >
          <StyledChildren dangerouslySetInnerHTML={{ __html: label }}></StyledChildren>
        </Button>
        
      </StyledButtonBlock>
    )
}
