import React, { useContext, useState } from 'react'
import {
  StyledNewsletterForm,
  StyledNewsletterFormBlock,
  StyledNewsletterFormName,
  StyledNewsletterFormTitle,
  StyledNewsletterFormParagraph,
  StyledNewsletterFormBigText,
  StyledNewsletterFormSubmit,
} from './style.jsx'
import {
  StyledForm,
  StyledFormBlock,
  StyledFormLabel,
  StyledFormInput,
  StyledFormSubmit,
} from '../../commons/Form'
import { DataContext } from '../../contexts/DataContext'
import { StyledGridRow } from '../../commons/Grid'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default () => {
  const { labels } = useContext(DataContext)
  const [formData, setFormData] = useState({})

  return (
    <StyledGridRow>
      <StyledNewsletterForm>
        <StyledNewsletterFormBlock>
          <StyledNewsletterFormName>
            {labels.NEWSLETTER}
          </StyledNewsletterFormName>
          <StyledNewsletterFormBigText
            dangerouslySetInnerHTML={{ __html: labels.NEWSLETTER_SUBSCRIBE }}
          />
        </StyledNewsletterFormBlock>
        <StyledNewsletterFormBlock>
          <div>
            <StyledNewsletterFormTitle>
              {labels.NEWSLETTER_TITLE}
            </StyledNewsletterFormTitle>
            <StyledNewsletterFormParagraph>
              {labels.NEWSLETTER_PARAGRAPH}
            </StyledNewsletterFormParagraph>
          </div>
          <StyledForm
            Validate
            onSubmit={(event) => {
              event.preventDefault()
              toast.configure();
              const form_data = new FormData(event.currentTarget);
              fetch('https://api.ipify.org/')
              .then(response => response.text())
              .then(response =>{
                form_data.append('g-recaptcha-response', 'token');
                form_data.append('lang', 'IT');
                fetch('https://3bee.link:8989/api/addNewsletter', {
                method: 'POST',
                body: form_data
                })
                .then((response) => {
                  if (response.status === 200){
                   toast.success("Iscritto!", {containerId: 'Alert', position: toast.POSITION.TOP_CENTER, autoClose: 2000 });   
                  } else {
                   toast.error("Errore, riprova o contatta info@3bee.it", {containerId: 'Alert', position: toast.POSITION.TOP_CENTER});   
                  }
                })
                .catch(function(){
                  toast.error("Errore, contattaci a info@3bee.it", {containerId: 'Alert', position: toast.POSITION.BOTTOM_CENTER});   
                });
                }
              )
              event.target.reset();
              return false
              }}
          >
            <StyledFormBlock>
              <StyledFormInput
                id="newsletter-email"
                type="email"
                name="email"
                onInput={(e) => {
                  setFormData({ ...formData, email: e.currentTarget.value })
                }}
              />
              <StyledFormLabel
                htmlFor="newsletter-email"
                isHidden={formData.email && formData.email.length > 0}
              >
                {labels.MAIL_ADDRESS}
              </StyledFormLabel>
            </StyledFormBlock>
            <StyledNewsletterFormSubmit>
              <StyledFormSubmit type="submit">
                {labels.SUBSCRIBE}
                <Icon icon={icons.arrowRight} color={Colors.white} />
              </StyledFormSubmit>
            </StyledNewsletterFormSubmit>
          </StyledForm>
        </StyledNewsletterFormBlock>
      </StyledNewsletterForm>
    </StyledGridRow>
  )
}
