import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledBigText } from '../../commons/Typography'

export const StyledComparativeSlider = styled.div`
  position: relative;
  color: ${Colors.black};
  background-color: ${Colors.grayLight};
  padding-top: ${Rem(52)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(100)};
  }

  ${StyledBigText} {
    @media (${BP.ipad}) {
      width: 50%;
      margin-left: 50%;
    }
  }
`

export const StyledComparativeSliderForm = styled.div`
  position: relative;
  height: ${Rem(220)};

  @media (${BP.ipad}) {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
  }
`

export const StyledComparativeSliderFormItem = styled.div`
  position: absolute;
  width: 80%;

  @media (${BP.ipad}) {
    width: 100%;
    padding-right: ${Rem(80)};
  }
`

export const StyledComparativeSwiper = styled.div`
  padding-bottom: ${Rem(90)};

  &:first-child {
    margin-bottom: ${Rem(32)};

    @media (${BP.ipad}) {
      padding-bottom: ${Rem(90)};
    }
  }

  &:last-child {
    @media (${BP.ipad}) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 2;
    }

    ${() => css`
      ${StyledComparativeSwiperSlider},
      ${StyledComparativeSwiperSlide} {
        @media (${BP.ipad}) {
          pointer-events: none !important;
        }
      }
      ${StyledComparativeSwiperSlider} {
        @media (${BP.ipad}) {
          height: auto;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    `}
  }
`

export const StyledComparativeSwiperSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledComparativeSwiperControls = styled.div`
  position: absolute;
  bottom: ${Rem(-2)};
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  pointer-events: none;
  width: 100%;
  max-width: ${Rem(550)};
  display: flex;
  justify-content: space-between;
  padding: 0;
`

export const StyledComparativeSwiperControlsPrev = styled.div`
  cursor: pointer;
  pointer-events: all;

  &.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const StyledComparativeSwiperControlsNext = styled(
  StyledComparativeSwiperControlsPrev
)``

export const StyledComparativeSwiperSlide = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-out 0s;
  text-align: center;

  &.swiper-slide-active {
    opacity: 1;
    pointer-events: all;
  }
`

export const StyledComparativeSwiperSlideImage = styled.img`
  width: 100%;
  max-width: ${Rem(600)};
  margin-bottom: ${Rem(32)};
`

export const StyledComparativeSwiperSlideTitle = styled.div`
  font-size: ${Rem(30)};
`

export const StyledComparativeSliderText = styled.p`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: ${Rem(15)};
  min-width: 100%;
  font-size: ${Rem(15)};
  line-height: ${Rem(19)};
  padding-top: ${Rem(32)};

  @media (${BP.ipad}) {
    bottom: auto;
    font-size: ${Rem(25)};
    top: 100%;
  }
`

export const StyledComparativeSwiperSlideProsCons = styled(
  StyledComparativeSwiperSlide
)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  max-width: ${Rem(960)};

  &.swiper-slide-active {
    opacity: 1;
    pointer-events: all;
  }
`

export const StyledComparativeSwiperSlidePros = styled.div`
  padding-top: ${Rem(12)};
  color: ${Colors.yellow};
  border-top: 1px solid ${Colors.yellow};
  text-align: left;
  max-width: calc(50% - ${Rem(12)});

  @media (${BP.ipad}) {
    padding-right: ${Rem(24)};
  }
`

export const StyledComparativeSwiperSlideCons = styled(
  StyledComparativeSwiperSlidePros
)`
  color: ${Colors.gray};
  border-top-color: ${Colors.gray};
  text-align: right;

  @media (${BP.ipad}) {
    color: ${Colors.black};
    border-top-color: ${Colors.black};
    padding-right: 0;
    padding-left: ${Rem(24)};
  }
`

export const StyledComparativeSwiperSlideProsConsBig = styled.div`
  font-size: ${Rem(28)};
  padding-bottom: ${Rem(12)};
`

export const StyledComparativeSwiperSlideProsConsSmall = styled.div`
  font-size: ${Rem(28)};
`
